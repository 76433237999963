import config from "./config";

export default {
  styleOverrides: {
    root: {
      minWidth: "auto",
      textTransform: "none" as const,
      paddingLeft: config.spacing.xl,
      paddingRight: config.spacing.xl,
      paddingTop: config.spacing.ml,
      paddingBottom: config.spacing.ml,
      borderRadius: config.radius.ml,
      [config.breakpoints.down("sm")]: {
        paddingLeft: config.spacing.l,
        paddingRight: config.spacing.l,
      },
      fontSize: config.typography.size.normal,
      lineHeight: config.typography.line.normal,
      fontWeight: config.typography.weight.regular,
    },
    startIcon: {
      marginRight: config.spacing.m,
    },
    endIcon: {
      marginLeft: config.spacing.m,
      [config.breakpoints.down("sm")]: {
        marginLeft: config.spacing.s,
      },
    },
    text: {
      padding: "initial",
      borderRadius: "initial",
      justifyContent: "flex-start",
      color: config.colors.base.black,
      [config.breakpoints.down("sm")]: {
        padding: "initial",
      },
      "&:disabled": {
        color: config.colors.gray.default,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    textSecondary: {
      color: config.colors.orange.brandy,
      "&:disabled": {
        color: config.colors.orange.tumbleweed,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    contained: {
      backgroundColor: config.colors.green.pea,
      color: config.colors.white,
      border: `1px solid ${config.colors.green.pea}`,
      "&:hover": {
        backgroundColor: config.colors.green.everglade,
        border: `1px solid ${config.colors.green.everglade}`,
      },
      "&:active": {
        backgroundColor: config.colors.green.everglade,
        border: `1px solid ${config.colors.green.everglade}`,
      },
      "&:disabled": {
        backgroundColor: config.colors.green.jet,
        border: `1px solid ${config.colors.green.jet}`,
        color: config.colors.white,
      },
    },
    containedSecondary: {
      backgroundColor: config.colors.orange.brandy,
      border: `1px solid ${config.colors.orange.brandy}`,
      "&:hover": {
        backgroundColor: config.colors.orange.tumbleweed,
        border: `1px solid ${config.colors.orange.tumbleweed}`,
      },
      "&:active": {
        backgroundColor: config.colors.orange.bourbon,
        border: `1px solid ${config.colors.orange.bourbon}`,
      },
      "&:disabled": {
        backgroundColor: config.colors.orange.pancho,
        border: `1px solid ${config.colors.orange.pancho}`,
      },
    },
    containedWhite: {
      backgroundColor: config.colors.white,
      color: config.colors.green.pea,
      "&:hover": {
        backgroundColor: config.colors.gray.smoke,
      },
    },
    outlined: {
      paddingLeft: config.spacing.xl,
      paddingRight: config.spacing.xl,
      paddingTop: config.spacing.ml,
      paddingBottom: config.spacing.ml,
      [config.breakpoints.down("sm")]: {
        paddingLeft: config.spacing.xl,
        paddingRight: config.spacing.xl,
      },
    },
    outlinedInherit: {
      borderColor: config.colors.gray.default,
    },
    outlinedSizeSmall: {
      paddingLeft: config.spacing.xl,
      paddingRight: config.spacing.xl,
      paddingTop: config.spacing.sm,
      paddingBottom: config.spacing.sm,
      [config.breakpoints.down("sm")]: {
        paddingLeft: config.spacing.xl,
        paddingRight: config.spacing.xl,
      },
    },
    outlinedPrimary: {
      color: config.colors.green.pea,
      borderColor: config.colors.green.pea,
      "&:hover": {
        borderColor: config.colors.green.pea,
      },
    },
    outlinedSecondary: {
      color: config.colors.orange.brandy,
      borderColor: config.colors.orange.brandy,
      backgroundColor: config.colors.white,
      "&:hover": {
        borderColor: config.colors.orange.brandy,
        backgroundColor: config.colors.white,
      },
    },
    sizeSmall: {
      paddingLeft: config.spacing.xl,
      paddingRight: config.spacing.xl,
      paddingTop: config.spacing.sm,
      paddingBottom: config.spacing.sm,
      [config.breakpoints.down("sm")]: {
        paddingLeft: config.spacing.xl,
        paddingRight: config.spacing.xl,
      },
    },
  },
  defaultProps: {
    disableElevation: true,
  },
};
