export default {
  ACCOUNT_PAGE_ADD_PHOTO: "Add Photo",
  ACCOUNT_PAGE_BADGES_BACK_TO_PROFILE: "Back to profile",
  ACCOUNT_PAGE_ACHIEVED_ON: "(Achieved on {date})",
  ACCOUNT_PAGE_BADGES_DESCRIPTION:
    "Our badges are a fun way to inspire each other to keep making progress. Click a badge to see more details.",
  ACCOUNT_PAGE_EVENTS_DRAFTS: "Event Drafts",
  ACCOUNT_PAGE_EVENTS_HOST_BY_ME: "Hosted by me",
  ACCOUNT_PAGE_EVENTS_IM_HOSTING: "Events I’m Hosting",
  ACCOUNT_PAGE_EVENTS_I_HOSTED: "Events I've Hosted",
  ACCOUNT_PAGE_HAPPY_WITH_MY_SPEAKING_PARTNERS: "Happy with my speaking partners",
  ACCOUNT_PAGE_MY_OCCUPATION: "My Occupation",
  ACCOUNT_PAGE_MY_PERSONAL_INTERESTS: "My personal interests",
  ACCOUNT_PAGE_MY_PERSONAL_INTERESTS_MAX: "My personal interests (max 8)",
  ACCOUNT_PAGE_SKILLS_I_WANT_TO_IMPROVE: "The skills I want to improve",
  ACCOUNT_PAGE_STORY_ERROR: "Please complete your story",
  ACCOUNT_PAGE_REQUIRED_FIELD_ERROR: "This field is required",
  ACCOUNT_PAGE_MIN_INTERESTS_ERROR: "Please choose at least one interest",
  ACCOUNT_PAGE_MAX_INTERESTS_ERROR: "Please choose max 8 interests",
  ACCOUNT_PAGE_MIN_SKILLS_ERROR: "Please choose at least one English skill",
  ACCOUNT_PAGE_MAX_SKILLS_ERROR: "Please choose max 5 English skills",
  ACCOUNT_PAGE_MIN_WORDS_ERROR: "Your story must have at least 30 words.",
  ACCOUNT_PAGE_ADD_PHOTO_ERROR: "Please add your photo",
  ACCOUNT_PAGE_INVALID_DATE_ERROR: "Please provide valid date",
  ACCOUNT_PAGE_SKILLS_I_WANT_TO_IMPROVE_MAX: "The skills I want to improve (max 5)",
  ACCOUNT_PAGE_MY_AVAILABILITY: "My Availability",
  ACCOUNT_PAGE_MY_PHOTOS: "My Photos ({photos})",
  ACCOUNT_PAGE_ARE_YOU_SURE: "Are you sure you want to delete this photo?",
  ACCOUNT_PAGE_MY_PROFILE: "My Profile",
  ACCOUNT_PAGE_MY_STORY: "My Story",
  ACCOUNT_PAGE_MY_ACHIEVEMENTS: "My Achievements",
  ACCOUNT_PAGE_UPDATE_ENGLISH_LEVEL: "Update",
  ACCOUNT_PAGE_OPEN_TO_SPEAKING_PARTNERS: "Open to speaking partners",
  ACCOUNT_PAGE_PASSED_EVENT: "Past Event",
  ACCOUNT_PAGE_PERSONAL_ACCOUNT: "My Account",
  ACCOUNT_PAGE_PERSONAL_DETAILS: "Personal Details",
  ACCOUNT_PAGE_SHOW_MY_BIRTHDAY: "Show my birthday (day & month only)",
  ACCOUNT_PAGE_SHOW_MY_BIRTHDAY_YEAR: "Show my birthday and year of birth",
  ACCOUNT_PAGE_NOT_SPECIFIED: "Occupation not specified",
  ACCOUNT_VERIFIED_MEMBER: "Verified Member",
  ACCOUNT_PAGE_CURRENT_ENGLISH_LEVEL: "Current English Level",
  ACCOUNT_PAGE_LOOKING_FOR_SPEAKING_PARTNERS:
    "Are you  looking for new speaking partners right now?",
  ACCOUNT_PAGE_SPEAKING_PARTNERS_DESCRIPTION:
    "If you answer ‘yes’ we will make sure your profile is seen by other members who are also looking for speaking partners.",
  ACCOUNT_PAGE_SEE_WHAT_IS_MISSING: "See what’s missing",
  ACCOUNT_PAGE_MISSING_DETAILS: "Missing Details",
  ACCOUNT_PAGE_COMPLETED_NAME: "First and last name",
  ACCOUNT_PAGE_COMPLETED_ENGLISH_LEVEL: "English Level",
  ACCOUNT_PAGE_COMPLETED_LOCATION: "Location",
  ACCOUNT_PAGE_COMPLETED_INTERESTS: "Personal Interests",
  ACCOUNT_PAGE_COMPLETED_ENGLISH_SKILLS: "English Interests",
  ACCOUNT_PAGE_COMPLETED_OCCUPATION: "Occupation",
  ACCOUNT_PAGE_COMPLETED_STORY: "Your story",
  ACCOUNT_PAGE_COMPLETED_BIRTHDATE: "Date of birth",
  ACCOUNT_PAGE_MISSING_INTERESTS: "Personal Interests (select up to 8)",
  ACCOUNT_PAGE_MISSING_ENGLISH_SKILLS: "English Interests (select up to 5)",
  ACCOUNT_PAGE_MISSING_OCCUPATION: "Occupation (please add)",
  ACCOUNT_PAGE_MISSING_STORY: "Your story (min 30 words)",
  ACCOUNT_PAGE_MISSING_BIRTHDATE: "Date of birth (please add)",
  ACCOUNT_PAGE_MONTHLY_STATISTICS_READY: "{name}, your monthly recap is ready and waiting for you!",
  ACCOUNT_PAGE_MONTHLY_CHECK_PROGRESS:
    "Let’s check your progress from last month. You can review it now or access the report from your profile.",
  ACCOUNT_PAGE_SEE_STATISTICS: "See My Report",
  ACCOUNT_PAGE_CHECK_PROFILE: "Check My Profile",
  ACCOUNT_PAGE_BACK_TO_PROFILE: "Back to Profile",
  ACCOUNT_PAGE_RECAP: "{name}, here is your {month} recap!",
  ACCOUNT_PAGE_FINISH: "Finish & Close",
  ACCOUNT_PAGE_ATTENDED_EVENTS: "Events you attended",
  ACCOUNT_PAGE_ATTENDED_EVENTS_DESCRIPTION:
    "Each event is an opportunity to improve your English and connect with people. Keep it up!",
  ACCOUNT_PAGE_ATTENDED_EVENTS_MORE_DETAILS: "More details:",
  ACCOUNT_PAGE_ATTENDED_EVENTS_ONE_TO_ONES: "1:1 Events ",
  ACCOUNT_PAGE_ATTENDED_EVENTS_GROUP_EVENTS: "Group Events",
  ACCOUNT_PAGE_ATTENDED_EVENTS_IN_PERSON_EVENTS: "In Person Events",
  ACCOUNT_PAGE_ATTENDED_EVENTS_WEBINARS: "Webinars",
  ACCOUNT_PAGE_ATTENDED_EVENTS_OTHER: "Other",
  ACCOUNT_PAGE_ATTENDED_EVENTS_TOTAL_EVENTS: "Total Events",
  ACCOUNT_PAGE_ATTENDED_EVENTS_PREVIOUS_MONTH_MORE:
    "You attended {number} more events than the previous month",
  ACCOUNT_PAGE_ATTENDED_EVENTS_PREVIOUS_MONTH_LESS:
    "You attended {number} less events than the previous month",
  ACCOUNT_PAGE_ATTENDED_EVENTS_PREVIOUS_MONTH_SAME:
    "You attended the same number of events as previous month",
  ACCOUNT_PAGE_SPONTANEOUS_CONVERSATIONS: "Spontaneous Conversations",
  ACCOUNT_PAGE_SPONTANEOUS_CONVERSATIONS_DESCRIPTION:
    "These are the extra conversations you had at Live Tables and via the Chat!",
  ACCOUNT_PAGE_SPONTANEOUS_CONVERSATIONS_MORE:
    "You had {number} more spontaneous conversations than the previous month",
  ACCOUNT_PAGE_SPONTANEOUS_CONVERSATIONS_LESS:
    "You had {number} less spontaneous conversations than the previous month",
  ACCOUNT_PAGE_SPONTANEOUS_CONVERSATIONS_SAME:
    "You had the same number of spontaneous conversations as previous month",
  ACCOUNT_PAGE_ATTENDANCE_RECORD: "Attendance Record",
  ACCOUNT_PAGE_NO_OF_EVENTS:
    "How reliable are you? When you say you will attend an event, do you keep your promise?",
  ACCOUNT_PAGE_YOU_ATTENDED:
    "You attended <bold>{number}</bold> of the <bold>{total}</bold> events you said you would attend.",
  ACCOUNT_PAGE_YOU_ATTENDED_ONLY:
    "You only attended <bold>{number}</bold> of the <bold>{total}</bold> events you said you would attend.",
  ACCOUNT_PAGE_YOU_ATTENDED_JUST:
    "You attended just <bold>{number}</bold> of the <bold>{total}</bold> events you said you would attend.",
  ACCOUNT_PAGE_EXCELLENT: "Excellent!",
  ACCOUNT_PAGE_GREAT: "Great!",
  ACCOUNT_PAGE_OK: "Uh oh!",
  ACCOUNT_PAGE_NOT_GREAT: "Not great!",
  ACCOUNT_PAGE_NOT_BAD: "Oh dear!",
  ACCOUNT_PAGE_MY_PARTICIPATION: "My Participation",
  ACCOUNT_PAGE_PARTICIPATION_TIME: "Participation Time",
  ACCOUNT_PAGE_PRACTICE:
    "Practice makes perfect! This is how much time you spent in video calls improving your English",
  ACCOUNT_PAGE_MORE_TIME: "That is {number} hours more than the previous month",
  ACCOUNT_PAGE_LESS_TIME: "That is {number} hours less than the previous month",
  ACCOUNT_PAGE_SAME_TIME: "You have spent the same number of hours as last month",
  ACCOUNT_PAGE_HOURS: "Hours",
  ACCOUNT_PAGE_MINUTES: "Minutes",
  ACCOUNT_PAGE_NETWORK: "Connecting to the world!",
  ACCOUNT_PAGE_MAP: "Ladies who attended the same events as you, joined from here!",
  ACCOUNT_PAGE_RECOGNISE_THIS: "Recognise these friendly faces?",
  ACCOUNT_PAGE_MOST_COMMON: "These ladies attended the same events as you most frequently!",
  ACCOUNT_PAGE_ARTICLES_WRITTEN: "Articles Written",
  ACCOUNT_PAGE_NO_OF_ARTICLES: "Here is the number of articles you wrote for the community.",
  ACCOUNT_PAGE_EVENTS_STREAK: "Events Streak",
  ACCOUNT_PAGE_EVENTS_YOU_JOINED: "How often did you participate?",
  ACCOUNT_PAGE_EVENTS_HOSTED: "Events Hosted",
  ACCOUNT_PAGE_THANKS: "Thanks for hosting!",
  ACCOUNT_PAGE_SPARKS: "Sparks created",
  ACCOUNT_PAGE_SPARKS_CELEBRATING:
    "Celebrating the meaningful conversations you’ve had in our community",
  ACCOUNT_PAGE_FELT_A_SPARK: "Events you attended where <bold>YOU felt</bold> a spark!",
  ACCOUNT_PAGE_NO_OF_SPARKS_ON_ATTENDED:
    "Sparks created <bold>at ALL the events</bold> you attended",
  ACCOUNT_PAGE_COMMUNITY_SPARKS: "Sparks created by the <bold>whole community</bold> last month!",
  ACCOUNT_PAGE_THANK_YOU: "Thank you!",
  ACCOUNT_PAGE_SPARKS_CREATED_SINCE: "Sparks created since Hey Lady! began",
  ACCOUNT_PAGE_MISSION:
    "Thank you for joining our mission to spark one billion meaningful conversations between" +
    " women of different cultures.",
  ACCOUNT_PAGE_SUMMARY:
    "This is a summary of the events you hosted and the number of participants who attended",
  ACCOUNT_PAGE_MORE_EVENTS: "You hosted {number} events more compared to the previous month.",
  ACCOUNT_PAGE_LESS_EVENTS: "You hosted {number} events less compared to the previous month.",
  ACCOUNT_PAGE_SAME_EVENTS: "You hosted the same number of events as the previous month.",
  ACCOUNT_PAGE_LADIES: "Ladies Attended",
  ACCOUNT_PAGE_SPARKS_CREATED: "Sparks created by the events <bold>you hosted!</bold>",
  ACCOUNT_PAGE_SEE_RECAP: "See Monthly Recap",
  ACCOUNT_PAGE_MONTHLY_REVIEW: "Your monthly review is ready!",
  ACCOUNT_PAGE_MONTHLY_RECAP:
    "Let’s review the amazing things you did over the past month in the Hey Lady! Community!",
  ACCOUNT_PAGE_SEE_DETAILS: "See Details",
  ACCOUNT_PAGE_TRACK_PROGRESS: "Want to track your progress?",
  ACCOUNT_PAGE_START_ATTENDING:
    "Did you know the average Hey Lady! member attends 3 events every week? Start attending events and we’ll help you to measure your progress!",
  AUTH_ACCOUNT_ENCOUNTERED_ISSUE:
    "We encountered an issue while activating your account. If this problem persists, please <link>contact us</link>.",
  AUTH_ACCOUNT_NOT_ACTIVATED: "Your account could not be activated",
  AUTH_ACCOUNT_NOW_ACTIVE: "Your account is now active!",
  AUTH_ACCOUNT_WAS_ACTIVATED:
    "Your account was activated successfully. You can now log in with the email and password you provided when you signed up.",
  AUTH_ACCOUNT_ACTIVATED: "Your account was activated successfully.",
  AUTH_ALREADY_HAVE_AN_ACCOUNT: "Already have an account? <url>Log In</url>",
  AUTH_BACK_TO_LOGIN: "Back to Log In",
  AUTH_BROWSE_MEMBER_PROFILES: "Browse member profiles",
  AUTH_CREATE_ACCOUNT: "Create Account",
  AUTH_CREATE_NEW_ACCOUNT_PASSWORD: "Please, create a new password for your account",
  AUTH_CREATE_NEW_PASSWORD_HINT: "Must be at least 8 characters long",
  AUTH_CREATE_PASSWORD: "Create password",
  AUTH_CREATE_CONFIRM_PASSWORD: "Confirm password",
  AUTH_CREATE_PASSWORDS_MUST_MATCH: "Passwords must match",
  AUTH_CREATE_PASSWORD_CHANGED: "Your password has been changed. You can log in now.",
  AUTH_CREATE_PASSWORD_FAILED: "Failed to change your password",
  AUTH_CREATE_PASSWORD_SAVE_PASSWORD: "Save new password",
  AUTH_CREATE_PASSWORD_LOG_IN: "Log in now",
  AUTH_DONT_HAVE_AN_ACCOUNT: "Not a member yet? <url>Sign Up</url>",
  AUTH_EXPLORE_YOUR_ONLINE_EVENTS: "Explore our online events",
  AUTH_FORGOT_PASSWORD: "Forgot password?",
  AUTH_FORGOT_PASSWORD_EMAIL_PLACEHOLDER: "pocahontas@example.com",
  AUTH_FORGOT_PASSWORD_ENTER_EMAIL:
    "Enter your email and we’ll send you instructions on how to restore your password",
  AUTH_FORGOT_PASSWORD_INSTRUCTIONS_SENT: "Instructions have been sent to your email!",
  AUTH_FORGOT_PASSWORD_INSTRUCTIONS_SENT_TO_EMAIL:
    "Instructions sent to {email}. Please check your email now to reset your password.",
  AUTH_FORGOT_PASSWORD_SEND_INSTRUCTIONS: "Send instructions",
  AUTH_I_WOULD_LIKE_TO_BE_UPDATED: "I would like to be updated about the launch of {title}",
  AUTH_LOGIN_TO_YOUR_ACCOUNT: "Log in to your account using the following methods:",
  AUTH_LOG_IN: "Log In",
  AUTH_LOG_IN_FORGOT_PASSWORD: "<url>Forgot password?</url>",
  AUTH_LOG_IN_TO_HEY_LADY: "Log in to Hey Lady!",
  AUTH_LOG_IN_WITH_GOOGLE: "Log in with Google",
  AUTH_NOT_ALL_FEATURES_FROM_OUR_PAID_MEMBER:
    "Not all features of our platform are available just yet.",
  AUTH_NO_CREDIT_CARD: "No credit card required",
  AUTH_PERSONALISE_YOUR_PROFILE: "Personalise your profile",
  AUTH_PREVIEW_HEY_LADY: "Preview {title}",
  AUTH_SETUP_PROFILE: "Set up your profile",
  AUTH_SIGN_UP: "Sign up to take a look inside.",
  AUTH_TWO_FREE_CONVERSATION_LESSONS: "Two free conversation lessons",
  AUTH_WELCOME: "Welcome to Hey Lady!",
  AUTH_WHAT_DO_I_GET: "What's inside?",
  AUTH_FAIL_UNEXPECTED_ERROR:
    "Unexpected error while trying to communicate with google. Please try again! If the issue persist please contact support.",
  AUTH_FAIL_ALREADY_EXISTS:
    "You already have an account associated with your email. Please log in!",
  AUTH_FAIL_UNAUTHORIZED: "We couldn't find a Hey Lady! account that matched your selection",
  AUTH_FAIL_UNAUTHORIZED_DESCRIPTION: `
      You have 2 options: {br}
      1. Please select a different Google account {br}
      2. If you are not yet a member of Hey Lady! Click <url>this link</url> to learn more and join
  `,
  BADGE_TITLE_10_EVENTS: "10 Events",
  BADGE_DESCRIPTION_10_EVENTS:
    "Attend 10 scheduled events and you’ll earn your first conversation badge to celebrate.",
  BADGE_TITLE_100_EVENTS: "100 Club",
  BADGE_DESCRIPTION_100_EVENTS:
    "Attend 100 scheduled events to join the “100 Club”. You are on your way to becoming a Conversation Queen!",
  BADGE_TITLE_500_EVENTS: "500 Club",
  BADGE_DESCRIPTION_500_EVENTS:
    "Attend 500 scheduled events and you’ll earn your “500 Club” badge. The recipients of this badge are officially past halfway on the journey to becoming a Conversation Queen!",
  BADGE_TITLE_AUTHOR: "Author",
  BADGE_DESCRIPTION_AUTHOR:
    "Members who write and publish an article of 300 words or more to our Memberzine can earn" +
    " themselves the “Author” badge.",
  BADGE_TITLE_BIG_SISTER: "Big Sister",
  BADGE_DESCRIPTION_BIG_SISTER:
    "Our ‘Big Sisters’ play an incredibly important role in our community by volunteering" +
    " their time to welcome new members, host community events and support members to get settled, meet friends and host events. Big Sisters are selected via application and receive training to support their special role. Contact our team to find out more.",
  BADGE_TITLE_PERFECT_PROFILE: "Perfect Profile",
  BADGE_DESCRIPTION_PERFECT_PROFILE:
    "A complete profile page helps us recommend events and members to you! Keep" +
    " yours at “100% complete” to earn and keep your “Perfect Profile” badge.",
  BADGE_TITLE_CONVERSATION_QUEEN: "Conversation Queen",
  BADGE_DESCRIPTION_CONVERSATION_QUEEN:
    "Attend 1000 scheduled events or more and you will be rightfully crowned as a Hey Lady! “Conversation Queen”. This is a prestigious badge acknowledging your persistence and commitment to becoming a brilliant English conversationalist!",
  BADGE_TITLE_EVENT_HOST: "Host",
  BADGE_DESCRIPTION_EVENT_HOST:
    "Members who create and host an online event for our community earn the ‘Host’ badge. To earn this badge you need to successfully create and host an event!",
  BADGE_TITLE_FOUNDING_MEMBER: "Founding Member",
  BADGE_DESCRIPTION_FOUNDING_MEMBER:
    "Our Founding Members are the pioneers of Hey Lady! They include members from The Ladies’ Project together with new members who signed up to join Hey Lady! within our first 3 months.",
  BADGE_TITLE_HOSTED_10_EVENTS: "Hosted 10 Events",
  BADGE_DESCRIPTION_HOSTED_10_EVENTS:
    "Successfully create and host 10 events to earn this Silver Host badge! Recipients of this badge are on the way to becoming a Super Host!",
  BADGE_TITLE_HOSTED_50_EVENTS: "Hosted 50 Events",
  BADGE_DESCRIPTION_HOSTED_50_EVENTS:
    "Successfully create and host 50 events to earn this shiny Gold Host badge. The recipients of this badge are officially past half way on the journey to becoming a Super Host.",
  BADGE_TITLE_LEGENDARY_LADY: "Legendary Lady",
  BADGE_DESCRIPTION_LEGENDARY_LADY:
    "Hey Lady! is full of incredible women - which is why it is so spectacular to" +
    " become legendary here. A Legendary Lady is a member who demonstrates one (or some or all) of our core values through her actions in our community. " +
    "<link>Our Community Values</link> are: Respect, Generosity, Empowerment, Courage and Collaboration.",
  BADGE_TITLE_LIFETIME_MEMBER: "Lifetime Member",
  BADGE_DESCRIPTION_LIFETIME_MEMBER:
    "Lifetime Membership is awarded to every Hey Lady! member who has been with the community for 4 years or more.",
  BADGE_TITLE_LOCAL_HOST: "Local Host",
  BADGE_DESCRIPTION_LOCAL_HOST:
    "Members who host an in-person event earn the “Local Host” badge. To earn this badge you need to successfully create and host an in-person event in the town or city where you live!",
  BADGE_TITLE_ONE_YEAR_ANNIVERSARY: "One Year Member",
  BADGE_DESCRIPTION_ONE_YEAR_ANNIVERSARY:
    "To celebrate 12 months of active membership in the community, members are awarded the “Green Emerald” badge.",
  BADGE_TITLE_TWO_YEAR_ANNIVERSARY: "Two Year Member",
  BADGE_DESCRIPTION_TWO_YEAR_ANNIVERSARY:
    "To celebrate 2 years of active membership in the community, members are awarded the “Pink Ruby” badge.",
  BADGE_TITLE_THREE_YEAR_ANNIVERSARY: "Three Year Member",
  BADGE_DESCRIPTION_THREE_YEAR_ANNIVERSARY:
    "To celebrate 3 years of active membership in the community, members are awarded the “Blue Diamond” badge.",
  BADGE_TITLE_SUPER_HOST: "Super Host",
  BADGE_DESCRIPTION_SUPER_HOST:
    "Members who host 100+ events attain the status of “Super host!” This is an incredible moment to be celebrated!",
  BADGE_TITLE_ULTRA_HOST: "Ultra Host",
  BADGE_DESCRIPTION_ULTRA_HOST:
    "Members who host 500+ events attain the status of “Ultra Host!” This is an astounding accomplishment and the highest host status that can be achieved.",
  BADGE_TITLE_VERIFIED: "Verified",
  BADGE_DESCRIPTION_VERIFIED:
    "Members whose identity has been verified by our team receive the verification badge. Being verified is an important step to ensure our community is safe so that our members can feel comfortable connecting face-to-face via video.",
  BADGE_WAITING_FOR_VERIFICATION: "Waiting for verification",
  BADGE_TITLE_ONBOARDING_QUIZ: "HeyLady! Way",
  BADGE_DESCRIPTION_ONBOARDING_QUIZ:
    "This badge is awarded to any member who correctly answers all 10 questions in our <link>quiz</link> about the “Hey Lady! Way”: how we learn, improve and grow!",
  BADGE_TITLE_BROUGHT_A_FRIEND: "Brought a friend!",
  BADGE_DESCRIPTION_BROUGHT_A_FRIEND:
    "Share the magic of Hey Lady! and support our community by inviting a friend to join! The more members we have, the more we can do together!",
  BADGE_TITLE_AMBASSADOR: "Ambassador",
  BADGE_DESCRIPTION_AMBASSADOR:
    "Become a Hey Lady!  Ambassador by successfully inviting five (5) new members to join and support the community as paying members.",
  BADGE_TITLE_PLATINUM_AMBASSADOR: "Platinum Ambassador",
  BADGE_DESCRIPTION_PLATINUM_AMBASSADOR:
    "Become a Platinum Ambassador when you successfully invite 25 new members to join and support the community as paying members.",
  BADGE_TITLE_DIAMOND_AMBASSADOR: "Diamond Ambassador",
  BADGE_DESCRIPTION_DIAMOND_AMBASSADOR:
    "Our Diamond Ambassadors are the exceptional women who have successfully invited 50 new members to join the community.",
  BADGE_TITLE_CERTIFIED_HOST: "Certified Host",
  BADGE_DESCRIPTION_CERTIFIED_HOST:
    "Awarded to members who complete the Hey Lady! Host training and are certified as capable event hosts.{br} Members with this badge are experienced and host events that are well organised and enjoyable. ",
  BADGE_SEE_MY_ACHIEVEMENTS: "See my achievements",
  BADGE_NEW_AUTHOR_TITLE: "You’ve just earned your Author badge!",
  BADGE_NEW_AUTHOR:
    "Congratulations on writing your first article. Writing regularly helps you to use language you learn and reflect on your progress. Keep writing!",
  BADGE_NEW_BIG_SISTER_TITLE: "You’ve just earned your Big Sister badge!",
  BADGE_NEW_BIG_SISTER:
    "Congratulations! Thank you for your wonderful contribution and the sparkle you bring to Hey Lady!",
  BADGE_NEW_10_EVENTS_TITLE: "You’ve just earned a new badge!",
  BADGE_NEW_10_EVENTS: "Congratulations for attending 10 events!",
  BADGE_NEW_100_EVENTS_TITLE: "You’ve just earned a new badge!",
  BADGE_NEW_100_EVENTS: "Congratulations for attending 100 events!",
  BADGE_NEW_500_EVENTS_TITLE: "You’ve just earned a new badge!",
  BADGE_NEW_500_EVENTS:
    "Whoah! You’ve attended 500 events?! That’s 500 opportunities you created to learn, speak," +
    " listen, laugh & meet new friends.",
  BADGE_NEW_CONVERSATION_QUEEN_TITLE: "You have been crowned a ‘Conversation Queen’!",
  BADGE_NEW_CONVERSATION_QUEEN:
    "This is special, lady! You have *officially* made the English language your own.",
  BADGE_NEW_EVENT_HOST_TITLE: "Congratulations, {name}! You’ve just earned your Host badge!",
  BADGE_NEW_EVENT_HOST:
    "Woohoo! Congratulations for unlocking new English opportunities for yourself. What conversation will you host next?",
  BADGE_NEW_HOSTED_10_EVENTS_TITLE: "Congratulations, {name}! You’ve just earned a new badge!",
  BADGE_NEW_HOSTED_10_EVENTS:
    "Congratulations for hosting 10 events! It’s time to make English your own!",
  BADGE_NEW_HOSTED_50_EVENTS_TITLE: "Congratulations, {name}! You’ve just earned a new badge!",
  BADGE_NEW_HOSTED_50_EVENTS:
    "You’ve hosted 50 events, which means you’ve been learning, but also DOING lots of things in English. 🙌 Keep going!",
  BADGE_NEW_SUPER_HOST_TITLE: "You are a SUPER Host, {name}!",
  BADGE_NEW_SUPER_HOST:
    "Congratulations! You are part of a small but special group of women in Hey Lady! Who contribute to the culture, discussions and ideas",
  BADGE_NEW_ULTRA_HOST_TITLE: "You are an ULTRA Host, {name}!",
  BADGE_NEW_ULTRA_HOST:
    "Congratulations! You are part of a small but special group of women who are core contributors to our community culture.",
  BADGE_NEW_LEGENDARY_LADY_TITLE: "Hey, lady! You are legendary!",
  BADGE_NEW_LEGENDARY_LADY:
    "The women of Hey lady! have awarded you this special badge, for consistently demonstrating our core <link>Community Values</link>. Thank you for being YOU!",
  BADGE_NEW_VERIFIED_TITLE: "You’ve just been verified, {name}.",
  BADGE_NEW_VERIFIED: "Thank for helping to make Hey Lady! a safe space for everyone!",
  BADGE_NEW_PERFECT_PROFILE_TITLE: "You’ve just earned the Perfect Profile badge!",
  BADGE_NEW_PERFECT_PROFILE: "Thank you for keeping your profile complete and up to date!",
  BADGE_NEW_ONE_YEAR_ANNIVERSARY_TITLE: "Happy 1-Year Anniversary, {name}!",
  BADGE_NEW_ONE_YEAR_ANNIVERSARY:
    "We are celebrating with you today, because you have just reached 12 months of active membership in Hey Lady! You’ve come so far & we are excited to see what comes next!",
  BADGE_NEW_TWO_YEAR_ANNIVERSARY_TITLE: "Happy 2-Year Anniversary, {name}!",
  BADGE_NEW_TWO_YEAR_ANNIVERSARY:
    "We are celebrating YOU today, because you have been showing up to speak, practise, learn and grow for two years. We can’t wait to see what you achieve in the year ahead!",
  BADGE_NEW_THREE_YEAR_ANNIVERSARY_TITLE: "Happy 3-Year Anniversary, {name}!",
  BADGE_NEW_THREE_YEAR_ANNIVERSARY:
    "We are celebrating with you today, because you have been living your best English life inside Hey Lady! for 3 years. Love you looong time!",
  BADGE_NEW_ONBOARDING_QUIZ_TITLE: "You just earned the <em>Hey Lady! Way</em> badge! Well done!",
  BADGE_NEW_BROUGHT_A_FRIEND_TITLE: "You brought a friend!",
  BADGE_NEW_AMBASSADOR_TITLE: "Hello Ambassador!",
  BADGE_NEW_PLATINUM_AMBASSADOR_TITLE: "Platinum Ambassador",
  BADGE_NEW_DIAMOND_AMBASSADOR_TITLE: "Diamond Ambassador",
  BADGE_NEW_ONBOARDING_QUIZ:
    "We are thrilled that you took the time to learn what makes our community unique and how we can help you!",
  BADGE_NEW_BROUGHT_A_FRIEND:
    "In recognition of your support in helping to introduce a friend to Hey Lady! you’ve just earned yourself a new badge! {br} You’re just 4 friends away from becoming an Ambassador!",
  BADGE_NEW_AMBASSADOR:
    "Awarded in recognition of successfully introducing 5 friends to Hey Lady! {br} Congratulations! You’ve also earned yourself $50 credit to your Hey Lady! account",
  BADGE_NEW_PLATINUM_AMBASSADOR:
    "Awarded in recognition of successfully introducing 25 friends to Hey Lady!.{br} We are truly grateful for the support you have shown to your friends and this community. {br} To celebrate your achievement we are pleased to offer you $200 credit to your Hey Lady! account PLUS 12 months membership!",
  BADGE_NEW_DIAMOND_AMBASSADOR:
    "Awarded in recognition of successfully introducing 50 friends to Hey Lady!.{br} Congratulations! This is the big one! Prepare to be showered with love and adoration by the whole community! {br} To show our appreciation, you will receive lifetime membership PLUS return flights to a Hey Lady! meetup! {br} Our Founder, Emma will be in touch with you personally in the coming days to thank you herself!",
  BADGE_NEW_CERTIFIED_HOST_TITLE: "You are a Certified Host!",
  BADGE_NEW_CERTIFIED_HOST:
    "Congratulations on completing your host training! We award you the Certified Host badge in recognition of your commitment to hosting effective and enjoyable online events and meetings.{mb} Share this on your <link>LinkedIn</link> profile to celebrate your certified skills!",
  SIGN_UP_PAGE_BEFORE_WE_START: "Before we start",
  SIGN_UP_PAGE_PLEASE_CONFIRM_ELIGIBILITY: "Please confirm your eligibility to join {logo}",
  SIGN_UP_PAGE_LETS_GET_STARTED: "Let's get started!",
  SIGN_UP_IMPORTANT_INFORMATION: "Here's some important information you need to know",
  SIGN_UP_A_MONTHLY_PLAN:
    "You're signing up to a Monthly Membership Plan giving you unlimited access to the platform, lessons and community!",
  SIGN_UP_A_QUARTERLY_PLAN:
    "You're signing up to a Quarterly Membership Plan which includes the 12-week 'Fluency Foundations' video course to set you up for success!",
  SIGN_UP_A_YEARLY_PLAN:
    "You're signing up to a Yearly Membership Plan which includes the 12-week 'Fluency Foundations' video course PLUS a 45 minute call with a Hey Lady! coach!",
  SIGN_UP_A_CREDIT_CARD_IS_REQUIRED:
    "A credit card or PayPal account is required to sign up. You will not be charged for 10 days.",
  SIGN_UP_PAYMENT: "Payment is upfront and requires a valid credit card or Paypal account.",
  SIGN_UP_MONTHLY_RENEW:
    "Your plan will renew automatically every month until you cancel. You can cancel easily at any time from your Account Settings inside the platform.",
  SIGN_UP_QUARTERLY_RENEW:
    "Your plan will renew automatically every 3 months until you cancel. You can cancel easily at any time from your Account Settings inside the platform.",
  SIGN_UP_YEARLY_RENEW:
    "Your plan will renew automatically every year until you cancel. You can cancel at any time from your Account Settings inside the platform.",
  SIGN_UP_MONTHLY_PAYMENT:
    "We accept payments via credit card, debit card and PayPal. Payment via bank transfer is also available on the Quarterly & Annual Plans. Please contact our team at <url>hello@heylady.io</url> if you'd like to arrange this.",
  SIGN_UP_QUARTERLY_PAYMENT:
    "We accept payments via credit card, debit card and PayPal. Payment via bank transfer is also available. Please contact our team at <url>hello@heylady.io</url> to arrange this.",
  SIGN_UP_YEARLY_PAYMENT:
    "We accept payments securely via Stripe and PayPal. Payment via bank transfer is also available. Please contact our team at <url>hello@heylady.io</url> to arrange this.",
  SIGN_UP_HAPPINESS_GUARANTEE:
    "You are protected by our 7-day Happiness Guarantee. If you're not happy, let us know within 7 days and we will refund your payment.",
  SIGN_UP_ACCESS:
    "Access to Hey Lady! is through your browser. For the best experience, we recommend Google Chrome, Firefox or Safari and a minimum internet speed of 5Mbps.",
  SIGN_UP_I_UNDERSTAND:
    "I understand how the plan works and agree to the <url>Terms of Service</url> including refund policy.",
  SIGN_UP_PAGE_ARE_YOU_A_WOMAN: "Are you a woman over 18 years old?",
  SIGN_UP_PAGE_ARE_YOU_A_WOMAN_YES: "Yes, I’m a woman over 18 years old",
  SIGN_UP_PAGE_ARE_YOU_A_WOMAN_NO: "No, I’m a woman under 18 years old",
  SIGN_UP_PAGE_ARE_NO_I_AM_A_MAN: "No, I’m a man",
  SIGN_UP_PAGE_CREATE_YOUR_ACCOUNT: "Create your account",
  SIGN_UP_PAGE_WE_NEED_YOUR_EMAIL:
    "We need your email to send you information about your payment plan",
  SIGN_UP_WITH_GOOGLE: "Sign up with Google",
  SIGN_UP_PAGE_WHAT_IS_YOUR_ENGLISH_LEVEL: "What is your current English level?",
  SIGN_UP_NOT_SURE: "Not Sure? <link>Follow the link</link> to test your level",
  SIGN_UP_PAGE_CONTINUE: "Continue",
  SIGN_UP_PAGE_PREVIOUS_STEP: "Previous Step",
  SIGN_UP_PAGE_BACK_TO_HOME: "Back to Home",
  SIGN_UP_PAGE_ENGLISH_CHARACTERS_ERROR: "Please use only English characters.",
  SIGN_UP_PAGE_YOU_RE_BACK: "You’re back!",
  SIGN_UP_PAGE_ALREADY_HAVE_AN_ACCOUNT:
    "We already have a Hey Lady! account with your email." +
    "Please log in to reactivate your membership.",
  SIGN_UP_PAGE_WE_RE_SORRY: "We’re sorry!",
  SIGN_UP_PAGE_CLOSE_AND_EXIT: "Close and exit",
  SIGN_UP_PAGE_UNFORTUNATELY_WE_CANNOT_ACCEPT: "Unfortunately we cannot accept your application.",
  SIGN_UP_PAGE_TO_JOIN_OUR_COMMUNITY: "To join our community you must:",
  SIGN_UP_PAGE_BE_A_WOMAN_OVER_18_YEARS_OLD: "Be a woman over 18 years old",
  SIGN_UP_PAGE_HAVE_INTERMEDIATE_LEVEL: "Have Intermediate level English or above",
  SIGN_UP_CONFIRMATION_PAYMENT_SUCCESSFUL: "Payment Successful!",
  SIGN_UP_CONFIRMATION_MEET_YOU: "We can’t wait to meet you!",
  SIGN_UP_CONFIRMATION_THANK_YOU: "Thank you for supporting Hey Lady!",
  SIGN_UP_CONFIRMATION_COPY: "A copy of your receipt has been sent to {email}.",
  SIGN_UP_CONFIRMATION_UPDATE: "You can update your payment settings at any time.",
  SIGN_UP_CONFIRMATION_ENTER_HEY_LADY: "Enter Hey Lady!",
  SIGN_UP_CONFIRMATION_CHECK_EMAIL: "Please check your email",
  SIGN_UP_CONFIRMATION_SENT_YOU:
    "We just sent you a link to {email} to enter the Hey Lady! platform.",
  SIGN_UP_CONFIRMATION_RECEIPT: "We also sent you a copy of your receipt.",
  SIGN_UP_CONFIRMATION_RESEND:
    "Can’t see the email in your inbox or spam folder? <url>Resend the link</url>.",
  SIGN_UP_CHECK_EMAIL: "Check your email",
  SIGN_UP_CHECK_CLICK_ON_THE_LINK:
    "Click on the link we sent to {email} to finish your account setup.",
  SIGN_UP_STILL_NO_EMAIL: "Still no email? <url>Contact Member Support</url>",
  COMMON_EMAIL_NOT_VALID_ERROR: "Please enter a valid email address.",
  COMMON_PASSWORD_MIN_CHAR_ERROR: "Password must be at least 8 characters long.",
  COMMON_USE_YOUR_EMAIL: "or use your email",
  COMMON_YOU: "You",
  COMMON_MONTHLY: "Monthly",
  COMMON_YEARLY: "Yearly",
  COMMON_DELETE: "Delete",
  COMMON_WORDS: "{words} words",
  COMMON_SAVE_DRAFT: "Save as Draft",
  COMMON_CONFIRM_AND_PUBLISH: "Confirm & Publish",
  COMMON_REPORT_ABUSE: "Report abuse",
  COMMON_FEATURED: "Featured",
  COMMON_SEE_MORE: "See more",
  COMMON_ADDITIONAL_MATERIALS: "Additional Materials",
  COMMON_ATTACHMENTS: "Attachments",
  COMMON_RELATED_INTERESTS_TAGS: "Related Interests Tags",
  COMMON_EMPTY_LIST: "There is no content yet.",
  COMMON_MEMBERZINE: "Memberzine",
  COMMON_ADD_REPLY: "Add reply",
  COMMON_CHOOSE_DATE: "Choose date",
  COMMON_ADD_YOUR_COMMENT: "Add your comment...",
  COMMON_AUTHOR: "By {author}",
  COMMON_BACK: "Back",
  COMMON_CANCEL: "Cancel",
  COMMON_SAVE: "Save",
  COMMON_CATEGORY: "Category",
  COMMON_CHAT: "Chat",
  COMMON_CITY: "City",
  COMMON_COMMENTS: "{comments} Comments",
  COMMON_COMING_SOON: "Coming Soon",
  COMMON_COMING_SOON_TITLE: "Coming Soon...",
  COMMON_COMING_SOON_DESCRIPTION:
    "Topic Library is available for members that have been with {title} for more than 12 months.",
  COMMON_CONTACT_US: "Contact us",
  COMMON_EVENTS_ATTENDED: "Events Attended",
  COMMON_PARTICIPATION_TIME: "Participation Time",
  COMMON_COPY_RIGHT: "Copyright © 2024 {title} All rights reserved.",
  COMMON_COUNTRY: "Country",
  COMMON_CREATE_EVENT: "Create Event",
  COMMON_DATE: "Date",
  COMMON_DATE_OF_BIRTH: "Date of Birth",
  COMMON_FINISH: "Finish",
  COMMON_DONE: "Done",
  COMMON_DOWNLOAD: "Download",
  COMMON_DRAFT: "Draft",
  COMMON_DROP_PHOTO: "Drop photo",
  COMMON_DURATION: "Duration",
  COMMON_EDIT_EVENT: "Edit event",
  COMMON_DUPLICATE_EVENT: "Duplicate event",
  COMMON_EMAIL: "Email",
  COMMON_RECURRING: "Recurring",
  COMMON_ENGLISH_LEVEL: "English level",
  COMMON_EVENTS: "Events",
  COMMON_EVENTS_HOSTED: "Events hosted",
  COMMON_FAQ: "FAQ",
  COMMON_FIRST_NAME: "First name",
  COMMON_FOLLOW: "Follow",
  COMMON_UNFOLLOW: "Unfollow",
  COMMON_FROM: "From",
  COMMON_FULL_NAME: "Full name",
  COMMON_GO_BACK: "Go back",
  COMMON_HELP: "Help",
  COMMON_HOME_PAGE: "Home",
  COMMON_LAST_NAME: "Last name",
  COMMON_LEVEL_ADVANCED: "Advanced",
  COMMON_LOCATION: "Location",
  COMMON_LOG_OUT: "Log Out",
  COMMON_MESSAGE: "Message",
  COMMON_MINUTES: "{minutes} min",
  COMMON_MORE_COMMENTS: "More comments",
  COMMON_NAME: "Name",
  COMMON_NATIVE_LANGUAGE: "Native language",
  COMMON_NEW_PASSWORD: "New password",
  COMMON_NEXT: "Next",
  COMMON_GOT_IT: "Got it!",
  COMMON_NEXT_STEP: "Next step",
  COMMON_NO: "No",
  COMMON_OK: "OK",
  COMMON_OR: "or",
  COMMON_NOTIFICATIONS: "Notifications",
  COMMON_NOT_VERIFIED: "Not verified",
  COMMON_OLD_PASSWORD: "Old password",
  COMMON_OTHER_LANGUAGES: "Other languages I speak",
  COMMON_PARTICIPANTS: "Participants",
  COMMON_PASSWORD: "Password",
  COMMON_MEMBERS: "Members",
  COMMON_MY_PROFILE: "My Profile",
  COMMON_PERSONAL_ACCOUNT: "My Account",
  COMMON_PLEASE_WAIT: "Please wait",
  COMMON_PREVIOUS: "Previous",
  COMMON_PRINT: "Print",
  COMMON_REMOVE: "Remove",
  COMMON_REMOVE_PHOTO: "Remove photo",
  COMMON_REPLIES: "{replies} Replies",
  COMMON_REPLY: "Reply",
  COMMON_SAVE_CHANGES: "Save changes",
  COMMON_SEARCH: "Search",
  COMMON_SETTINGS: "Settings",
  COMMON_SUBMIT: "Submit",
  COMMON_SHOW_LESS: "Show less",
  COMMON_SHOW_MORE: "Show more",
  COMMON_START_EVENT: "Start event",
  COMMON_START_DATE: "Start date",
  COMMON_END_DATE: "End date",
  COMMON_START_TIME: "Start time",
  COMMON_END_TIME: "End time",
  COMMON_JOIN_EVENT: "Join event",
  COMMON_KNOCK: "Knock",
  COMMON_STEP: "Step",
  COMMON_UNTIL: "Until",
  COMMON_TIME: "Time",
  COMMON_TIME_NOT_SET: "Time is not set",
  COMMON_TOPICS: "Topics",
  COMMON_UPLOAD_NEW_PHOTO: "Upload New Photo",
  COMMON_VERIFIED_MEMBER: "Verified Member",
  COMMON_VIEW_EVENT: "View Event",
  COMMON_VIEW: "View",
  COMMON_VIEW_PROFILE: "View Profile",
  COMMON_WATCH_INTRO_VIDEO: "Watch Intro Video",
  COMMON_YES: "Yes",
  COMMON_PAGE_ERROR_OCCURRED: "An error occurred",
  COMMON_THERE_WAS_A_PROBLEM_PROCESSING_PAYMENT:
    "We were unable to process your payment. If the error persists please <url>Contact Member Support</url>.",
  COMMON_RETRY: "Retry",
  COMMON_PURCHASE_MEMBERSHIP: "Purchase Membership",
  COMMON_DETAILS_PAGE_TOTAL: "Total:",
  COMMON_DETAILS_PAGE_SUBTOTAL: "Subtotal:",
  COMMON_PAGE_NAME: "First name and Last name",
  COMMON_YOUR_EMAIL: "Your Email",
  COMMON_CARDHOLDER_NAME: "Cardholder Name",
  COMMON_CHOOSE_FROM_LIST: "Choose from list",
  COMMON_CARD_NUMBER: "Card Number",
  COMMON_EXPIRATION_DATE: "Expiration Date",
  COMMON_CVV: "CVV",
  COMMON_ZIPCODE: "Zip Code",
  COMMON_ENTER_ZIPCODE: "Enter zip code",
  COMMON_TERMS_AND_CONDITIONS: "By registering I agree to all <url>Terms & Conditions</url>",
  COMMON_CONFIRM_AND_PAY: "Confirm & pay",
  COMMON_NEED_HELP: "Need help? <url>Contact Member Support</url>",
  COMMON_NO_RESULTS: "Sorry, we don't have anything to show you.",
  COMMON_SORRY: "Sorry!",
  COMMON_PLEASE_UPDATE_FILTERS: "Please update your filter settings.",
  COMMON_INTERMEDIATE: "Intermediate",
  COMMON_ADVANCED: "Advanced",
  COMMON_ANY_LEVEL: "Any level",
  COMMON_CLOSE: "Close",
  COMMON_NEW_MESSAGES: "You have new messages",
  COMMON_NEW_NOTIFICATIONS: "You have unread notifications",
  COMMON_INACTIVE_MEMBER: "Inactive member",
  COMMON_ARE_YOU_SURE: "Are you sure?",
  COMMON_NOT_REVERSIBLE_ACTION: "This action is not reversible and all data will be lost.",
  COMMON_ARTICLES_WE_THINK_YOU_LL_LOVE: "Articles we think you’ll love!",
  COMMON_COMMENT_DELETED: "Comment was deleted successfully",
  COMMON_PROBLEM_DELETING_COMMENT: "There was a problem deleting the comment",
  COMMON_EVENT_DELETED: "Event deleted",
  COMMON_LINKS: "Links",
  COMMON_RELATED_INTERESTS: "Related interests",
  COMMON_LINK_COPIED: "Event link copied to clipboard",
  COMMON_MY_BACKGROUND: "My Background",
  COMMON_MY_NATIONALITY: "Nationality",
  COMMON_START_TYPING: "Please start typing in English",
  COMMON_ADD_EMOJI: "Add emoji",
  COMMON_SEND: "Send",
  COMMON_SPARK: "Spark",
  COMMON_BETA: "Beta version",
  COMMON_SHARE_YOUR_FEEDBACK: "Share your feedback!",
  COMMON_SEND_FEEDBACK: "Send Feedback",
  COMMON_STEP_TOTAL: "Step {step}/{total}",
  COMMON_NEVER_AGAIN: "Never again!",
  COMMON_ABSOLUTELY_WILL: "Absolutely will!",
  COMMON_LETS_START: "Let's Start",
  COMMON_EXIT: "Exit",
  COMMON_CONTINUE_EDITING: "Continue Editing",
  COMMON_BACK_TO_HOME_PAGE: "Back to Home Page",
  COMMON_CONTINUE: "Continue",
  COMMON_FORM_ERRORS_REQUIRED: "This field is required",
  COMMON_FORM_ERRORS_INVALID_EMAIL: "Please provide valid email",
  COMMON_INVITE_A_FRIEND: "Invite a friend",
  COMMON_SUCCESS: "Success!",
  COMMON_CONFIRM: "Confirm",
  COMMON_FILTERS: "Filters",
  COMMON_CUSTOMISE_FILTERS: "Customise Filters",
  COMMON_FILTER_APPLIED: "Filter Applied",
  COMMON_FILTERS_APPLIED: "Filters Applied",
  COMMON_FILTERS_AVAILABILITY: "Availability",
  COMMON_FILTERS_MEMBERS: "Members",
  COMMON_FILTERS_OCCUPATION: "Occupation",
  COMMON_FILTERS_PERSONAL_INTERESTS: "Personal Interests",
  COMMON_FILTERS_ENGLISH_INTERESTS: "English Interests",
  COMMON_FILTERS_ENGLISH_LEVEL: "English Level",
  COMMON_CLEAR_FILTER: "Clear Filter",
  COMMON_CLEAR_ALL: "Clear All",
  COMMON_SHOW_RESULTS: "Show results",
  COMMON_CHOOSE_AN_EVENT: "Choose an event",
  COMMON_NO_RESULTS_FOUND: "No results found",
  COMMON_SHARE_EVENT: "Share Event",
  COMMON_SHARE: "Share",
  COMMON_MEMBER_EVENT: "Member Event",
  DEACTIVATE_PAGE_CANT_JUSTIFY: "I can't justify the time needed to participate",
  DEACTIVATE_PAGE_ENGLISH_LEVEL: "My English level isn't high enough",
  DEACTIVATE_PAGE_EXPENSIVE: "Expensive subscription",
  DEACTIVATE_PAGE_FINANCIAL: "My financial situation has changed",
  DEACTIVATE_PAGE_HOPE_TO_SEE_BACK:
    "We are very sorry to know that you decided to leave our community. Hope to see you back very soon. In case you have any question you can always contact our support team.",
  DEACTIVATE_PAGE_I_WANT_BECAUSE: "I want to delete my account because...",
  DEACTIVATE_PAGE_NOT_INTERESTED: "Not interested in learning English any more",
  DEACTIVATE_PAGE_NOT_USEFUL: "Content is not useful",
  DEACTIVATE_PAGE_OTHER_REASON: "Other reason",
  DEACTIVATE_PAGE_PRACTICE: "I couldn't find someone to practise with",
  DEACTIVATE_PAGE_HELP_US_IMPROVE: "Help us improve by offering feedback about your decision:",
  DEACTIVATE_PAGE_ARE_YOU_SURE_YOU_WANT_TO_LEAVE: "Are you sure you want to leave?",
  DEACTIVATE_PAGE_DELETING_YOUR_ACCOUNT:
    "Deleting your account means that you want to leave our community permanently. We will remove your account and all information.",
  DEACTIVATE_PAGE_WANT_TO_PAUSE:
    "Was this a mistake? If you just want to pause your membership or switch plans, you can do this on the <url>Membership Settings</url> tab in your Account Settings.",
  DEACTIVATE_PAGE_YOU_HAVE_DECIDED_TO_LEAVE:
    "If you have decided to leave, we'll be sad to see you go!",
  DEACTIVATE_PAGE_YOUR_ACCOUNT: "Your account has been deleted",
  ENUM_A1: "Beginner (A1/A2)",
  ENUM_B1: "Intermediate (B1)",
  ENUM_B2: "Upper Intermediate (B2)",
  ENUM_C1: "Advanced (C1)",
  ENUM_C2: "Proficient (C2)",
  ENUM_SUNDAY: "Sun",
  ENUM_MONDAY: "Mon",
  ENUM_TUESDAY: "Tue",
  ENUM_WEDNESDAY: "Wed",
  ENUM_THURSDAY: "Thu",
  ENUM_FRIDAY: "Fri",
  ENUM_SATURDAY: "Sat",
  ENUM_DAILY: "Daily",
  ENUM_ON_DATE: "On date",
  ENUM_AFTER: "After",
  ENUM_NEVER: "Never",
  ENUM_DAYS: "Days",
  ENUM_WEEKS: "Weeks",
  ENUM_MONTHS: "Months",
  ENUM_SELF: "I will host the event",
  ENUM_COHOST: "I will co-host the event",
  ENUM_SOMEBODY_ELSE: "Somebody else will host the event",
  ENUM_WEEKLY_ON_THURSDAY: "Weekly on Thursday",
  ENUM_MONTHLY_ON_THE_FOURTH_THURSDAY: "Monthly on the fourth Thursday",
  ENUM_MONTHLY_ON_THE_LAST_THURSDAY: "Monthly on the last Thursday",
  ENUM_ANUALLY: "Annually on December 19",
  ENUM_EVERY_WEEKDAY: "Every weekday (Monday to Friday)",
  ENUM_CUSTOM: "Custom",
  ENUM_GROUP_EVENT: "Group Event",
  ENUM_USER_BADGE_NONE: "None",
  ENUM_USER_BADGE_INACTIVE_MEMBER: "Inactive Member",
  ENUM_USER_BADGE_LITTLE_SISTER: "Little sister",
  ENUM_USER_BADGE_MEMBER: "Member",
  ENUM_USER_BADGE_BIG_SISTER: "Big sister",
  ENUM_USER_BADGE_ENGLISH_COACH: "English Coach",
  ENUM_USER_BADGE_COMMUNITY_SUCCESS: "Community Success",
  ENUM_USER_BADGE_FOUNDER: "Founder",
  ENUM_USER_BADGE_MEMBER_SUPPORT: "Member support",
  ENUM_USER_BADGE_TECHNICAL_SUPPORT: "Technical Support",
  ENUM_CASUAL_CONVERSATION: "Casual Conversation",
  ENUM_CONVERSATION_STARTER: "Conversation Starter",
  ENUM_STUDY_AND_LEARN: "Study & Learn",
  ENUM_DEEP_DIVE: "Deep Dive",
  ENUM_ENGLISH_LESSON: "English Lesson",
  ENUM_GENERAL_GROUP_EVENT: "General Group Event",
  ENUM_GRAMMAR_SPOT: "Grammar Spot",
  ENUM_GENERAL_EVENT: "General Event",
  ENUM_GROUP_CHAT: "Group Chat",
  ENUM_GROUP_CHAT_DESCRIPTION:
    "Send direct messages to other members or create a group to study, connect with likeminded women and stay in touch!",
  ENUM_GROUP_STUDY: "Group Study",
  ENUM_IN_PERSON: "In Person",
  ENUM_NEW_MEMBER_MEETUP: "New Member Welcome",
  ENUM_OFFICE_HOURS: "Office Hours",
  ENUM_ONE_TO_ONE: "One to One",
  ENUM_OVERVIEW: "Overview",
  ENUM_STUDY_GROUP: "Study Group",
  ENUM_TEACHER_TALK: "Teacher Talk",
  ENUM_VOCABULARY: "Vocabulary",
  ENUM_INTERVIEW: "Interview",
  ENUM_ONLINE: "Online",
  ENUM_OFFLINE: "Offline",
  ENUM_LADIES_LUNCH: "Conversations Over Coffee",
  ENUM_INTEREST_ANIMALS_AND_WILDLIFE: "Animals and wildlife",
  ENUM_INTEREST_ARTS_AND_CRAFTS: "Arts and crafts",
  ENUM_INTEREST_BEAUTY_AND_FASHION: "Beauty and fashion",
  ENUM_INTEREST_BUSINESS_AND_ENTREPRENEURSHIP: "Business and Entrepreneurship",
  ENUM_INTEREST_CAREERS_AND_PROFESSIONAL_DEVELOPMENT: "Careers and professional development",
  ENUM_INTEREST_DIFFERENT_CULTURES: "Different cultures",
  ENUM_INTEREST_DESIGN: "Design",
  ENUM_INTEREST_ECONOMICS: "Economics",
  ENUM_INTEREST_IELTS: "IELTS",
  ENUM_INTEREST_ENVIRONMENTAL_ISSUES: "Environmental issues",
  ENUM_INTEREST_EQUALITY: "Equality",
  ENUM_INTEREST_FAMILY_AND_MOTHERHOOD: "Family and motherhood",
  ENUM_INTEREST_FEMINISM_AND_EMPOWERMENT: "Feminism and empowerment",
  ENUM_INTEREST_FOOD_AND_COOKING: "Food and cooking",
  ENUM_INTEREST_GAMES: "Games",
  ENUM_INTEREST_GOALS_SETTING_AND_SUCCESS: "Goals setting and success",
  ENUM_INTEREST_HEALTH_AND_WELLNESS: "Health and wellness",
  ENUM_INTEREST_HIKING: "Hiking",
  ENUM_INTEREST_HISTORY: "History",
  ENUM_INTEREST_HOME_AND_GARDENING: "Home and gardening",
  ENUM_INTEREST_LANGUAGES: "Languages",
  ENUM_INTEREST_LEADERSHIP: "Leadership",
  ENUM_INTEREST_LIVING_ABROAD: "Living abroad",
  ENUM_INTEREST_LOVE_AND_RELATIONSHIPS: "Love and relationships",
  ENUM_INTEREST_MOVIES_AND_TV_SHOWS: "Movies and TV shows",
  ENUM_INTEREST_MUSIC_AND_DANCE: "Music and dance",
  ENUM_INTEREST_NATURE_AND_THE_ENVIRONMENT: "Nature and the environment",
  ENUM_INTEREST_PERSONAL_DEVELOPMENT: "Personal development",
  ENUM_INTEREST_PHOTOGRAPHY: "Photography",
  ENUM_INTEREST_POLITICS: "Politics",
  ENUM_INTEREST_PSYCHOLOGY: "Psychology",
  ENUM_INTEREST_READING: "Reading",
  ENUM_INTEREST_SCIENCE_AND_TECHNOLOGY: "Science and technology",
  ENUM_INTEREST_SPIRITUALITY: "Spirituality",
  ENUM_INTEREST_SPORTS: "Sports",
  ENUM_INTEREST_SUSTAINABLE_LIVING: "Sustainable living",
  ENUM_INTEREST_THEATRE_AND_PERFORMANCE: "Theatre and performance",
  ENUM_INTEREST_TRAVEL: "Travel",
  ENUM_INTEREST_VIDEO_GAMES: "Video games",
  ENUM_INTEREST_VOLUNTEERING_AND_CHARITY: "Volunteering and charity",
  ENUM_INTEREST_WRITING: "Writing",
  ENUM_ENGLISH_SKILL_GIVING_A_PRESENTATION: "Give a presentation or speak in public",
  ENUM_ENGLISH_SKILL_INTERVIEW_OR_BE_INTERVIEWED: "Interview or be interviewed",
  ENUM_ENGLISH_SKILL_INTRODUCE_MYSELF: "Introduce myself",
  ENUM_ENGLISH_SKILL_MEET_NEW_PEOPLE: "Meet new people using English",
  ENUM_ENGLISH_SKILL_NEGOTIATE_AND_DEBATE: "Negotiate & Debate",
  ENUM_ENGLISH_SKILL_PASS_AN_ENGLISH_EXAM: "Pass an English exam",
  ENUM_ENGLISH_SKILL_PERSONAL_NETWORKING: "Personal networking",
  ENUM_ENGLISH_SKILL_PRONUNCIATION: "Pronunciation",
  ENUM_ENGLISH_SKILL_SPEAK_CONFIDENTLY_AT_WORK: "Speak confidently at work",
  ENUM_ENGLISH_SKILL_THINK_IN_ENGLISH: "Think in English",
  ENUM_ENGLISH_SKILL_UNDERSTAND_MOVIES_AND_MUSIC: "Understand movies & music",
  ENUM_ENGLISH_SKILL_UNDERSTAND_NATIVE_SPEAKERS: "Understand native speakers",
  ENUM_ENGLISH_SKILL_WRITE_CREATIVELY: "Write creatively",
  ENUM_ENGLISH_SKILL_WRITE_PROFESSIONALLY: "Writing professionally",
  ENUM_NONE: "None",
  ENUM_MEMBER: "Member",
  ENUM_BIG_SISTER: "Big Sister",
  ENUM_COMMUNITY_SUCCESS: "Community Success",
  ENUM_CURIOUS_LADY: "Curious Lady",
  ENUM_CUSTOMER_SUPPORT: "Customer Support",
  ENUM_ENGLISH_COACH: "English Coach",
  ENUM_FOUNDER: "Founder",
  ENUM_PRIVATE_EVENT: "Private",
  ENUM_PUBLIC_EVENT: "Public",
  ENUM_TECHNICAL_SUPPORT: "Technical Support",
  ENUM_LITTLE_SISTERS: "Little Sisters",
  ENUM_LITTLE_SISTER: "Little Sister",
  ENUM_UNVERIFIED: "Unverified",
  ENUM_ZERO_TO_ONE: "Little sisters",
  ENUM_ONE_TO_THREE: "1-3 months",
  ENUM_THREE_TO_TWELVE: "3-12 months",
  ENUM_THREE_TO_SIX_MONTH: "3-6 month",
  ENUM_SIX_TO_TWELVE_MONTH: "6-12 month",
  ENUM_MORE_THAN_TWELVE_MONTHS: "12 months+",
  ENUM_EVENT_DURATION_FILTER_15: "15 minutes",
  ENUM_EVENT_DURATION_FILTER_30: "30 minutes",
  ENUM_EVENT_DURATION_FILTER_45: "45 minutes",
  ENUM_EVENT_DURATION_FILTER_60: "1 hour",
  ENUM_EVENT_DURATION_FILTER_90: "1,5 hours",
  ENUM_EVENT_DURATION_FILTER_120: "2 hours and more",
  ENUM_ART_AND_CULTURE: "Art and Culture",
  ENUM_CAREER_WOMEN: "Career Women",
  ENUM_COMMUNITY: "Community",
  ENUM_FAMILY_AND_FRIENDS: "Family and Friends",
  ENUM_FOOD_AND_TRADITIONS: "Food and Traditions",
  ENUM_HEALTH_AND_WELLNESS: "Health and Wellness",
  ENUM_PERSONAL_DEVELOPMENT: "Personal Development",
  ENUM_SCIENCE_AND_TECHNOLOGY: "Science and Technology",
  ENUM_THE_ENVIRONMENT: "The Environment",
  ENUM_TRAVEL: "Travel",
  ENUM_ACCOMMODATION_AND_HOSPITALITY: "Accommodation & Hospitality",
  ENUM_ADMINISTRATIVE_AND_SUPPORT_SERVICES: "Administrative and Support Services",
  ENUM_DESIGN_AND_CONSTRUCTION: "Design & Construction",
  ENUM_CONSUMER_SERVICES: "Consumer Services",
  ENUM_EDUCATION: "Education",
  ENUM_ARTS_AND_ENTERTAINMENT: "Arts & Entertainment",
  ENUM_ANIMALS_HORTICULTURE_FORESTRY: "Animals, Horticulture, Forestry",
  ENUM_FINANCIAL_SERVICES: "Financial Services",
  ENUM_GOVERNMENT_ADMINISTRATION: "Government Administration",
  ENUM_HOSPITAL_AND_MEDICAL: "Hospital & Medical",
  ENUM_HEALTH_AND_WELLBEING: "Health & Wellbeing",
  ENUM_MANUFACTURING: "Manufacturing",
  ENUM_OIL_GAS_AND_MINING: "Oil, Gas, and Mining",
  ENUM_PROFESSIONAL_SERVICES: "Professional Services",
  ENUM_REAL_ESTATE: "Real Estate",
  ENUM_RETAIL: "Retail",
  ENUM_SCIENCE_AND_RESEARCH: "Science & Research",
  ENUM_SPORTS_AND_RECREATION: "Sports & Recreation",
  ENUM_TECHNOLOGY_INFORMATION_AND_MEDIA: "Technology, Information and Media",
  ENUM_TRANSPORTATION: "Transportation",
  ENUM_HOME_CARE: "Home Care",
  ENUM_RETIRED: "Retired",
  ENUM_STUDENTS: "Students",
  ENUM_ASSISTANT_COOK: "Assistant Cook",
  ENUM_BAKER: "Baker",
  ENUM_BAR_MANAGER: "Bar Manager",
  ENUM_BAR_STAFF: "Bar Staff",
  ENUM_CAFE_OWNER: "Cafe Owner",
  ENUM_CAFE_STAFF: "Cafe Staff",
  ENUM_CATERER: "Caterer",
  ENUM_CHEF: "Chef",
  ENUM_COOK: "Cook",
  ENUM_GUEST_HOUSE_OWNER: "Guest House Owner",
  ENUM_HOSTESS: "Hostess",
  ENUM_HOTELIER: "Hotelier",
  ENUM_HOTEL_WORKER: "Hotel Worker",
  ENUM_KITCHEN_HAND: "Kitchen Hand",
  ENUM_LANDLORD: "Landlord",
  ENUM_PASTRY_CHEF: "Pastry Chef",
  ENUM_RESTAURANT_MANAGER: "Restaurant Manager",
  ENUM_RESTAURATEUR: "Restaurateur",
  ENUM_WAITRESS: "Waitress",
  ENUM_ACCOUNTS_MANAGER: "Accounts Manager",
  ENUM_ADMINISTRATION_ASSISTANT: "Administration Assistant",
  ENUM_ADMINISTRATOR: "Administrator",
  ENUM_ASSISTANT_MANAGER: "Assistant Manager",
  ENUM_CLAIMS_ASSESSOR: "Claims Assessor",
  ENUM_CLERK: "Clerk",
  ENUM_LEGAL_ASSISTANT: "Legal Assistant",
  ENUM_LEGAL_SECRETARY: "Legal Secretary",
  ENUM_OFFICE_MANAGER: "Office Manager",
  ENUM_PAYROLL_ASSISTANT: "Payroll Assistant",
  ENUM_PAYROLL_MANAGER: "Payroll Manager",
  ENUM_RECEPTIONIST: "Receptionist",
  ENUM_REGISTRAR: "Registrar",
  ENUM_SALES_ADMINISTRATOR: "Sales Administrator",
  ENUM_SECRETARY: "Secretary",
  ENUM_SUPERVISOR: "Supervisor",
  ENUM_TRAINING_ASSISTANT: "Training Assistant",
  ENUM_TRAINING_MANAGER: "Training Manager",
  ENUM_VIRTUAL_ASSISTANT: "Virtual Assistant",
  ENUM_ARBORIST: "Arborist",
  ENUM_BREEDER: "Breeder",
  ENUM_FARMER: "Farmer",
  ENUM_FOREST_RANGER: "Forest Ranger",
  ENUM_GARDEN_DESIGNER: "Garden Designer",
  ENUM_GARDENER: "Gardener",
  ENUM_HORSE_BREEDER: "Horse Breeder",
  ENUM_HORSE_TRAINER: "Horse Trainer",
  ENUM_HORTICULTURALIST: "Horticulturalist",
  ENUM_LANDSCAPER: "Landscaper",
  ENUM_NURSERY_WORKER: "Nursery Worker",
  ENUM_VETERINARIAN: "Veterinarian",
  ENUM_VETERINARY_SURGEON: "Veterinary Surgeon",
  ENUM_ZOO_KEEPER: "Zoo Keeper",
  ENUM_ACTOR: "Actor",
  ENUM_ACTRESS: "Actress",
  ENUM_ART_CRITIC: "Art Critic",
  ENUM_ART_HISTORIAN: "Art Historian",
  ENUM_ARTIST: "Artist",
  ENUM_BROADCASTER: "Broadcaster",
  ENUM_CAMERAWOMAN: "Camerawoman",
  ENUM_CARTOONIST: "Cartoonist",
  ENUM_CHOREOGRAPHER: "Choreographer",
  ENUM_COMEDIAN: "Comedian",
  ENUM_ENTERTAINER: "Entertainer",
  ENUM_FASHION_DESIGNER: "Fashion Designer",
  ENUM_ILLUSTRATOR: "Illustrator",
  ENUM_JEWELLERY_MAKER: "Jewellery Maker",
  ENUM_MAGICIAN: "Magician",
  ENUM_MUSICIAN: "Musician",
  ENUM_OPERA_SINGER: "Opera Singer",
  ENUM_PHOTOGRAPHER: "Photographer",
  ENUM_RADIO_PRESENTER: "Radio Presenter",
  ENUM_SCREEN_WRITER: "Screen Writer",
  ENUM_SCRIPT_WRITER: "Script Writer",
  ENUM_SCULPTOR: "Sculptor",
  ENUM_SINGER: "Singer",
  ENUM_STAGE_MANAGER: "Stage Manager",
  ENUM_TATTOOIST: "Tattooist",
  ENUM_THEATRE_MANAGER: "Theatre Manager",
  ENUM_TV_PRESENTER: "TV Presenter",
  ENUM_WRITER: "Writer",
  ENUM_AUTO_ELECTRICIAN: "Auto Electrician",
  ENUM_BAGGAGE_HANDLER: "Baggage Handler",
  ENUM_BARBER: "Barber",
  ENUM_BEAUTICIAN: "Beautician",
  ENUM_BEAUTY_THERAPIST: "Beauty Therapist",
  ENUM_BOOK_BINDER: "Book Binder",
  ENUM_BUTCHER: "Butcher",
  ENUM_CARPET_CLEANER: "Carpet Cleaner",
  ENUM_CASHIER: "Cashier",
  ENUM_CHAPLAIN: "Chaplain",
  ENUM_CHARITY_WORKER: "Charity Worker",
  ENUM_CHEMIST: "Chemist",
  ENUM_CLEANER: "Cleaner",
  ENUM_DOG_WALKER: "Dog Walker",
  ENUM_HAIRDRESSER: "Hairdresser",
  ENUM_HOUSEKEEPER: "Housekeeper",
  ENUM_LAUNDRY_STAFF: "Laundry Staff",
  ENUM_LIBRARIAN: "Librarian",
  ENUM_LIFEGUARD: "Lifeguard",
  ENUM_LIFT_ATTENDANT: "Lift Attendant",
  ENUM_MAKE_UP_ARTIST: "Make Up Artist",
  ENUM_MECHANIC: "Mechanic",
  ENUM_METER_READER: "Meter Reader",
  ENUM_NEWSAGENT: "Newsagent",
  ENUM_OUTREACH_WORKER: "Outreach Worker",
  ENUM_PET_MINDER: "Pet Minder",
  ENUM_PRIEST: "Priest",
  ENUM_PRINTER: "Printer",
  ENUM_SECURITY_GUARD: "Security Guard",
  ENUM_TELEMARKETER: "Telemarketer",
  ENUM_TEXTILE_CONSULTANT: "Textile Consultant",
  ENUM_TOUR_GUIDE: "Tour Guide",
  ENUM_ARCHITECT: "Architect",
  ENUM_BUILDER: "Builder",
  ENUM_BUILDING_ENGINEER: "Building Engineer",
  ENUM_BUILDING_SURVEYOR: "Building Surveyor",
  ENUM_CABINET_MAKER: "Cabinet Maker",
  ENUM_CARPENTER: "Carpenter",
  ENUM_CIVIL_ENGINEER: "Civil Engineer",
  ENUM_ENGINEER: "Engineer",
  ENUM_INTERIOR_DECORATOR: "Interior Decorator",
  ENUM_INTERIOR_DESIGNER: "Interior Designer",
  ENUM_LANDSCAPE_ARCHITECT: "Landscape Architect",
  ENUM_LIGHTING_DESIGNER: "Lighting Designer",
  ENUM_PAINTER: "Painter",
  ENUM_QUANTITY_SURVEYOR: "Quantity Surveyor",
  ENUM_TRADESPERSON: "Tradesperson",
  ENUM_TRADES_PERSON: "Trades Person",
  ENUM_ASSISTANT_TEACHER: "Assistant Teacher",
  ENUM_CHILDCARE_WORKER: "Childcare Worker",
  ENUM_ENGLISH_TEACHER: "English Teacher",
  ENUM_HIGH_SCHOOL_TEACHER: "High School Teacher",
  ENUM_INSTRUCTOR: "Instructor",
  ENUM_LECTURER: "Lecturer",
  ENUM_PRE_SCHOOL_TEACHER: "Pre-School Teacher",
  ENUM_PRIMARY_SCHOOL_TEACHER: "Primary School Teacher",
  ENUM_PRINCIPAL: "Principal",
  ENUM_TEACHER: "Teacher",
  ENUM_TEACHERS_ASSISTANT: "Teachers Assistant",
  ENUM_TUTOR: "Tutor",
  ENUM_VICE_PRINCIPAL: "Vice-Principal",
  ENUM_ACCOUNTANT: "Accountant",
  ENUM_BANK_MANAGER: "Bank Manager",
  ENUM_BOOKKEEPER: "Bookkeeper",
  ENUM_ENTREPRENEUR: "Entrepreneur",
  ENUM_INSTRUCTIONAL_DESIGNER: "Instructional Designer",
  ENUM_FINANCIAL_TRADER: "Financial Trader",
  ENUM_INVESTMENT_ADVISOR: "Investment Advisor",
  ENUM_INVESTMENT_MANAGER: "Investment Manager",
  ENUM_INVESTOR: "Investor",
  ENUM_LOANS_MANAGER: "Loans Manager",
  ENUM_MERCHANT_BANKER: "Merchant Banker",
  ENUM_TRADER: "Trader",
  ENUM_TREASURER: "Treasurer",
  ENUM_CAREERS_ADVISOR: "Careers Advisor",
  ENUM_COMMUNITY_WORKER: "Community Worker",
  ENUM_COUNCILLOR: "Councillor",
  ENUM_DIPLOMAT: "Diplomat",
  ENUM_FIREWOMAN: "Firewoman",
  ENUM_HEALTH_ADVISOR: "Health Advisor",
  ENUM_HOUSING_OFFICER: "Housing Officer",
  ENUM_IMMIGRATION_OFFICER: "Immigration Officer",
  ENUM_INVESTIGATOR: "Investigator",
  ENUM_JUDGE: "Judge",
  ENUM_LOCAL_GOVERNMENT: "Local Government",
  ENUM_MAGISTRATE: "Magistrate",
  ENUM_MILITARY_PERSONNEL: "Military Personnel",
  ENUM_PARKING_ENFORCEMENT: "Parking Enforcement",
  ENUM_POLICE_OFFICER: "Police Officer",
  ENUM_POLICY_MAKER: "Policy Maker",
  ENUM_POLITICIAN: "Politician",
  ENUM_POSTWOMAN: "Postwoman",
  ENUM_PUBLIC_RELATIONS_OFFICER: "Public Relations Officer",
  ENUM_SOCIAL_WORKER: "Social Worker",
  ENUM_STATUTORY_PLANNER: "Statutory Planner",
  ENUM_TAX_OFFICER: "Tax Officer",
  ENUM_TAX_ADVISOR: "Tax Advisor",
  ENUM_TOWN_CLERK: "Town Clerk",
  ENUM_TOWN_PLANNER: "Town Planner",
  ENUM_YOUTH_WORKER: "Youth Worker",
  ENUM_ACUPUNCTURIST: "Acupuncturist",
  ENUM_ALTERNATIVE_MEDICINE_CONSULTANT: "Alternative Medicine Consultant",
  ENUM_AROMATHERAPIST: "Aromatherapist",
  ENUM_CHIROPRACTOR: "Chiropractor",
  ENUM_HERBALIST: "Herbalist",
  ENUM_HOMEOPATH: "Homeopath",
  ENUM_HYPNOTHERAPIST: "Hypnotherapist",
  ENUM_MASSAGE_THERAPIST: "Massage Therapist",
  ENUM_MEDITATION_TEACHER: "Meditation Teacher",
  ENUM_MINDFULNESS_COACH: "Mindfulness Coach",
  ENUM_NATUROPATH: "Naturopath",
  ENUM_NUTRITIONIST: "Nutritionist",
  ENUM_REFLEXOLOGIST: "Reflexologist",
  ENUM_SPEECH_THERAPIST: "Speech Therapist",
  ENUM_YOGA_TEACHER: "Yoga Teacher",
  ENUM_AU_PAIR: "Au Pair",
  ENUM_FULL_TIME_MUM: "Full Time Mum",
  ENUM_HOUSEWIFE: "Housewife",
  ENUM_NANNY: "Nanny",
  ENUM_AMBULANCE_DRIVER: "Ambulance Driver",
  ENUM_ANAESTHETIST: "Anaesthetist",
  ENUM_ASSISTANT_NURSE: "Assistant Nurse",
  ENUM_AUDIOLOGIST: "Audiologist",
  ENUM_CARE_ASSISTANT: "Care Assistant",
  ENUM_CHIROPODIST: "Chiropodist",
  ENUM_DENTIST: "Dentist",
  ENUM_DIETITIAN: "Dietitian",
  ENUM_DOCTOR: "Doctor",
  ENUM_GENERAL_PRACTITIONER: "General Practitioner",
  ENUM_GYNAECOLOGIST: "Gynaecologist",
  ENUM_HEALTH_CARE_ASSISTANT: "Health Care Assistant",
  ENUM_HOSPITAL_MANAGER: "Hospital Manager",
  ENUM_HOSPITAL_WARDEN: "Hospital Warden",
  ENUM_HOSPITAL_WORKER: "Hospital Worker",
  ENUM_MIDWIFE: "Midwife",
  ENUM_NEUROLOGIST: "Neurologist",
  ENUM_NURSE: "Nurse",
  ENUM_NURSERY_ASSISTANT: "Nursery Assistant",
  ENUM_OPTICIAN: "Optician",
  ENUM_OPTOMETRIST: "Optometrist",
  ENUM_ORTHODONTIST: "Orthodontist",
  ENUM_OSTEOPATH: "Osteopath",
  ENUM_PAEDIATRICIAN: "Paediatrician",
  ENUM_PARAMEDIC: "Paramedic",
  ENUM_PATHOLOGIST: "Pathologist",
  ENUM_PHARMACIST: "Pharmacist",
  ENUM_PODIATRIST: "Podiatrist",
  ENUM_PSYCHIATRIST: "Psychiatrist",
  ENUM_PSYCHOLOGIST: "Psychologist",
  ENUM_RADIOLOGIST: "Radiologist",
  ENUM_FACTORY_WORKER: "Factory Worker",
  ENUM_MANUFACTURER: "Manufacturer",
  ENUM_MILLINER: "Milliner",
  ENUM_POTTER: "Potter",
  ENUM_QUALITY_ASSURANCE_MANAGER: "Quality Assurance Manager",
  ENUM_RUG_MAKER: "Rug Maker",
  ENUM_SCREEN_PRINTER: "Screen Printer",
  ENUM_SEAMSTRESS: "Seamstress",
  ENUM_SHOE_MAKER: "Shoe Maker",
  ENUM_WEAVER: "Weaver",
  ENUM_MINING_CONSULTANT: "Mining Consultant",
  ENUM_MINING_ENGINEER: "Mining Engineer",
  ENUM_ADVERTISING_MANAGER: "Advertising Manager",
  ENUM_ANALYST: "Analyst",
  ENUM_BARRISTER: "Barrister",
  ENUM_BUSINESS_CONSULTANT: "Business Consultant",
  ENUM_BUSINESS_OWNER: "Business Owner",
  ENUM_CHIEF_EXECUTIVE_OFFICER: "Chief Executive Officer",
  ENUM_CHIEF_OPERATIONS_OFFICER: "Chief Operations Officer",
  ENUM_CONSULTANT: "Consultant",
  ENUM_ECONOMIST: "Economist",
  ENUM_EXECUTIVE_DIRECTOR: "Executive Director",
  ENUM_EXECUTIVE_MANAGER: "Executive Manager",
  ENUM_GENERAL_MANAGER: "General Manager",
  ENUM_INSURANCE_CONSULTANT: "Insurance Consultant",
  ENUM_INTERPRETER: "Interpreter",
  ENUM_JOURNALIST: "Journalist",
  ENUM_LAW_CLERK: "Law Clerk",
  ENUM_LAWYER: "Lawyer",
  ENUM_LEGAL_ADVISOR: "Legal Advisor",
  ENUM_MARKETING_DIRECTOR: "Marketing Director",
  ENUM_OPERATIONS_MANAGER: "Operations Manager",
  ENUM_PLANNER: "Planner",
  ENUM_PROJECT_MANAGER: "Project Manager",
  ENUM_RECRUITMENT_CONSULTANT: "Recruitment Consultant",
  ENUM_SALES_MANAGER: "Sales Manager",
  ENUM_CARETAKER: "Caretaker",
  ENUM_MORTGAGE_BROKER: "Mortgage Broker",
  ENUM_PROPERTY_BUYER: "Property Buyer",
  ENUM_PROPERTY_DEVELOPER: "Property Developer",
  ENUM_PROPERTY_MANAGER: "Property Manager",
  ENUM_PROPERTY_VALUER: "Property Valuer",
  ENUM_REAL_ESTATE_AGENT: "Real Estate Agent",
  ENUM_ANTIQUE_DEALER: "Antique Dealer",
  ENUM_BOOK_SELLER: "Book Seller",
  ENUM_CAR_DEALER: "Car Dealer",
  ENUM_FLORIST: "Florist",
  ENUM_FURNITURE_DEALER: "Furniture Dealer",
  ENUM_GALLERY_OWNER: "Gallery Owner",
  ENUM_GROCER: "Grocer",
  ENUM_JEWELLER: "Jeweller",
  ENUM_PURCHASING_MANAGER: "Purchasing Manager",
  ENUM_RETAIL_ASSISTANT: "Retail Assistant",
  ENUM_SALES_ASSISTANT: "Sales Assistant",
  ENUM_SHOP_ASSISTANT: "Shop Assistant",
  ENUM_SHOP_KEEPER: "Shop Keeper",
  ENUM_SUPERMARKET_ASSISTANT: "Supermarket Assistant",
  ENUM_TICKET_AGENT: "Ticket Agent",
  ENUM_TRAVEL_AGENT: "Travel Agent",
  ENUM_ANTHROPOLOGIST: "Anthropologist",
  ENUM_ARCHAEOLOGIST: "Archaeologist",
  ENUM_ASTROLOGER: "Astrologer",
  ENUM_ASTRONOMER: "Astronomer",
  ENUM_BIOCHEMIST: "Biochemist",
  ENUM_BIOLOGIST: "Biologist",
  ENUM_BOTANIST: "Botanist",
  ENUM_CARTOGRAPHER: "Cartographer",
  ENUM_GENEALOGIST: "Genealogist",
  ENUM_GEOLOGIST: "Geologist",
  ENUM_GEOPHYSICIST: "Geophysicist",
  ENUM_HISTORIAN: "Historian",
  ENUM_INDUSTRIAL_CHEMIST: "Industrial Chemist",
  ENUM_INVENTOR: "Inventor",
  ENUM_LABORATORY_ANALYST: "Laboratory Analyst",
  ENUM_LINGUIST: "Linguist",
  ENUM_METALLURGIST: "Metallurgist",
  ENUM_METEOROLOGIST: "Meteorologist",
  ENUM_MICROBIOLOGIST: "Microbiologist",
  ENUM_NUCLEAR_SCIENTIST: "Nuclear Scientist",
  ENUM_PHYSICIST: "Physicist",
  ENUM_RESEARCHER: "Researcher",
  ENUM_SCIENTIST: "Scientist",
  ENUM_TAXIDERMIST: "Taxidermist",
  ENUM_ZOOLOGIST: "Zoologist",
  ENUM_FITNESS_COACH: "Fitness Coach",
  ENUM_GYM_INSTRUCTOR: "Gym Instructor",
  ENUM_OUTDOOR_EDUCATION_STAFF: "Outdoor Education Staff",
  ENUM_PERSONAL_TRAINER: "Personal Trainer",
  ENUM_PHYSICAL_EDUCATION_INSTRUCTOR: "Physical Education Instructor",
  ENUM_PILATES_INSTRUCTOR: "Pilates Instructor",
  ENUM_PROFESSIONAL_ATHLETE: "Professional Athlete",
  ENUM_SPORTS_COACH: "Sports Coach",
  ENUM_MASTERS_STUDENT: "Masters Student",
  ENUM_PHD_STUDENT: "PHD Student",
  ENUM_STUDENT: "Student",
  ENUM_CHIEF_MARKETING_OFFICER: "Chief Marketing Officer",
  ENUM_CHIEF_TECHNICAL_OFFICER: "Chief Technical Officer",
  ENUM_COMMUNICATIONS_MANAGER: "Communications Manager",
  ENUM_COMMUNITY_MANAGER: "Community Manager",
  ENUM_CONTENT_CREATOR: "Content Creator",
  ENUM_CYBER_SECURITY_CONSULTANT: "Cyber Security Consultant",
  ENUM_EDITOR: "Editor",
  ENUM_GRAPHIC_DESIGNER: "Graphic Designer",
  ENUM_IT_CONSULTANT: "IT Consultant",
  ENUM_MARKETING_ASSISTANT: "Marketing Assistant",
  ENUM_MARKETING_MANAGER: "Marketing Manager",
  ENUM_MARKET_RESEARCHER: "Market Researcher",
  ENUM_PRODUCER: "Producer",
  ENUM_PRODUCT_DESIGNER: "Product Designer",
  ENUM_PRODUCT_MANAGER: "Product Manager",
  ENUM_PUBLISHER: "Publisher",
  ENUM_SOCIAL_MEDIA_ASSISTANT: "Social Media Assistant",
  ENUM_SOFTWARE_DEVELOPER: "Software Developer",
  ENUM_SOFTWARE_ENGINEER: "Software Engineer",
  ENUM_SOFTWARE_TESTER: "Software Tester",
  ENUM_SOUND_ENGINEER: "Sound Engineer",
  ENUM_SYSTEMS_ENGINEER: "Systems Engineer",
  ENUM_UX_UI_DESIGNER: "UX/UI Designer",
  ENUM_VIDEO_EDITOR: "Video Editor",
  ENUM_WEB_DEVELOPER: "Web Developer",
  ENUM_AERONAUTICAL_ENGINEER: "Aeronautical Engineer",
  ENUM_AIRCRAFT_ENGINEER: "Aircraft Engineer",
  ENUM_AIRPORT_MANAGER: "Airport Manager",
  ENUM_AIR_TRAFFIC_CONTROLLER: "Air Traffic Controller",
  ENUM_BUS_DRIVER: "Bus Driver",
  ENUM_DELIVERY_DRIVER: "Delivery Driver",
  ENUM_DRIVER: "Driver",
  ENUM_FLYING_INSTRUCTOR: "Flying Instructor",
  ENUM_MARINE_ENGINEER: "Marine Engineer",
  ENUM_PASSENGER_DRIVER: "Passenger Driver",
  ENUM_PILOT: "Pilot",
  ENUM_TRANSPORT_OFFICER: "Transport Officer",
  EVENTS_PAGE_USE_FILTERS: "Use event filters",
  EVENTS_PAGE_EXIT_FILTERS: "Exit event filters",
  EVENTS_PAGE_TOP_PICKS: "Top Picks for you this week",
  EVENTS_PAGE_TOP_PICKS_SUBTITLE:
    "These are the events we think you’ll enjoy, based on your profile information",
  EVENTS_PAGE_CONVERSATION_PRACTICE: "Events for Conversation Practice",
  EVENTS_PAGE_CONVERSATION_SUBTITLE:
    "Let your fluency flow as you practise your conversation skills in these events. ",
  EVENTS_PAGE_STUDY_AND_LEARN: "Events to Study & Learn",
  EVENTS_PAGE_STUDY_SUBTITLE: "The perfect way sharpen your grammar, vocabulary or pronunciation! ",
  EVENTS_PAGE_DIVE_DEEPER: "Events to Dive Deeper",
  EVENTS_PAGE_DIVE_DEEPER_SUBTITLE:
    "Explore different formats and challenge yourself to try new ways to experience English.",
  EVENTS_PAGE_SEARCH_EVENTS: "Search all events with filters",
  EVENTS_PAGE_BANNER_CREATE_CANT_FIND: "Can’t find the right event?",
  EVENTS_PAGE_BANNER_USE_FILTERS: "You can try using filters to search all events.",
  EVENTS_PAGE_BANNER_SEARCH: "Search all events",
  EVENTS_PAGE_FILTERS_LOCATION: "Location",
  EVENTS_PAGE_FILTERS_TYPES: "Event Types",
  EVENTS_PAGE_FILTERS_EVENT_TIME: "Event Time",
  EVENTS_PAGE_FILTERS_AVAILABLE_WHEN_I_AM: "Available When I Am",
  EVENTS_PAGE_FILTERS_SIMILAR_TIMES: "Similar Times",
  EVENTS_PAGE_FILTERS_ANY_TIME: "Any Time",
  EVENTS_PAGE_FILTERS_HOSTED_BY: "Hosted By",
  EVENTS_PAGE_FILTERS_HOSTED_BY_ANYONE: "Hosted By Anyone",
  EVENTS_PAGE_FILTERS_PEOPLE_I_FOLLOW: "People I Follow",
  EVENTS_PAGE_FILTERS_HL_MEMBERS: "{HL} Members",
  EVENTS_PAGE_FILTERS_HL_COACHES: "{HL} Coach",
  EVENTS_PAGE_FILTERS_TEAM: "{HL} Team",
  EVENTS_PAGE_FILTERS_BIG_SISTERS: "Big Sisters",
  EVENTS_PAGE_FILTERS_ALL_EVENT_TYPES: "All Event Types",
  EVENTS_PAGE_FILTERS_CONVERSATIONS: "Conversations",
  EVENTS_PAGE_FILTERS_STUDY_EVENTS: "Study & Learn",
  EVENTS_PAGE_FILTERS_DEEP_DIVE: "Deep Dive",
  EVENTS_PAGE_FILTERS_PROFESSIONAL_EVENTS: "Professional",
  EVENTS_PAGE_FILTERS_LISTEN_ONLY: "Listen Only",
  EVENTS_PAGE_FILTERS_IN_PERSON_EVENTS: "In Person",
  EVENTS_PAGE_FILTERS_NEW_MEMBER_WELCOME: "NM Welcome",
  EVENTS_PAGE_FILTERS_ANY_LEVEL: "Any Level",
  EVENTS_PAGE_FILTERS_EVENT_LEVEL: "All Event Levels",
  EVENTS_PAGE_FILTERS_INTERMEDIATE: "Intermediate",
  EVENTS_PAGE_FILTERS_ADVANCED: "Advanced",
  EVENTS_PAGE_FILTERS_CHOOSE_AVAILABILITY: "Choose Availability",
  EVENTS_PAGE_FILTERS_ADVANCED_FILTERS: "Advanced filters",
  EVENTS_PAGE_SHOWING_ALL_EVENTS: "Showing all events",
  EVENTS_PAGE_USE_THE_FILERS: "You can use the filters to find what you're looking for",
  EVENTS_PAGE_FILTERED_RESULTS: "Filtered Results",
  EVENTS_PAGE_ADJUST_FILTERS: "Sorted by relevance. Add or remove filters to adjust your search",
  EVENTS_PAGE_ALL_EVENTS: "All Events",
  EVENTS_PAGE_RECOMMENDED: "Recommended",
  EVENTS_PAGE_RECOMMENDED_EVENTS: "Recommended Events",
  EVENTS_PAGE_EVENTS_I_M_GOING_TO: "Events I’m Going To",
  EVENTS_PAGE_I_M_GOING_TO: "I’m Going To",
  EVENTS_PAGE_EVENTS_I_M_HOSTING: "Events I'm Hosting",
  EVENTS_PAGE_I_M_HOSTING: "I'm Hosting",
  EVENTS_PAGE_EVENTS_I_WENT_TO: "Events I Went To",
  EVENTS_PAGE_I_WENT_TO: "I Went To",
  EVENTS_PAGE_EVENTS_I_HOSTED: "Events I Hosted",
  EVENTS_PAGE_I_HOSTED: "I Hosted",
  EVENTS_PAGE_MY_EVENTS: "My Events",
  EVENTS_PAGE_FULL_EVENT: "FULL",
  EVENTS_PAGE_FULL: "Full event!",
  EVENTS_PAGE_YOU_RE_GOING: "You're going!",
  EVENTS_PAGE_YOU_RE_INVITED: "You're invited!",
  EVENTS_PAGE_IN_PROGRESS: "In progress",
  EVENTS_PAGE_LIVE: "Live",
  EVENTS_PAGE_FULL_EVENTS: "Full Events",
  EVENTS_PAGE_REMOVE_TOPIC: "Remove Topic",
  EVENTS_PAGE_HIDE_FULL_EVENTS: "Hide FULL Events",
  EVENTS_PAGE_EVENT_DATE: "Event Date",
  EVENTS_PAGE_ANY_EVENT_DATE: "Any Event Date",
  EVENTS_PAGE_CAPACITY: "Capacity",
  EVENTS_PAGE_UNVERIFIED_BANNER: "Which event should you choose?",
  EVENTS_PAGE_UNVERIFIED_BANNER_DESCRIPTION:
    "{name}, any of the events you see below are great for new members. They are shown in your time zone. Choose one that suits your schedule and we will meet you there!",
  EVENT_PAGE_IDEAS_WE_WILL_EXPLORE: "Ideas we will explore",
  EVENT_PAGE_BACK_TO_EVENTS: "Back to Events",
  EVENT_PAGE_BACK_TO_EVENT_PAGE: "Back to Event Page",
  EVENT_PAGE_CANCEL_ATTENDANCE: "Cancel attendance",
  EVENT_PAGE_DESCRIPTION: "Description",
  EVENT_PAGE_LOCATION: "Event Location",
  EVENT_PAGE_DISCUSSION: "Event Discussion",
  EVENT_PAGE_GO_TO_TOPIC: "Go to Topic",
  EVENT_PAGE_A_MESSAGE_FROM_YOUR_COACHES: "A message from your coaches",
  EVENT_PAGE_INVITE_OTHER_MEMBERS: "Invite other members to the event",
  EVENT_PAGE_I_CANT_PARTICIPATE: "I can’t participate",
  EVENT_PAGE_I_WILL_ATTEND: "I will attend",
  EVENT_PAGE_I_WILL_CO_HOST: "I will Co-Host",
  EVENT_PAGE_I_WILL_GO: "Actually, I will go",
  EVENT_PAGE_START_EVENT: "Start Event",
  EVENT_PAGE_YOU_ARE_GOING: "You’re going!",
  EVENT_PAGE_STARTS_IN: "Starts in {time}",
  EVENT_PAGE_NEED_TO_CANCEL: "Need to cancel?",
  EVENT_PAGE_FULL_EVENT: "The event is now full",
  EVENT_PAGE_CONFIRM_WHO_ATTENDED: "Confirm who attended",
  EVENT_PAGE_UPDATE_IN_PERSON_ATTENDANCE: "Update attendance",
  EVENT_PAGE_CONFIRM_IN_PERSON_EVENT_HAPPENED: "Did you successfully host this in-person event?",
  EVENT_PAGE_CONFIRM_IN_PERSON_EVENT_HAPPENED_YES: "Yes, I did",
  EVENT_PAGE_CONFIRM_IN_PERSON_EVENT_HAPPENED_NO: "No, I didn't",
  EVENT_PAGE_CONFIRM_IN_PERSON_PARTICIPANTS: "Please confirm which ladies attended",
  EVENT_PAGE_CONFIRM_IN_PERSON_NO_ONE_ATTENDED: "It looks like no-one attended this event!",
  EVENT_PAGE_CONFIRM_IN_PERSON_NO_ONE_ATTENDED_CONFIRM:
    "We are assuming that no one attended your event, is that right?",
  EVENT_PAGE_CONFIRM_IN_PERSON_CANCELLED: "Yes, it was cancelled",
  EVENT_PAGE_CONFIRM_IN_PERSON_SUCCESS_MESSAGE: "The event attendance has been confirmed.",
  EVENT_PAGE_UPGRADE_HEADING: "We’d love to see you again!",
  EVENT_PAGE_UPGRADE_DESC:
    "Upgrade your plan and get instant access to our events, video calls and learning resources.",
  EVENT_PAGE_UPGRADE_SEE_PLANS: "See plans",
  EVENT_PAGE_UPGRADE_TO_ATTEND: "Upgrade to attend",
  EVENT_PAGE_ACTIVATE_TO_ATTEND: "Activate to attend",
  EVENT_PAGE_ACTIVATE_HEADING: "Want to join this {eventKind}?",
  EVENT_PAGE_ACTIVATE_DESC:
    "During your free trial, you are unable to attend {eventKindToAttend}. <br></br> To join this event, you will need a <bold>paid subscription</bold>. You can activate your paid subscription now by clicking the button below. Your account will be charged and you will have instant access to {eventKindAccess}.",
  EVENT_PAGE_ACTIVATE_DESC_EVENTS_HOSTED_BY_MEMBERS: "events hosted by other {HL} members",
  EVENT_PAGE_ACTIVATE_DESC_CONVERSATION_STARTER:
    "Conversation Starter events hosted by our Coaches",
  EVENT_PAGE_ACTIVATE_DESC_MEMBER_EVENTS: "member events",
  EVENT_PAGE_ACTIVATE_DESC_THIS_EVENT_TYPE: "this event type",
  EVENT_PAGE_ACTIVATE_NOW: "Activate now",
  EVENT_PAGE_PRIVATE_EVENT: "This is a private event",
  EVENT_PAGE_CLOSED_DOOR:
    "This event started {minsAgo} minutes ago and the host has closed the door. You can knock to request access but must wait to see if the host allows you to enter",
  EVENT_PAGE_CLOSED_DOOR_SHORT:
    "This event started {minsAgo} minutes ago and the host has closed the door.",
  EVENT_PAGE_ARE_YOU_SURE: "Are you sure?",
  EVENT_PAGE_ADD_THIS_TO_YOUR_CALENDAR: "Add this to your calendar?",
  EVENT_PAGE_REMIND_YOURSELF: "Remind yourself to attend by adding this event to your calendar.",
  EVENT_PAGE_GOOGLE: "Google",
  EVENT_PAGE_OUTLOOK: "Outlook",
  EVENT_PAGE_I_CAL: "iCal",
  EVENT_PAGE_NO_THANKS: "No thanks",
  EVENT_PAGE_EVENT_ADDED_SUCCESSFULLY: "Event added successfully",
  EVENT_PAGE_IS_IN_YOUR_CALENDAR: "It’s in your calendar!",
  EVENT_PAGE_WELL_DONE: "Well done!",
  EVENT_PAGE_PUBLISHED_SUCCESSFULLY:
    "Your event has been successfully published! You can edit your event by going" +
    ' to the "Events I\'m hosting" tab on your profile page.',
  EVENT_PAGE_GOOD_LUCK: "Good luck!",
  EVENT_PAGE_PUBLISHED_SUCCESSFULLY_IN_PERSON: "Your event has been published!",
  EVENT_PAGE_PUBLISHED_IN_PERSON_INFO:
    "<bold>But there is one more thing...</bold>" +
    "{br}Because this event is happening offline, we need your help." +
    "{br}<span>After your in-person event has ended,</span> please come back to confirm that the event happened, and who attended." +
    "{br}This will help us to update everyone's profiles!",
  EVENT_PAGE_PUBLISHED_IN_PERSON_TIP:
    "Tip: You will find the event on your Profile Page > Events I've Hosted",
  EVENT_PAGE_PUBLISHED_IN_PERSON_SURE: "Sure! I can do that!",
  EVENT_PAGE_SOMETHING_WENT_WRONG: "Oops! Something went wrong.",
  EVENT_PAGE_TRY_AGAIN:
    "We were not able to publish your event. Please close this window and try to ‘Confirm &" +
    " Publish’ your event again.",
  EVENT_PAGE_WE_HAVE_SENT_THIS_EVENT:
    "We have sent this event to your calendar. Make sure you update your calendar setting to receive reminders so you don’t miss it!",
  EVENT_PAGE_ADVANCED_SPEAKERS:
    "This event was created for Advanced speakers. You can attend, but be aware that the discussion may be difficult to follow and participate in.",
  EVENT_PAGE_I_WOULD_LIKE_TO_ATTEND: "I would like to attend",
  EVENT_PAGE_NOT_SURE: "I’m not sure",
  EVENT_PAGE_I_LL_BE_THERE: "I will be there!",
  EVENT_PAGE_NOBODY_LIKES: "Nobody likes being <italic>stood up</italic>.",
  EVENT_PAGE_HOST_WILL_BE_EXPECTING:
    "If you say you’re going, {host} will be expecting you to be there!",
  EVENT_PAGE_IF_YOU_CHANGE_YOUR_MIND:
    "If you change your mind or you can’t make it, that’s OK, but please let her know & ‘cancel’ so your seat can be offered to someone else.",
  EVENT_PAGE_WHO_IS_COMING: "Who's coming?",
  EVENT_PAGE_VERIFIED: "Verified?",
  EVENT_PAGE_ARE_YOU_SURE_YOU_WANT_TO_VERIFY: "Are you sure you want to verify this person?",
  EVENT_PAGE_ARE_YOU_SURE_YOU_WANT_TO_UNVERIFY: "Are you sure you want to un-verify this person?",
  EVENT_PAGE_VERIFY: "Verify",
  EVENT_PAGE_UNVERIFY: "Un-verify",
  EVENT_PAGE_WHO_IS_COMING_UPDATED_USER: "User was updated successfully",
  EVENT_PAGE_CANT_MAKE_IT: "Can't make it any more?",
  EVENT_PAGE_THAT_IS_OK:
    "That's OK! Sometimes life gets busy. We'll see you at a different event soon!",
  EVENT_PAGE_EVENT_TIME: "Your event is scheduled to start at {time}",
  EVENT_PAGE_YOU_CAN_START_THE_EVENT:
    "You can start the event now and get ready before your guests arrive.",
  EVENT_PAGE_YOUR_GUESTS: "Your guests will be able to join at the scheduled start time.",
  EVENT_PAGE_PLEASE_CANCEL: "Please cancel",
  EVENT_PAGE_FINISHED_EVENT: "Finished Event",
  EVENT_PAGE_UNLIMITED_PLACES_REMAINING: "Unlimited",
  EVENT_PAGE_JOIN_CALL: "Join call",
  EVENT_PAGE_PLACES_REMAINING: "{places} places remaining",
  EVENT_PAGE_SHOW_ALL_PARTICIPANTS: "Show all",
  EVENT_PAGE_REPORT_ABUSE: "Report this Event",
  EVENT_PAGE_SEND_INVITATION: "Send invitation",
  EVENT_PAGE_SHARE_EVENT_TO_PEOPLE: "Share event to people",
  EVENT_PAGE_SUGGESTED_EVENTS: "Suggested Events",
  EVENT_PAGE_ACCESS_DENIED:
    "Unfortunately the host of this event is not allowing more people to join at this time",
  EVENT_PAGE_DEEP_DIVE_ROUNDTABLE:
    "This Deep Dive is a ‘Roundtable’ discussion.  Roundtable is a discussion where each lady shares her opinion about a specific topic. It’s a great way to practise agreeing, disagreeing, and listening. To prepare for this event, complete the Deep Dive lesson, and do the Workbook. It will help you to organise your thoughts.  Ask your host if you have any questions about the topic! We know you’ll love it!",
  EVENT_PAGE_DEEP_DIVE_SHARE_AND_COMPARE:
    "This Deep Dive is a ‘Share and Compare’ discussion. In this event, each lady will practise giving a short talk in front of your group by sharing something special. Depending on the topic, you might share a song, a photo, a story, or even some advice! To prepare for your discussion, go through the Deep Dive lesson. Watch the video of us coaches having our own discussion, and complete the Workbook to prepare for the event. Be sure to ask your host if you have any questions about this topic! We know you’re going to love it!",
  EVENT_PAGE_DEEP_DIVE_ROLE_PLAY:
    "This Deep Dive is a ‘Role Play’. For this event each lady gets to play a special role by pretending to be someone else, or by performing a specific task. It’s a great way to think outside the box, and expand your vocabulary! To prepare for this event, complete the Deep Dive lesson and then read about the different roles that you could be. Be sure to message your host and ask her what she wants you to do before the event. This kind of event is so much fun, we know you’re going to love it!",
  EVENT_PAGE_DEEP_DIVE_SOLVE_A_PROBLEM:
    "This Deep Dive is a ‘Solve a Problem’ discussion. In this event, your host will choose a topic for you to discuss. Then you will work together to identify both problems and solutions related to the topic. This is a great way to practise asking questions, sharing ideas, and listening to each other. To prepare for the discussion, go through the Deep Dive lesson on the platform. Complete the Workbook to start brainstorming, and be sure to message your host if you have any questions about this topic!",
  EVENT_PAGE_SPECIFIC_MEMBER_GROUP: "Sorry, this event is scheduled for a specific member group.",
  EVENT_PAGE_TAG_PARTICIPANTS: "(Tag Event Participants)",
  EVENT_PAGE_SHARE_THIS_EVENT: "Who would you like to share this event with?",
  EVENT_PAGE_SHARED: "Event shared successfully",
  LIVE_TABLES_BANNER_INTRODUCES: "Hey Lady! introduces ",
  LIVE_TABLES_BANNER_DESCRIPTION:
    "Join a *live* conversation with ladies who are online right now! No preparation required but lots of speaking  practice!",
  LIVE_TABLES: "Live Tables",
  LIVE_COFFEE_TABLES: "Live Coffee Tables",
  LIVE_TABLES_SEE_MORE: "See more tables",
  LIVE_TABLES_FULL: "Table is Full",
  LIVE_TABLES_GRAB_A_SEAT: "Grab a Seat",
  LIVE_TABLES_JOINED: "Joined",
  LIVE_TABLES_START_TABLE: "Start Table",
  LIVE_TABLES_START_TABLE_DESCRIPTION:
    "Start a table to speak with other ladies who are online right now",
  LIVE_TABLES_TOPIC_LABEL: "Table Topic:",
  LIVE_TABLES_TOPIC_PLACEHOLDER: "What topic will you talk about at your table?",
  LIVE_TABLES_MAX_PARTICIPANTS_LABEL: "Max Seats (including you)",
  LIVE_TABLES_UPDATE_TITLE: "Change Table Settings",
  LIVE_TABLES_UPDATE_DESCRIPTION:
    "Change table settings to update this information on ‘Live Tables’ page",
  LIVE_TABLES_STARTED_MODAL_TITLE: "Your Coffee Table has started",
  LIVE_TABLES_STARTED_MODAL_DESCRIPTION:
    "Please, show some patience and give ladies a little time to join. We will end this call automatically in 15 minutes if nobody joins your table.",
  LIVE_TABLES_END_MODAL_TITLE: "Your Coffee Table is about to end",
  LIVE_TABLES_END_MODAL_DESCRIPTION:
    "Looks like there are no ladies who are ready to join your table right now. But no worries, we are waiting for you next time!",
  LIVE_TABLES_END_MODAL_LEAVE_TABLE: "Leave Table",
  LIVE_TABLES_GRAB_SEAT_MODAL_TITLE: "Uh oh, that's not going to work!",
  LIVE_TABLES_GRAB_SEAT_MODAL_DESCRIPTION:
    "You are only able to grab one seat. You will need to leave your other seat before taking this one.",
  LIVE_TABLES_LEFT_ALONE_TITLE: "Looks like you're the only one left",
  LIVE_TABLES_LEFT_ALONE_DESCRIPTION:
    "We will end this call automatically in 15 minutes if nobody joins your table. Remember you can update the table topic via the settings.",
  LIVE_TABLES_READY_TO_TALK: "Ready to talk right now?",
  LIVE_TABLES_START_COFFEE_TABLE_DESCRIPTION:
    "Start a <span>*LIVE* Coffee Table</span> to video chat with other ladies who are online now.",
  LIVE_TABLES_START_COFFEE_TABLE: "Start Coffee Table",
  LIVE_TABLES_FEEDBACK_RATE_EXPERIENCE_TITLE: "Please rate your experience of Coffee Tables",
  LIVE_TABLES_FEEDBACK_RATE_EXPERIENCE_DESCRIPTION:
    "This is a new feature and there are many ways we can improve it. Please rate what you think about this feature as it is today.",
  LIVE_TABLES_FEEDBACK_COMMENT_EXPERIENCE_TITLE: "Why did you give that score?",
  LIVE_TABLES_FEEDBACK_COMMENT_EXPERIENCE_DESCRIPTION:
    "Your honest feedback is appreciated. You can say anything you like including suggestions for how we can make this feature better.",
  LIVE_TABLES_FEEDBACK_COMMENT_EXPERIENCE_PLACEHOLDER: "Type your feedback..",
  LIVE_TABLES_FEEDBACK_WILL_USE_AGAIN_TITLE: "How likely are you to use it again?",
  LIVE_TABLES_FEEDBACK_SUCCESS: "Success!  Your feedback has been sent!",
  LIVE_TABLES_WHAT_IS_LIVE_TABLE: "What is a live table?",
  LIVE_TABLES_HELP_TOOLTIP_TITLE: "Relax and practise over coffee!",
  LIVE_TABLES_HELP_TOOLTIP_DESCRIPTION:
    "<p>A “Live Coffee Table” is a new way to start or join a video call and practise speaking!</p><p>Join a table with a free seat, or click “Start Table” to start a new one with your own topic.</p><p>Anyone online can see your table and ‘grab a seat’ to join you in the call. Max 6 seats available per table. <url>Learn more</url></p>",
  LIVE_TABLES_STARTED_MESSAGE_TITLE: "{name} just started a live coffee table",
  LIVE_TABLES_STARTED_MESSAGE_MUTE: "Mute for 60 mins",
  LIVE_TABLES_STARTED_MESSAGE_GO_TO_TABLES: "Go to Tables",
  FOOTER_MAKING_AN_IMPACT: "You are making an impact!",
  FOOTER_SPARKS_CREATED: "Sparks created since we launched!",
  FOOTER_OUR_MISSION:
    "Our mission is to spark one billion meaningful conversations between women of different" +
    " cultures.",
  FOOTER_INVITE_A_FRIEND: "Invite a friend to join and earn credit for your membership!",
  HELP_CONTACT_PAGE_FEEL_FREE:
    "Feel free to contact us! Our support team will reply to you as soon as possible.",
  HELP_CONTACT_PAGE_HOW_CAN_WE_HELP: "How can we help you?",
  HELP_CONTACT_PAGE_SEND: "Send message",
  HELP_CONTACT_MESSAGE_SENT: "Message successfully sent",
  HELP_CONTACT_PAGE_TYPE_YOUR_MESSAGE: "Type your message here...",
  HELP_FAQ_PAGE_CHOOSE_CATEGORY: "Common questions from our members",
  HELP_FAQ_PAGE_EVENTS: "Events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS: "Subscriptions",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_AFTER_3_MONTHS: "after 3 months",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_AFTER_6_MONTHS: "after 6 months",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_AFTER_12_MONTHS: "after 12 months",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_STANDARD_THEME: "Standard Themes",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_SKILLS_WORKSHOPS: "English skills workshops",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_SKILLS_WORKSHOPS_DESCRIPTION:
    "Language workshops hosted by our teachers to help you sharpen your conversation skills. Specific, practical training designed to take your English to the next level.",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_TOPIC_LIBRARY: "Topic Library",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CONVERSATION_STARTERS: "Conversation Starters",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CONVERSATION_STARTERS_DESCRIPTION:
    "Develop your conversation skills in group events guided by our teachers. Get the support, advice and feedback you need to develop your English speaking skills.",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_GRAMMAR_SPOTS: "Grammar Spots",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_OFFICE_HOURS: "Office Hours",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CREATE_HOSTS_EVENTS: "Create & Host Events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CREATE_HOSTS_EVENTS_DESCRIPTION:
    "Schedule online events and invite other ladies from the community to join. This is a fantastic way to meet other ladies talk about the things that interest you at a time that suits you best!",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_COMMUNITY_MEET_AND_GREETS: "Community Meet & Greets",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_COMMUNITY_MEET_AND_GREETS_DESCRIPTION:
    "Live events hosted by our Community Success team, these informal, fun events are here to help you meet and get to know other ladies in the community and find new speaking partners!",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_GRAMMAR_SPOTS_AND_OFFICE_HOURS: "Grammar Spots & Office Hours",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_GRAMMAR_SPOTS_AND_OFFICE_HOURS_DESCRIPTION:
    "Live events hosted by our teachers where you can continue learning vocabulary and grammar relating to our conversation topics. Bring your questions and have them answered.",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_WORKSHOPS: "Functional Language Workshops",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_GENERAL_EVENTS: "Community General Events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_SPEED_MEETING: "Speed Meeting/Dating",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_OVER_COFFEE: "Conversations Over Coffee",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_OVER_COFFEE_DESCRIPTION:
    "Live events hosted by our team where you can grab a coffee and come along to listen in to their conversation with members from the community. A great way to learn more about other members as they discuss interesting topics!",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_MEMBER_INTERVIEWS: "Member Interviews",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CONVERSATIONS: "Casual Conversations",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_STUDY_GROUPS: "Study Groups",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_DEEP_DIVES: "Deep Dives",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CUSTOM_TOPIC_EVENTS: "Custom Topic Events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_ONE_TO_ONE: "1:1 Conversations",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CONVERSATIONS_GROUP: "Casual Conversations (Group)",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_PROFILES: "Explore member profiles",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_MESSAGES: "Send direct messages",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CHAT: "Create Group Chat",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CONVERSATION_PACKS: "Conversation Packs",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_CONVERSATION_PACKS_DESCRIPTION:
    "Structured video lessons, workbooks and activities to help you study, prepare and participate confidently in conversations inside community.",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_TEACHER_HOSTED_EVENTS: "Teacher Hosted Events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_COMMUNITY_TEAM_EVENTS: "Community Team Events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_MEMBER_EVENTS: "Join Member Events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_HOST_YOUR_EVENTS: "Host your own events",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_FEATURE: "Feature",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_PROFILES_CHAT: "Profiles & Chat",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_FOUNDATIONS: "Foundations",
  HELP_FAQ_PAGE_SUBSCRIPTIONS_RISE: "Rise",
  HELP_FAQ_PAGE_PLATFORM_LAUNCH: "Platform launch",
  HELP_FAQ_PAGE_TOPICS_AND_LESSONS: "Topics & Lessons",
  HELP_FAQ_PAGE_PEOPLE: "Meeting people",
  HELP_FAQ_PAGE_MY_ACCOUNT: "My account",
  HELP_FAQ_PAGE_OUR_COMMUNITY: "Our community",
  HELP_FAQ_PAGE_PLEASE_CHOOSE_CATEGORY:
    "Please choose the category that best relates to your questions or concerns",
  HELP_FAQ_PAGE_STILL_HAVE_QUESTION: "Still have a question? <url>Contact us</url>",
  HOME_PAGE_HINT_HOME_TITLE: "Let's explore your home page!",
  HOME_PAGE_HINT_HOME_DESCRIPTION:
    "See the flashing icon? This quick tour will follow the icon to give you some tips on our main features.",
  HOME_PAGE_HINT_EVENTS_TITLE: "Events happening every day!",
  HOME_PAGE_HINT_EVENTS_DESCRIPTION:
    "All events are shown in your time zone. This link will take you to our Events page. You are welcome to join as many events as you like.",
  HOME_PAGE_HINT_USERS_TITLE: "People to practise with",
  HOME_PAGE_HINT_USERS_DESCRIPTION:
    "Follow this link to see and search for people in the community then connect via chat and start talking!",
  HOME_PAGE_HINT_TOPICS_TITLE: "Explore our topics",
  HOME_PAGE_HINT_TOPICS_DESCRIPTION:
    "This is where you will find all the tools you need to have great conversations!{br}We also offer Resources to study grammar and learn more about what's happening in the community.",
  HOME_PAGE_HINT_CHAT_TITLE: "Let's chat!",
  HOME_PAGE_HINT_CHAT_DESCRIPTION:
    "The perfect place to keep the conversation going. You can text 1:1, create a group and start an instant video call at the click of a button!",
  HOME_PAGE_HINT_MEMBERZINE_TITLE: "Read our Memberzine!",
  HOME_PAGE_HINT_MEMBERZINE_DESCRIPTION:
    "Have a read through the inspiring magazine articles written by our members and team! When you’re ready, you can contribute an article yourself!",
  HOME_PAGE_HINT_LEARN_BASICS_TITLE: "Watch to learn the basics",
  HOME_PAGE_HINT_LEARN_BASICS_DESCRIPTION:
    "These snack-sized videos created by our team will help you step-by-step to use the platform. Mark each one “Complete” to move through the series.",
  HOME_PAGE_HINT_PROFILE_TITLE: "My Account",
  HOME_PAGE_HINT_PROFILE_DESCRIPTION:
    "Click your profile pic to access and update your profile, change your availability settings and manage your membership subscription.",
  HOME_PAGE_CHOOSE_FIRST_EVENT: "Choose your first event",
  HOME_PAGE_TOUR_EXPLORE: "Start Exploring",
  HOME_PAGE_LEARN_BASICS: "Learn The Hey Lady! Way",
  HOME_PAGE_TAKE_THE_QUIZ: "Take the quiz!",
  HOME_PAGE_UNLOCK_QUIZ: "Watch all 3 videos to unlock the quiz",
  HOME_PAGE_COMPLETE_YOUR_PROFILE: "Complete your profile",
  HOME_PAGE_RECORD_A_VIDEO: "Record a video for verification",
  HOME_PAGE_UNLOCK_ALL_FEATURES: "Unlock all features",
  EVENTS_PAGE_HINT_ALL_EVENTS_TITLE: "Your Events",
  EVENTS_PAGE_HINT_ALL_EVENTS_DESCRIPTION:
    "Click any link in this menu to see the events you are going to, have been to or are going to be hosting.",
  EVENTS_PAGE_HINT_FILTERS_TITLE: "Try the filters!",
  EVENTS_PAGE_HINT_FILTERS_DESCRIPTION:
    "We start by showing you the events that match your “Availability” settings from your profile. Try changing the filters to find the right events for you.",
  EVENTS_PAGE_HINT_EVENTS_IN_YOUR_TIMEZONE: "Events in your time zone",
  EVENTS_PAGE_HINT_EVENTS_IN_YOUR_TIMEZONE_DESCRIPTION:
    "All times are displayed in your time zone so you never need to worry about missing an event!",
  EVENTS_PAGE_HINT_JOIN_AS_MANY_AS_YOU_LIKE: "Join as many as you like!",
  EVENTS_PAGE_HINT_JOIN_AS_MANY_AS_YOU_LIKE_DESCRIPTION:
    "Join conversations hosted by community members and the Hey Lady! team. To join, simply click “View Event” then “I will attend” on the next page.",
  EVENTS_PAGE_HINT_JOIN_AS_MANY_AS_YOU_LIKE_DESCRIPTION_MOBILE:
    "Join conversations hosted by community members and the Hey Lady! team. To join, simply click event title or image then “I will attend” on the next page.",
  EVENT_PAGE_HINT_SHARE_EVENT_TITLE: "Share the love!",
  EVENT_PAGE_HINT_SHARE_EVENT_DESCRIPTION:
    "Attending an event is easy, just click “I will attend”. If you see an event that other members might enjoy, you can use “Share Event” button to let them know!",
  MEETING_PAGE_HINT_MEETING_TITLE: "✨ Feel a spark?",
  MEETING_PAGE_HINT_MEETING_DESCRIPTION:
    "Click this button to show your appreciation & gratitude toward others!" +
    " Every spark you give is counted and contributes to our mission! ",
  MEETING_PAGE_HINT_LEARN_MORE: "Learn more",
  EVENT_PAGE_CONNECT_WITH_PEOPLE: "Connect with people who attended!",
  EVENT_PAGE_CONNECT_SEND_QUICK_MESSAGE: "Send them a quick message or type your own",
  EVENT_PAGE_CONNECT_LOVELY_MEETING_YOU: "So lovely meeting you!",
  EVENT_PAGE_CONNECT_CATCH_UP: "Shall we catch up?",
  EVENT_PAGE_CONNECT_LETS_KEEP_TALKING: "Let's keep talking!",
  EVENT_PAGE_CONNECT_SAY_HI: "👋 Say Hi!",
  EVENT_PAGE_CONNECT_GO_TO_CHAT: "Go to Chat",
  EVENT_PAGE_CONNECT_CLICK_TO_SEND_MESSAGE: "Click to send quick message",
  EVENT_PAGE_CONNECT_TYPE_YOUR_OWN: "Or type your own here...",
  PEOPLE_PAGE_SELECTED: "{number} out of {total} selected",
  PEOPLE_PAGE_CLEAR_SELECTION: "Clear selection",
  PEOPLE_PAGE_INVITE_TO_CHAT: "Invite to Group Chat",
  PEOPLE_PAGE_NO_EVENTS: "Create an event first then share it with people here",
  PEOPLE_PAGE_SEARCH_EVENT_BY_TITLE: "Search event by title",
  PEOPLE_PAGE_WHAT_EVENT_YOU_WANT_TO_SHARE: "What event would you like to share?",
  PEOPLE_PAGE_FILTERS_LOCATION: "Location",
  PEOPLE_PAGE_FILTERS_CHOOSE_TYPE: "Choose Type",
  PEOPLE_PAGE_FILTERS_CHOOSE_LOCATION: "Choose Location",
  PEOPLE_PAGE_FILTERS_CHOOSE_PERSONAL_INTERESTS: "Choose Personal Interests",
  PEOPLE_PAGE_FILTERS_CHOOSE_ENGLISH_INTERESTS: "Choose English Interests",
  PEOPLE_PAGE_FILTERS_CHOOSE_LEVEL: "Choose Level",
  PEOPLE_PAGE_FILTERS_CHOOSE_OCCUPATION: "Choose Occupation",
  PEOPLE_PAGE_FILTERS_MEMBER_TYPE_SELECTED: "{number} Member Type selected",
  PEOPLE_PAGE_FILTERS_LOCATION_SELECTED: "{number} Location selected",
  PEOPLE_PAGE_FILTERS_PERSONAL_INTEREST_SELECTED: "{number} Personal Interest selected",
  PEOPLE_PAGE_FILTERS_ENGLISH_INTEREST_SELECTED: "{number} English Interest selected",
  PEOPLE_PAGE_FILTERS_ENGLISH_LEVEL_SELECTED: "{number} English Level selected",
  PEOPLE_PAGE_FILTERS_MEMBER_TYPES_SELECTED: "{number} Member Types selected",
  PEOPLE_PAGE_FILTERS_OCCUPATION_SELECTED: "{number} Occupation selected",
  PEOPLE_PAGE_FILTERS_LOCATIONS_SELECTED: "{number} Locations selected",
  PEOPLE_PAGE_FILTERS_PERSONAL_INTERESTS_SELECTED: "{number} Personal Interests selected",
  PEOPLE_PAGE_FILTERS_ENGLISH_INTERESTS_SELECTED: "{number} English Interests selected",
  PEOPLE_PAGE_FILTERS_ENGLISH_LEVELS_SELECTED: "{number} English Levels selected",
  PEOPLE_PAGE_FILTERS_OCCUPATIONS_SELECTED: "{number} Occupations selected",
  PEOPLE_PAGE_MEMBERS_WHO_MATCH: "Members who match your profile",
  PEOPLE_PAGE_ONLINE_MEMBERS_WHO_MATCH: "Online members who match your profile",
  PEOPLE_PAGE_MEMBERS_SEE_HOW_YOU_MATCH:
    "Click the 'rotate' icon to see how you match. Use the filters to generate" +
    " your own results.",
  PEOPLE_PAGE_MEMBERS_CLICK_ROTATE: "Click the 'rotate' icon to see how you match.",
  PEOPLE_PAGE_HLT_MEMBERS: "Here are the members of our amazing team!",
  PEOPLE_PAGE_HLT_MEMBERS_DESCRIPTION:
    'We\'re all here to support you, feel free to reach out and say "Hey!"',
  PEOPLE_PAGE_MEMBERS_FILTERED_RESULTS: "Filtered results ({total} members)",
  PEOPLE_PAGE_MEMBERS_SORTED_BY:
    "Sorted by relevance. Click the 'rotate' icon to see your match score.",
  PEOPLE_PAGE_YOUR_MATCH_SCORE:
    "Your match score shows much you have in common with this member based on the" +
    " information in your profile.",
  PEOPLE_PAGE_MAKE_SURE:
    "Make sure <url>your profile</url> is complete and up to date to find the perfect match!",
  PEOPLE_PAGE_HINT_ALL_MEMBERS: "Meet the members of our community!",
  PEOPLE_PAGE_HINT_ALL_PEOPLE_DESCRIPTION:
    "Use these links to switch between search, members who are online now and a helpful list of Hey Lady! team members.",
  PEOPLE_PAGE_HINT_AVAILABILITY_TITLE: "Availability & Filters",
  PEOPLE_PAGE_HINT_AVAILABILITY_DESCRIPTION:
    "We show you the members we think are a good match and are available when you are. You can use any of these filters to customise your search at any time.",
  PEOPLE_PAGE_HINT_FILTERS_DESCRIPTION_MOBILE:
    "We show you the members we think are a good match and are available when you are. You can refine your search at any time by clicking the “Customize Filters” button and adjusting the filters to your preferences.",
  PEOPLE_PAGE_HINT_MATCH_SCORE: "Find out your match score!",
  PEOPLE_PAGE_HINT_MATCH_SCORE_DESCRIPTION:
    "Click this icon to flip the card and see how you match this member based on interests, availability and English level!",
  PEOPLE_SHARED_AVAILABILITY: "Shared Availability",
  PEOPLE_DIFFERENT_AVAILABILITY: "Different Availability",
  PEOPLE_ANY_AVAILABILITY: "Any Availability",
  PEOPLE_ALL_MEMBERS: "All Members",
  PEOPLE_PEOPLE_I_FOLLOW: "People I Follow",
  PEOPLE_I_MATCH: "People I Match",
  PEOPLE_ANY_LOCATION: "Any Location",
  PEOPLE_NEAR_ME: "Near Me",
  PEOPLE_LITTLE_SISTERS: "Little Sisters",
  PEOPLE_BIG_SISTERS: "Big Sisters",
  PEOPLE_SEARCH_BY_CITY: "Search by City or Country",
  PEOPLE_SEARCH_BY_OCCUPATION: "Search by occupation",
  PEOPLE_SEARCH_FOR_INTERESTS: "Search for Interests",
  PEOPLE_SEARCH_B1: "Intermediate (B1)",
  PEOPLE_SEARCH_B2: "Upper Intermediate (B2)",
  PEOPLE_SEARCH_C1: "Advanced (C1)",
  PEOPLE_SEARCH_C2: "Proficient (C2)",
  USER_PAGE_HINT_SPEAKING_PARTNER_TITLE: "Speaking Partners",
  USER_PAGE_HINT_SPEAKING_PARTNER_DESCRIPTION:
    "If you are open to meeting new speaking partners, make sure you let us know by selecting the right option on your profile. We will use this information when making recommendations for ladies we think you should meet!",
  USER_PAGE_HINT_FOLLOW_TITLE: "Follow",
  USER_PAGE_HINT_FOLLOW_DESCRIPTION:
    "You'll be able to follow your favourite members or reach out to them directly through our chat feature. ",
  USER_PAGE_HINT_EVENTS_HOSTED_TITLE: "Track your progress",
  USER_PAGE_HINT_EVENTS_HOSTED_DESCRIPTION:
    "We'll help you keep track of how many conversations you've had and hosted so you can set goals and keep improving.",
  TOPICS_PAGE_HINT_CURRENT_THEME_TITLE: "Current Topic",
  TOPICS_PAGE_HINT_CURRENT_THEME_DESCRIPTION:
    "The current topic is the one chosen by our Coaches for the whole community to study and practise talking about. A new conversation topic is released every two weeks.",
  TOPICS_PAGE_HINT_TOPIC_LIBRARY_TITLE: "Topic Library",
  TOPICS_PAGE_HINT_TOPIC_LIBRARY_DESCRIPTION:
    "Here you will find the full range of topics that we offer. This is a fantastic way to expand your vocabulary range and talk about the subjects you're interested in.",
  TOPICS_PAGE_HINT_GRAMMAR_LIBRARY_TITLE: "Grammar Library",
  TOPICS_PAGE_HINT_GRAMMAR_LIBRARY_DESCRIPTION:
    "Just as it sounds, this is where you will find the useful lessons our Coaches have created about the grammar we often use in our conversations.",
  TOPICS_PAGE_HINT_RESOURCES_TITLE: "Resources",
  TOPICS_PAGE_HINT_RESOURCES_DESCRIPTION:
    "This is the place to find super helpful resources about how to set goals, track your progress, study and host events the “Hey Lady! way”.",
  TOPICS_PAGE_UPGRADE_TO_ACCESS: "Upgrade to access",
  HOME_PAGE_JOIN_YOUR_FIRST_EVENT: "Join your first event!",
  HOME_PAGE_CHOOSE_TIME: "Choose a time",
  HOME_PAGE_COMPLETE_YOUR_PROFILE_GET_CHANCES:
    "By completing your profile it will be easier for us to help you find people to meet and practise with!",
  HOME_PAGE_EVENTS_YOU_ARE_GOING: "Events you are going to",
  HOME_PAGE_CHOOSE_AN_EVENT: "Ooops! You need to choose an event! ",
  HOME_PAGE_EVENTS_YOU_ARE_GOING_TO: "Events you are going to attend will appear here.",
  HOME_PAGE_CHOOSE_AN_EVENT_UNVERIFIED_USER_TITLE: "Choose your first event!",
  HOME_PAGE_CHOOSE_AN_EVENT_UNVERIFIED_USER_DESCRIPTION:
    "To unlock all platform features you will need to attend your first event. You'll be warmly greeted by our team and be introduced to other members.",
  HOME_PAGE_VIEW_DETAILS: "View Details",
  HOME_PAGE_LETS_GET_STARTED: "Hey {firstName}! Let’s get started!",
  HOME_PAGE_FUN_THINGS_TO_DO:
    "We have <bold>5</bold> fun things to do that will set you up for success!",
  HOME_PAGE_SCROLL_DOWN:
    "<bold>Scroll down</bold>  to complete each and unlock access to all areas of our platform.",
  HOME_PAGE_TO_UNLOCK:
    "To unlock all platform features you will need to attend an event where you will be kindly welcomed by our team.",
  HOME_PAGE_QUIZ: "*QUIZ* How well do you know the Hey Lady! Way?",
  HOME_PAGE_QUIZ_DESCRIPTION: "10 easy questions based on the videos you watched!",
  HOME_PAGE_START_QUIZ: "Start Quiz",
  HOME_PAGE_SET_UP: "Set up your home in Hey Lady!",
  HOME_PAGE_SET_UP_DESCRIPTION:
    "Your profile is your personal space where you can share a little about you so we can help to match you with other members!",
  HOME_PAGE_COMPLETE_PROFILE: "Complete profile",
  HOME_PAGE_GET_VERIFIED: "Get verified by the Hey Lady! Team",
  HOME_PAGE_GET_VERIFIED_DESCRIPTION:
    "Community safety is important. All new members need to record a short video to introduce themselves to our team.",
  HOME_PAGE_YOUR_VIDEO: "Your video will be reviewed soon!",
  HOME_PAGE_WAITING_FOR_VERIFICATION:
    "We have received your video. We review and verify all new members within 24" +
    " hours. This step will be marked as complete once you are verified.",
  HOME_PAGE_THANK_YOU_FOR_PATIENCE: "Thank you for your patience.",
  HOME_PAGE_GET_VERIFIED_BUTTON: "Get verified",
  HOME_PAGE_FINISH: "Finish the checklist to unlock all features",
  HOME_PAGE_FINISH_DESCRIPTION:
    "When you have completed the new Member checklist you will be able to create and host events, start video calls and see our full event list!",
  HOME_PAGE_LADIES_YOU_SHOULD_MEET: "Members we think you should meet!",
  HOME_PAGE_EVENTS: "Events for you",
  HOME_PAGE_WATCH_VIDEOS: "Watch videos to learn the basics!",
  HOME_PAGE_WATCH_VIDEO: "Watch video",
  HOME_PAGE_WATCH_AGAIN: "Watch again",
  HOME_PAGE_COMPLETED: "Completed",
  HOME_PAGE_FINISHED: "Have you finished watching the video?",
  HOME_PAGE_VIDEO_INFORMATION:
    "The information in this video will help you to unlock the quiz and answer all" +
    " questions successfully!",
  HOME_PAGE_WATCH_LATER: "Watch later",
  HOME_PAGE_MARK_COMPLETE: "Yes, mark as complete",
  HOME_PAGE_MORE_MEMBERS_YOU_MATCH: "More members you match",
  HOME_PAGE_MORE_EVENTS: "More Events",
  HOME_PAGE_THE_FIRST_THING_YOU_NEED:
    "The first thing you need to do is complete the steps below to verify your profile. Once complete, you'll be able to meet other members and join all our events!",
  HOME_PAGE_ADD_DETAILS: "The first thing you need to do is add a few details to your profile.",
  HOME_PAGE_THEN_COME_ALONG: "Then, come along to your first event to meet our team!",
  HOME_PAGE_YOUR_ACCOUNT_WILL_BE_VERIFIED:
    "For your safety all members are required to attend a New Member Meet-Up to say hello and verify their identity. Choose the best time for you!",
  HOME_PAGE_FIND_AN_EVENT:
    'Find an event that interests you and say "Hey" to our team. You\'ll meet other ladies and unlock access to all events and platform features!',
  HOME_PAGE_A_LITTLE_ABOUT_ME: "A little about me:",
  PEOPLE_PAGE_MATCH_SCORE: "Match score",
  PEOPLE_PAGE_MATCHING_SAME_CITY: "You live in the same city!",
  PEOPLE_PAGE_MATCHING_CONVERSATION_QUEEN: "Conversation Queen",
  PEOPLE_PAGE_MATCHING_OPEN_TO_NEW_SPEAKING_PARTNERS: "Open to new speaking partners",
  PEOPLE_PAGE_MATCHING_INTERESTS_YOU_SHARE: "Interests you share:",
  PEOPLE_PAGE_MATCHING_SKILLS_YOU_SHARE: "Both want to improve:",
  NOTIFICATIONS_NO_NOTIFICATIONS_YET: "No notifications yet!",
  NOTIFICATIONS_PAGE_ALL: "All ({number})",
  NOTIFICATIONS_PAGE_NOTIFICATIONS: "Notifications",
  NOTIFICATIONS_PAGE_PUBLISHED_EVENT: "{actor} has created a new event, {event}.",
  NOTIFICATIONS_PAGE_MENTION: "{actor} has just mentioned you in the discussion for {event}.",
  NOTIFICATIONS_PAGE_CHAT_MENTION: "{actor} has just mentioned you in a chat message!",
  NOTIFICATIONS_PAGE_CHAT_MENTION_VIEW_CHAT: "View Comment",
  NOTIFICATIONS_PAGE_INVITATION_EVENT: "{actor} has invited you to join her for {event} on {time}.",
  NOTIFICATIONS_PAGE_INVITATION_DELETED_EVENT:
    "{actor} has invited you to join already deleted event {event} on {time}.",
  NOTIFICATIONS_PAGE_INVITATION_STATUS: `You've {status, select, 
    Accepted {accepted}
    Declined {declined}
    other {not decided}
  } the invitation.`,
  NOTIFICATIONS_PAGE_ACCEPTED_INVITATION:
    "{actor} has accepted your invitation for {event} on {time}.",
  NOTIFICATIONS_PAGE_DECLINED_INVITATION:
    "{actor} has declined your invitation for {event} on {time}.",
  NOTIFICATIONS_PAGE_NO_RESPONSE_INVITATION:
    "{actor} hasn't responded yet to your invitation for {event}.",
  NOTIFICATIONS_PAGE_INVITATION_EVENT_AS_COHOST:
    "{actor} has invited you to co-host {event} on {time}.",
  NOTIFICATIONS_PAGE_INVITATION_EVENT_AS_HOST:
    "{actor} has assigned you as the host of {event} on {time}.",
  NOTIFICATIONS_PAGE_SHARED_EVENT: "{actor} has shared {event} with you. Check it out!",
  NOTIFICATIONS_PAGE_SHARED_DELETED_EVENT:
    "{actor} has shared {event} with you. Unfortunately, the event has been deleted.",
  NOTIFICATIONS_PAGE_SYSTEM_MESSAGES: "System Messages",
  NOTIFICATIONS_PAGE_UPDATE: "The scheduled time of an event you're going to has been updated.",
  NOTIFICATIONS_PAGE_UPDATED_EVENT: "Event: {event}",
  NOTIFICATIONS_PAGE_NEW_TIME: "New time: {time}",
  NOTIFICATIONS_PAGE_IMPORTANT:
    'IMPORTANT: Please "refresh" your browser after reading this message. This will ensure the link to join the event is updated for you.',
  NOTIFICATIONS_PAGE_DELETED_EVENT:
    "{actor} has deleted an event you were going to {event}, on {time}",
  NOTIFICATIONS_PAGE_REMOVED_PARTICIPANT:
    "{actor} has removed you as a participant for the event {event} on {time}. If you think this was an error, kindly contact {actor} to let her know.",
  NOTIFICATIONS_PAGE_REMOVED_COHOST:
    "{actor} has removed you as co-host for the event {event} on {time}. If this was an error, kindly contact {actor} to let her know.",
  NOTIFICATIONS_PAGE_NEW_EVENT: "{actor}, who you follow, is hosting {event} on {time}",
  NOTIFICATIONS_WE_WILL_LET_YOU_KNOW: "We'll let you know as soon as something arrives!",
  NOTIFICATIONS_CARD: "See all notifications",
  NOTIFICATIONS_EVENT_IS_STARTING: "Event starting soon!",
  NOTIFICATIONS_REMINDER:
    "A friendly reminder to let you know that {event} is starting in {time} minutes.",
  NOTIFICATION_MARK_ALL_AS_READ: "Mark all as read",
  NOTIFICATION_VIEW_EVENT: "View Changes",
  NOTIFICATION_GO_TO_COMMENT: "Go to comment",
  NOTIFICATION_VIEW_ARTICLE: "View article",
  NOTIFICATION_GOT_IT: "Got it",
  NOTIFICATION_CONTACT_HOST: "Contact Host",
  NOTIFICATION_INVITATION_SUCCESSFULLY_ACCEPTED: "Invitation successfully accepted",
  NOTIFICATION_INVITATION_SUCCESSFULLY_DECLINED: "Invitation successfully declined",
  NOTIFICATION_ACCEPT_INVITATION: "Accept",
  NOTIFICATION_ADD_EVENT: "Add to calendar",
  NOTIFICATION_CONTACT: "Contact",
  NOTIFICATIONS_PAGE_RECEIVED: "Received {time}",
  NOTIFICATIONS_COMMENT: "{actor} has commented on {event}",
  NOTIFICATIONS_ARTICLE_PUBLISHED:
    "{actor} who you follow, has just published a new article: {title}",
  NOTIFICATIONS_CONFIRM_IN_PERSON:
    "You recently hosted ‘{event}’ but we are still waiting for you to confirm who attended so that we can update everyone’s profile.{br} If you or your co-host has already done this, you can ignore this message.{br} Otherwise, please, do it now.",
  NOT_FOUND_CONTACT_US: "Contact us about the problem",
  NOT_FOUND_GO_TO_HOME: "Go to Home Page",
  NOT_FOUND_THE_LINK:
    "The link you followed may be broken or this page does not exist any more. But don't worry, we have lots of other interesting things for you instead.",
  NOT_FOUND_WHOOPS: "Whoops, we can't find this page",
  ONBOARD_SETUP_PROFILE: "Set up your profile",
  ONBOARD_SETUP_INTRO_TITLE: "Get matched with the best people & events for your goals",
  ONBOARD_SETUP_INTRO_DESCRIPTION:
    "Complete the form to earn your ‘Perfect Profile’ badge! {br} <bold>NOTE</bold>: We will never share your personal information with anyone outside the Hey Lady! platform.",
  ONBOARD_ADD_PHOTO: "Add Photo",
  ONBOARD_DROP_PHOTO: "Drop photo",
  ONBOARD_ADD_PHOTO_TITLE: "Add Your Photo!",
  ONBOARD_ADD_PHOTO_DESCRIPTION:
    "Be sure to choose a photo that is friendly and welcoming so others can easily recognised you! {br} <bold>Note</bold>: We will never share your image outside our platform without your permission.",
  ONBOARD_ADD_STORY_TITLE: "Please, write your introduction",
  ONBOARD_ADD_STORY_DESCRIPTION:
    "What would you like someone to know about you when we introduce you? {br} <bold>TIP</bold>: Try to include something unique about you, that might spark conversation! (Minimum 30 words)",
  ONBOARD_USE_ENGLISH_LEVEL:
    "We will use your English level to recommend the best events and speaking partners to practise with!",
  ONBOARD_INTRODUCE_YOU_TO_MEMBERS:
    "We want to introduce you to members who share your interests! Choose up to eight (8) interests and hobbies from the list below.",
  ONBOARD_AVAILABILITY: "When are you available to practise and improve your English?",
  ONBOARD_USE_YOUR_PHOTO:
    "We will use your photo when introducing you. Be sure to choose one that is friendly and welcoming..",
  ONBOARD_FRIDAY_SUNDAY: "Friday - Sunday",
  ONBOARD_MONDAY_THURSDAY: "Monday - Thursday",
  ONBOARD_B1: "B1",
  ONBOARD_INTERMEDIATE: "Intermediate",
  ONBOARD_B2: "B2",
  ONBOARD_UPPER: "Upper",
  ONBOARD_UPPER_INTERMEDIATE: "Intermediate",
  ONBOARD_C1: "C1",
  ONBOARD_ADVANCED: "Advanced",
  ONBOARD_C2: "C2",
  ONBOARD_PROFICIENT: "Proficient",
  ONBOARD_START_TYPING_HERE: "Start typing here..",
  ONBOARD_CHOSEN_SKILLS: "You have chosen {number} out of {total} skills",
  ONBOARD_CHOSEN_INTERESTS: "You have chosen {number} out of {total} interests",
  ONBOARD_USE_YOUR_ANSWERS:
    "We will use your answers  to recommend people and events that match your availability.",
  ONBOARD_UPDATE_LATER: "You can easily update this later if you need to.",
  ONBOARD_ENGLISH_GOALS:
    "We want to help you achieve your English goals! Choose up to five (5) skills from the list below.",
  ONBOARD_SMILE: "Show us your beautiful smile!",
  ONBOARD_WELCOME: "Welcome {name}!",
  ONBOARD_READY_TO_MEET: "Ready to meet friends to practise English with?",
  ONBOARD_START: "We’ll start by asking some simple questions to create your profile.",
  ONBOARD_USE_ANSWERS:
    "We will use your answers to introduce you to other members we think you should meet.",
  ONBOARD_WHAT_CITY_DO_YOU_LIVE: "What city do you live in?",
  ONBOARD_ENGLISH_SKILLS: "What English skills do you want to practise & improve?",
  ONBOARD_CONFIRM_YOUR_NAME: "Please confirm your name",
  ONBOARD_WHAT_ARE_YOUR_INTERESTS: "What are your interests & hobbies?",
  ONBOARD_SHARE_YOUR_STORY: "Let’s share your story!",
  ONBOARD_FANTASTIC: "Fantastic!",
  ONBOARD_STEP_INSIDE: "Let’s step inside {logo} so you can take a look around.",
  ONBOARD_EMAIL:
    "We will email you in the coming days with tips from our team to help you meet speaking partners and learn how to use the platform.",
  ONBOARD_ENTER: "Enter",
  ONBOARD_CONGRATULATIONS: "Congratulations, {name}!",
  ONBOARD_COMPLETED_ALL_STEPS:
    "Now that you have been verified and earned your first badges, you can:",
  ONBOARD_ACCESS_EVENTS: "Access <bold>all</bold> Community & Coach events!",
  ONBOARD_JOIN_VIDEO_CALLS: "Join <bold>instant video calls</bold> via the Chat!",
  ONBOARD_CREATE_AND_HOST: "Create & host <bold>your own</bold> events!",
  ONBOARD_CLOSE_EDIT_PROFILE_TITLE: "Are you sure you want to leave before you finish?",
  ONBOARD_CLOSE_EDIT_PROFILE_DESCRIPTION: "Your information will not be saved.",
  ONBOARD_MISSING_FIELDS: "To complete this step, you need to add:",
  ONBOARD_SETUP_PROFILE_MISSING_PHOTO: "Photo",
  ONBOARD_SETUP_PROFILE_MISSING_FIRST_NAME: "First name",
  ONBOARD_SETUP_PROFILE_MISSING_LAST_NAME: "Last name",
  ONBOARD_SETUP_PROFILE_MISSING_STORY: "Personal introduction (min 30 words)",
  SEARCH_BAR_SEARCH_RESULTS: "Search results",
  SEARCH_BAR_START_TYPING: "Start typing",
  SEARCH_BAR_EVENTS: "Events:",
  SEARCH_BAR_LOADING: "Searching...",
  SEARCH_BAR_NO_EVENTS_FOUND: "No events found",
  SEARCH_BAR_OPTION_MEMBERS: "Members",
  SEARCH_BAR_OPTION_MEMBERZINE: "Memberzine",
  SEARCH_BAR_OPTION_RESOURCES: "Resources",
  SEARCH_BAR_ARTICLE_LABEL: "Article:",
  SEARCH_BAR_LESSON_LABEL: "Lesson:",
  SETTINGS_FIRST_PAYMENT_JOIN_COMMUNITY: "Join the Community",
  SETTINGS_FIRST_PAYMENT_YOUR_JOURNEY_BEGINS: "Your journey begins with our Foundations Program",
  SETTINGS_FIRST_PAYMENT_CLICK_HERE: "<url>Click here</url> for full details",
  SETTINGS_FIRST_PAYMENT_FOUNDATIONS_PROGRAM: "Foundations Program",
  SETTINGS_FIRST_PAYMENT_A_LIFE_CHANGING_EXPERIENCE: "A life-changing English experience!",
  SETTINGS_FIRST_PAYMENT_COACHING_FROM_EXPERTS: "Coaching from expert English teachers",
  SETTINGS_FIRST_PAYMENT_ACCESS_TO_MATERIALS:
    "Access to structured learning materials, workbooks and conversation training",
  SETTINGS_FIRST_PAYMENT_GET_INTRODUCED_TO_SPEAKING_PARTNERS: "Get introduced to speaking partners",
  SETTINGS_FIRST_PAYMENT_DAILY_EVENTS: "Daily events to learn and practise together!",
  SETTINGS_FIRST_PAYMENT_USD_PER_MONTH: "USD/month",
  SETTINGS_FIRST_PAYMENT_SPECIAL_OFFER: "Special Offer",
  SETTINGS_FIRST_PAYMENT_OFFER_FOR_NEW_MEMBERS: "Introductory offer for new members",
  SETTINGS_FIRST_PAYMENT_CANCEL_ANY_TIME: "You can cancel any time.",
  SETTINGS_FIRST_PAYMENT_FOUNDATIONS: "Foundations",
  SETTINGS_FIRST_PAYMENT_3_MONTH_PACKAGE: "{months} Month Package",
  SETTINGS_FIRST_PAYMENT_SET_YOURSELF_UP_FOR_SUCCESS: "Set yourself up for success!",
  SETTINGS_FIRST_PAYMENT_IMMERSE_YOURSELF:
    "Immerse yourself in our English-speaking community for three months and get results you can actually ",
  SETTINGS_FIRST_PAYMENT_FEEL: "feel!",
  SETTINGS_FIRST_PAYMENT_SIGN_UP: "Sign up for 3 months and save!",
  SETTINGS_FIRST_PAYMENT_MONEY_BACK: "14 day money-back guarantee",
  SETTINGS_FIRST_PAYMENT_PURCHASE_PACKAGE: "Purchase Package",
  SETTINGS_FIRST_PAYMENT_USD_MONTH: "USD/{months} mths",
  SETTINGS_FIRST_PAYMENT_EQUIVALENT_PER_WEEK: "Equivalent {amount} USD / week",
  SETTINGS_PAGE_ACTIVITY_AN_EMAIL_WILL_BE_SENT:
    "An email will be sent to you every time there is new activity",
  SETTINGS_PAGE_ACTIVITY_HAPPENS: "Activity happens",
  SETTINGS_PAGE_CHANGE_PASSWORD: "Change Password",
  SETTINGS_PAGE_CHANGE_OLD_PASSWORD_HINT: "The password is required",
  SETTINGS_PAGE_DAILY_DIGEST: "Daily Digest",
  SETTINGS_PAGE_DAILY_DIGEST_EMAIL_WILL_BE_SENT:
    "A daily digest email will be sent to you each day to summarise your notification. Note that time-sensitive notifications may still send as activity happens, including account updates and event reminders",
  SETTINGS_PAGE_EMAIL_AND_PASSWORD: "Email & Password",
  SETTINGS_PAGE_EMAIL_ME_WHEN: "Email me when",
  SETTINGS_PAGE_ACCOUNT_SETTINGS: "Account Settings",
  SETTINGS_PAGE_NOTIFICATIONS_SETTINGS: "Notifications Settings",
  SETTINGS_PAGE_NEVER_MISS_A_THING:
    "Never miss a thing! Tick the boxes below to manage how you would like to receive notifications.",
  SETTINGS_PAGE_PLATFORM: "Platform",
  SETTINGS_PAGE_EMAIL: "Email",
  SETTINGS_PAGE_EVENT_UPDATES_AND_INVITATIONS: "Event updates and invitations",
  SETTINGS_PAGE_EVENT_UPDATES_AND_INVITATIONS_DESCRIPTION:
    "Get notified when a host invites you to their event  or when changes are made to the date or time.",
  SETTINGS_PAGE_EVENT_REMINDERS: "Event Reminders",
  SETTINGS_PAGE_EVENT_REMINDERS_DESCRIPTION:
    "Get reminders when an event you’re going to is about to start.",
  SETTINGS_PAGE_EVENT_RSVPS: "Event RSVP’s",
  SETTINGS_PAGE_EVENT_RSVPS_DESCRIPTION:
    "Get notified when someone you invite to an event accepts or declines",
  SETTINGS_PAGE_ACTIVITY_FROM_MEMBERS: "Activity From Members You Follow",
  SETTINGS_PAGE_ACTIVITY_FROM_MEMBERS_DESCRIPTION:
    "Get notified about new activity from the members that matter to you.",
  SETTINGS_PAGE_MENTIONS_AND_COMMENTS: "Mentions and comments",
  SETTINGS_PAGE_MENTIONS_AND_COMMENTS_DESCRIPTION:
    "Get notified when someone mentions you in a comment",
  SETTINGS_PAGE_CHAT_MENTIONS: "Mentions in chat",
  SETTINGS_PAGE_CHAT_MENTIONS_DESCRIPTION:
    "Get notified when someone mentions you in a chat group message",
  SETTINGS_PAGE_LIVE_TABLE_STARTED: "Live Table has started",
  SETTINGS_PAGE_LIVE_TABLE_STARTED_DESCRIPTION:
    "Get notified when someone has started a new live table",
  SETTINGS_PAGE_PLATFORM_STATUS: "Platform status",
  SETTINGS_PAGE_PLATFORM_STATUS_DESCRIPTION:
    "Get notified when there are changes affecting the platform",
  SETTINGS_PAGE_MEMBERSHIP_SUBSCRIPTION_CHANGES: "Membership subscription changes",
  SETTINGS_PAGE_MEMBERSHIP_SUBSCRIPTION_CHANGES_DESCRIPTION:
    "Get confirmation of changes to your subscription",
  SETTINGS_PAGE_MEMBERSHIP_SETTINGS: "Membership Settings",
  SETTINGS_PAGE_NOTIFY_ME_BY_EMAIL: "Yes, notify me by email",
  SETTINGS_PAGE_ACTIVATE_MEMBERSHIP: "Activate Membership",
  SETTINGS_PAGE_STAY_PAUSED: "Stay paused",
  SETTINGS_PAGE_READY_TO_COME_BACK_EARLY: "Ready to come back early?",
  SETTINGS_PAGE_YOURE_READY_TO_ACTIVATE:
    "You're welcome to activate your paused membership and step back into the community today.",
  SETTINGS_PAGE_CLICK_BUTTON_BELLOW:
    "Click the button below and we'll to activate your membership and update your billing cycle right away!",
  SETTINGS_PAGE_DELETED_ACCOUNT_HEADER: "Your account is going to be deleted",
  SETTINGS_PAGE_DELETED_ACCOUNT_DESCRIPTION:
    "This account {email} is going to be deleted. You can still recover this account until it is deleted completely.",
  SETTINGS_PAGE_DELETED_ACCOUNT_RECOVER: "Recover account",
  SETTINGS_PAGE_DELETED_ACCOUNT_ERROR_RECOVER:
    "Failed to recover an account. Please try again later or contact support!",
  SETTINGS_MEMBERSHIP_FREE_MEMBERSHIP: "You have 1 Month of Free Membership!",
  SETTINGS_MEMBERSHIP_FREE_THANKS:
    "Thanks for your contribution to building The Hey Lady Community!",
  SETTINGS_MEMBERSHIP_FREE_USE: "Use",
  SETTINGS_PAGE_FREE: "Free",
  SETTINGS_MEMBERSHIP_MONTHLY: "Monthly",
  SETTINGS_MEMBERSHIP_MONTHLY_UNLIMITED_PRACTICE:
    "Unlimited speaking practice time. Your progress depends only on your effort.",
  SETTINGS_MEMBERSHIP_YEARLY: "Yearly",
  SETTINGS_MEMBERSHIP_YEARLY_SAVE: "Save {percent}%",
  SETTINGS_MEMBERSHIP_YEARLY_YEAR: "USD/year",
  SETTINGS_MEMBERSHIP_YEARLY_ADDITIONAL_DISCOUNT:
    "If you want to practice English next year with an additional discount - we have a special deal for you!",
  SETTINGS_SWITCH_MEMBERSHIP_PLAN: "Switch Membership Plan?",
  SETTINGS_PLAN_SUCCESSFULLY_UPDATED: "Your plan will be updated soon.",
  SETTINGS_SWITCH_MEMBERSHIP_WHEN_YOU_SWITCH:
    "When you switch plans, we will adjust your first payment with any credit remaining on your existing plan. You can also edit your credit/debit card details if you need to.",
  SETTINGS_SWITCH_MEMBERSHIP_KEEP_CURRENT: "Keep current",
  SETTINGS_MEMBERSHIP_YEARLY_SWITCH_PLAN: "Switch Plan",
  SETTINGS_MEMBERSHIP_YOU_ARE_STAYING: "YAY! You're staying!",
  SETTINGS_MEMBERSHIP_YOUR_REQUEST:
    "Your request to cancel has been removed and your membership will continue at the end of your current billing cycle.",
  SETTINGS_MEMBERSHIP_SUCCESS: "Success!",
  SETTINGS_MEMBERSHIP_REACTIVATED_SUBSCRIPTION:
    "Your subscription has been reactivated. Please allow up to 30 minutes for our system to update your billing cycle and restore your access to the platform. You can refresh the page to check after 5 minutes.",
  SETTINGS_MEMBERSHIP_IF_YOUR_ACCOUNT:
    "If your account is not reactivated within 30 minutes, please contact <url>hello@heylady.io</url>.",
  SETTINGS_FOUNDATIONS: "Foundations",
  SETTINGS_FOUNDATIONS_PACKAGE: "Foundations Package",
  SETTINGS_FOUNDATIONS_DESCRIPTION:
    "Need a little extra support? This plan includes guided conversations hosted by our qualified English teachers.",
  SETTINGS_FOUNDATIONS_USD_PER_MONTHS: "USD /{number} months",
  SETTINGS_RISE_MONTHLY: "Rise Monthly",
  SETTINGS_RISE_MONTHLY_DESCRIPTION:
    "Focus on fluency and take your English journey into your own hands. Create and host your own events with English-speaking friends around the world!",
  SETTINGS_RISE_MONTHLY_AVAILABLE: "Available after {number} months",
  SETTINGS_RISE_YEARLY: "Rise Yearly",
  SETTINGS_RISE_YEARLY_PER_YEAR: "USD/year",
  SETTINGS_RISE_YEARLY_DESCRIPTION:
    "Same as Rise Monthly but commit to your progress for a full year and save!",
  SETTINGS_SWITCH_TO_YEARLY: "SAVE 36% on your membership fees by switching to a yearly plan.",
  SETTINGS_ANNUAL_PLAN_DESCRIPTION:
    "PLUS you will receive a 45 min session with a Hey Lady! coach to develop a personalised learning plan!",
  SETTINGS_SWITCH_TO_QUARTERLY: "Switch to Quarterly to save 24% <bold>plus</bold>:",
  SETTINGS_QUARTERLY_PLAN_DESCRIPTION:
    "Get 'Fluency Foundations' a 12-week course to accelerate your spoken English! <url>Learn about course</url>",
  SETTINGS_FREE_PLAN_DESCRIPTION:
    "Friends of Hey Lady! members enjoy one month of free access to everything that community offers!",
  SETTINGS_MONTHLY_PLAN_DESCRIPTION:
    "Continue your membership with Hey Lady! on an affordable monthly plan with unlimited conversation practice, speaking partners, lessons and more!",
  SETTINGS_3_MTH_DESCRIPTION:
    "This introductory plan gives you three months access to Hey Lady! to meet new friends and build your fluency in" +
    " real conversations!",
  SETTINGS_3_MTH_DESCRIPTION_2: "Switch plans to extend your membership at any time.",
  SETTINGS_SUPPORTER_PLAN_DESCRIPTION_TOP:
    "Feel good supporting Hey Lady! as a friend while you take a break.",
  SETTINGS_SUPPORTER_PLAN_DESCRIPTION_BOTTOM:
    "You will still have access to basic platform features and your profile history, badges and achievements will be waiting for you when you return!",
  SETTINGS_CARD_DAYS_REMAINING: "{days} days remaining (then ${amount}/mth)",
  SETTINGS_CARD_RENEWS_IN: "renews in {days} days",
  SETTINGS_CARD_DAYS_LEFT: "{days} days remaining",
  SETTINGS_CARD_USD_PER_MONTH: "USD/month",
  SETTINGS_CARD_USD_PER_THREE_MONTHS: "USD/3 months",
  SETTINGS_CARD_DIFFERENCE: "What’s the difference?",
  SETTINGS_CARD_CURRENT_PLAN: "Current Plan",
  SETTINGS_CARD_PAUSE_MEMBERSHIP: "Pause",
  SETTINGS_CARD_PAUSE_UPDATE: "Update",
  SETTINGS_CARD_CHOOSE_PLAN: "Choose this plan",
  SETTINGS_CARD_BUY_PLAN: "Buy this plan",
  SETTINGS_CARD_SWITCH: "Switch",
  SETTINGS_CARD_ACTIVATE: "Activate",
  SETTINGS_PLAN_DIFFERENT_PRICE_TOOLTIP:
    "The current price of this plan is now {planAmount}/{period}. As an existing subscriber you will continue to enjoy {userAmount}/{period} until you cancel. If you rejoin after cancelling the increased price will apply.",
  SETTINGS_PLAN_DIFFERENT_PRICE_LABEL: "For you:",
  SETTINGS_ACTIVATE_MODAL_HEADING: "Ready to be a Member?",
  SETTINGS_ACTIVATE_MODAL_DESC:
    "Activate your plan today to end your trial and pay for your membership. You will get instant access to even more events and features.",
  SETTINGS_ACTIVATE_MODAL_DESC_CHARGE: "Your account will be charged automatically.",
  SETTINGS_ACTIVATE_MODAL_ACTIVATE_NOW: "Activate now",
  SPARKS_MODAL_AFTER_CALL_TITLE: "{name}, did you feel a spark?",
  SPARKS_MODAL_AFTER_CALL_DESCRIPTION:
    "We hope so! If you felt a meaningful connection to one lady (or many ladies) during this conversation, let us know by clicking the button below!",
  SPARKS_MODAL_AFTER_CALL_YES_BUTTON: "Yes, I felt a spark!",
  SPARKS_MODAL_AFTER_CALL_NO_BUTTON: "Sorry, not this time",
  TRIAL_BANNER_HEADING: "Congratulations! You've got credit!",
  TRIAL_BANNER_DESCRIPTION:
    "The next time your Hey Lady! account is charged, any available credit will be used to reduce the total!",
  REFERRAL_BANNER_HEADING: "Invite a friend to join for FREE {br}and you could earn rewards!",
  REFERRAL_BANNER_LINK: "<link>Find out more</link>",
  REFERRAL_BANNER_HEADING_BF: "Black Friday - $30 for you and your friends!",
  REFERRAL_BANNER_DESCRIPTION_BF:
    "Share your code with a friend. If they join by 30th November, you'll both get $30" +
    " towards any membership plan! <link>See details</link>",
  REFERRAL_BANNER_BALANCE: "Credit balance:",
  REFERRAL_BANNER_CODE: "Your unique code:",
  REFERRAL_CARD_INVITE_A_FRIEND: "Invite a Friend",
  REFERRAL_CARD_REWARDS: "My Rewards",
  REFERRAL_CARD_EMAIL_SENT: "Email is sent!",
  REFERRAL_CARD_INVITE_TITLE: "Invite a friend to try{br}{HL} for FREE",
  REFERRAL_CARD_INVITE_DESCRIPTION:
    "Send your friend a special link to enjoy {br} <bold>a 7-day free trial PLUS $20" +
    " free credit {br} in her Hey Lady! account!</bold> {br}You will earn rewards for friends who continue after the  trial.",
  REFERRAL_CARD_CHOOSE_HOW_TO_INVITE: "Choose how you want to invite them:",
  REFERRAL_CARD_ENTER_FRIEND_EMAIL:
    "Enter your friend’s email and we will send her the  invitation on your behalf.",
  REFERRAL_SEND_LINK_BY_EMAIL: "Send Link by Email",
  REFERRAL_COPY_LINK_TO_CLIPBOARD: "Copy Invitation Link to Clipboard",
  REFERRAL_LINK_COPIED: "Link copied to Clipboard!",
  REFERRAL_LEARN_MORE: "<link>Click here</link> to learn more about how invitations work.",
  REFERRAL_CARD_REWARDS_TITLE: "Earn rewards when your friends join!",
  REFERRAL_CARD_REWARDS_DESCRIPTION:
    "When a friend you invite continues after their free trial on any paid membership plan you can earn fantastic rewards.<sup>*</sup>",
  REFERRAL_BADGE_BROUGHT_FRIEND_TITLE:
    "Earn the <span>“Brought a friend”</span>badge when your first friend joins",
  REFERRAL_BADGE_AMBASSADOR_TITLE: "<span>“Ambassador”</span>- 5 friends",
  REFERRAL_BADGE_PLATINUM_AMBASSADOR_TITLE: "<span>“Platinum Ambassador”</span>- 25 friends",
  REFERRAL_BADGE_DIAMOND_AMBASSADOR_TITLE: "<span>“Diamond Ambassador”</span>- 50 friends",
  REFERRAL_REWARD_50_CREDIT: "<span>$50 credit</span>to your account",
  REFERRAL_REWARD_200_CREDIT: "<span>$200 credits</span>to your account",
  REFERRAL_REWARD_12_MONTHS_FREE: "<span>12 months free</span>membership",
  REFERRAL_REWARD_LIFETIME_MEMBERSHIP: "<span>Lifetime membership</span>",
  REFERRAL_REWARD_RETURN_FLIGHT: "<span>Return flights</span>to a Hey Lady! Meetup",
  REFERRAL_FRIENDS_JOINED: "Friends joined",
  REFERRAL_CREDIT_BALANCE: "Credit balance",
  REFERRAL_TERMS_AND_CONDITIONS:
    "<sup>*</sup>Terms and conditions apply. <link>Find out more</link>",
  REFERRAL_WELCOME_SIGN_UP: "I want to sign up",
  REFERRAL_WELCOME_GREETING: "Great to see you!",
  REFERRAL_WELCOME_RECEIVE_INVITE: "Receive an invite",
  REFERRAL_WELCOME_WATCH_VIDEO: "Watch the video",
  REFERRAL_WELCOME_WATCH_VIDEO_DURATION: "2 min",
  REFERRAL_WELCOME_SIGN_UP_IN: "Sign up in Hey Lady!",
  REFERRAL_WELCOME_SIGN_UP_DURATION: "3 min",
  REFERRAL_WATCH_VIDEO_TO_CONTINUE: "Watch video to continue",
  REFERRAL_WELCOME_FRIEND_TITLE: "You’re {name}’s friend, right?",
  REFERRAL_WELCOME_FRIEND_DESCRIPTION:
    "{name} has offered you a special “members-only” invitation to experience {HL} including:",
  REFERRAL_WELCOME_TRIAL: "7-day free trial of a full {HL} Membership",
  REFERRAL_WELCOME_CREDIT: "US$20 credit in your {HL} account",
  REFERRAL_WELCOME_CANCEL_ANY_TIME:
    "Easily cancel any time before your trial ends to avoid being charged if you" +
    " decide not to stay.",
  REFERRAL_WELCOME_WE_WILL_LET_KNOW:
    "We will let {name} know once you have finished signing up.{br}You can send her a message once you’re inside!",
  REFERRAL_WELCOME_INFO_TITLE: "Connecting women through English",
  REFERRAL_WELCOME_INFO_HEY_LADY:
    "Hey Lady! is the world’s leading English speaking community created especially for women.",
  REFERRAL_WELCOME_INFO_COMMUNITY:
    "By stepping inside our community, you are following the footsteps of more than 8000 women from over 120 countries on a rewarding journey to English fluency, friendship and freedom.",
  REFERRAL_WELCOME_INFO_JOIN:
    "So, if you enjoy your time, we’d love you to join and continue your journey with us!",
  REFERRAL_CODE_NOT_FOUND: "Invalid referral code",
  REFERRAL_CODE_NOT_FOUND_DESCRIPTION:
    "The referral code you entered is not valid. Please contact your friend for a" +
    " new code or sign up without a code.",
  REFERRAL_CONTINUE_WITHOUT_CODE: "Continue without code",
  REFERRAL_SEE_MY_REWARDS: "See My Rewards",
  TOPICS_PAGE_TOPIC_LIBRARY: "Topic Library",
  TOPICS_PAGE_GRAMMAR_LIBRARY: "Grammar Library",
  TOPICS_PAGE_RESOURCES: "Resources",
  TOPICS_PAGE_CURRENT_TOPIC: "Current Topic",
  TOPICS_PAGE_EXPLORE_TOPIC: "Explore Topic",
  TOPICS_PAGE_LEARN_MORE: "Read",
  TOPICS_PAGE_EXPLORE_TOPICS: "Explore Topics",
  TOPICS_PAGE_COMMUNITY_RESOURCES: "Community Resources",
  TOPICS_PAGE_RESOURCES_BANNER_DESCRIPTION:
    "This month, we'll explore our communities and how they enrich our lives!",
  TOPICS_PAGE_LETS_DIVE_DEEPER: "Ready for a deep dive?",
  TOPICS_PAGE_LETS_STUDY_VOCABULARY: "Let’s Study Vocabulary",
  TOPIC_PAGE_CREATE_CASUAL_CONVERSATION: "Host a Casual Conversation",
  TOPIC_PAGE_CREATE_DEEP_DIVE: "Take the plunge! Host a Deep Dive event for this topic!",
  TOPIC_PAGE_CREATE_STUDY_GROUP: "Why not create a Study Group to improve your vocab and grammar?",
  TOPIC_PAGE_DEEP_DIVE: "Deep Dive",
  TOPIC_PAGE_DEEP_DIVE_OVERVIEW: "Deep Dive Overview",
  TOPIC_PAGE_EVENT_DISCUSSION: "Topic Discussion",
  TOPIC_PAGE_DISCUSSION: "Discussion",
  TOPIC_PAGE_FIND_RELATED_EVENTS: "Find related events",
  TOPIC_PAGE_HIDE_ANSWERS: "Hide Answers",
  TOPIC_PAGE_WATCH_INTRO_VIDEO: "Watch intro video",
  TOPIC_PAGE_IDEAS_TO_DISCUSS: "Ideas to discuss",
  TOPIC_PAGE_LESSON_OVERVIEW: "Lesson Overview",
  TOPIC_PAGE_LETS_CREATE_DEEP_DIVE: "Host a Deep Dive",
  TOPIC_PAGE_LETS_CREATE_STUDY_GROUP: "Host a Study Group",
  TOPIC_PAGE_LETS_DIVE_DEEPER: "Let’s Dive Deeper",
  TOPIC_PAGE_LINKS_TO_OTHER_RESOURCES: "Links to other resources",
  TOPIC_PAGE_OVERVIEW: "Overview",
  TOPIC_PAGE_SHOW_ANSWERS: "Show Answers",
  TOPIC_PAGE_VOCABULARY_PRACTICE: "Vocabulary Practice",
  TOPIC_PAGE_DEEP_DIVE_PRACTICE: "Deep Dive Practice",
  TOPIC_PAGE_USEFUL_VOCABULARY: "Useful Vocabulary",
  TOPIC_PAGE_STUDY_VOCABULARY: "Study Vocabulary",
  TOPIC_PAGE_VOCABULARY: "Vocabulary",
  TOPIC_PAGE_WOULD_YOU_LIKE_TO_HOST_AN_EVENT: "Why not host an event to talk about this topic?",
  TOPIC_PAGE_UPGRADE_READY: "Ready to study with us again?",
  TOPIC_PAGE_UPGRADE_DESC:
    "Upgrade your plan and get instant access to our events, video calls and learning resources.",
  TOPIC_PAGE_UPGRADE_SEE_PLANS: "See plans",
  USERS_PAGE_MEMBER_SEARCH: "Member Search",
  USERS_PAGE_NO_RESULTS: "Sorry, {name}! We searched but couldn't find a match.",
  USERS_PAGE_UPDATE_FILTERS: "Try a different combination of filters.",
  USERS_PAGE_JOINED: "Joined {date}",
  USERS_PAGE_DIFFERENT_AVAILABILITY: "Different Availability",
  USERS_PAGE_SIMILAR_ENGLISH_LEVEL: "Similar English Level",
  USERS_PAGE_SAME_ENGLISH_LEVEL: "Same English Level",
  USERS_PAGE_HERS: "Hers",
  USERS_PAGE_MINE: "Mine",
  USERS_PAGE_IN_YOUR_TIMEZONE: "(Shown in your timezone)",
  USERS_PAGE_MON_THU: "Mon-Thu:",
  USERS_PAGE_FRI_SUN: "Fri-Sun:",
  USERS_PAGE_HEY_LADY_TEAM: "{title} Team",
  USERS_PAGE_LOVES_TALKING_ABOUT: "Loves talking about:",
  USERS_PAGE_LAST_ONLINE: "Last online {distance} ago",
  USERS_PAGE_PERSONAL_INTERESTS: "My personal interests:",
  USERS_PAGE_ENGLISH_LEVEL: "My English level:",
  USERS_PAGE_SKILLS_I_WANT_TO_IMPROVE: "The skills I want to improve:",
  USERS_PAGE_ONLINE_NOW: "Online Now",
  USERS_PAGE_MEMBERS: "Members",
  USERS_PAGE_SHARED_AVAILABILITY: "Shared Availability",
  USERS_PAGE_TIMES: "Times you can meet",
  USER_PAGE_ADD_MORE_DETAILS: "Please, add more details...",
  USER_PAGE_HOSTED_EVENTS: "{name}'s Events",
  USER_PAGE_ARTICLES: "{name}'s Articles",
  USER_PAGE_MEMBER_INFO: "Member Profile",
  USER_PAGE_MY_PHOTOS: "Photos ({photos})",
  USER_PAGE_PERSONAL_EVENTS_GOING_TO: "Events {name}'s Going To",
  USER_PAGE_PERSONAL_INFO: "Personal Info",
  USER_PAGE_YOU_ARE_FOLLOWING: "You’re following {firstName}",
  USER_PAGE_ABLE_TO_SEE_EVENTS:
    "You’ll be able to see the events {firstName} is hosting and find them more easily.",
  USER_PAGE_SEND_MESSAGE: "Send Message",
  USER_PAGE_REPORT: "Report",
  USER_PAGE_REPORT_ABUSE: "Report abuse",
  USER_PAGE_REPORT_ABUSE_OTHER_REASON: "Other Reason",
  USER_PAGE_REPORT_ABUSE_MALE_GENDER_OR_FALSE_IDENTITY_OR_PREVIOUSLY_BANNED:
    "I don't believe this person should be in the community because of their gender (male), false identity or because they have been previously banned.",
  USER_PAGE_REPORT_ABUSE_OFFENSIVE_OR_DISRESPECTFUL:
    "I consider something this person has said or written to be offensive/disrespectful to another persons religious or political beliefs, nationality, ethnicity or culture practices.",
  USER_PAGE_REPORT_ABUSE_UNACCEPTABLE_BEHAVIOUR:
    "I found this person's behaviour to be unacceptable and not aligned with the values of our community during an event I attended.",
  USER_PAGE_REPORT_USER_ABUSE: "Report Abuse",
  USER_PAGE_REPORT_USER_ABUSE_HAS_BEEN_SENT: "The report has been sent",
  USER_PAGE_REPORT_USER_ABUSE_PLEASE_TELL_US:
    "Please help us understand why you decided to report {name}",
  USER_PAGE_REPORT_USER_ABUSE_THANK_YOU:
    "Thank you for being proactive. We'll process {break} your report as soon as we possibly can.",
  USER_PAGE_REPORT_USER_ABUSE_WE_ARE_VERY_SORRY:
    "We are very sorry to know you experienced negative behaviour in {break} our community.",
  USER_PAGE_STORY: "Story",
  CREATE_EVENT_PAGE_HOSTED_BY: "Hosted By",
  CREATE_EVENT_PAGE_UNLIMITED: "No limit",
  CREATE_EVENT_PAGE_UP_TO: "Up to",
  CREATE_EVENT_PAGE_PLACES_REMAINING: "{places} places remaining",
  CREATE_EVENT_PAGE_UNLIMITED_PLACES_REMAINING: "Unlimited",
  CREATE_EVENT_PAGE_WHO_WILL_HOST_THE_EVENT: "Who will host the event?",
  CREATE_EVENT_PAGE_HOSTS_AND_PARTICIPANTS: "Hosts and Participants",
  CREATE_EVENT_PAGE_QUESTIONS_WE_WILL_EXPLORE: "Questions we will explore",
  CREATE_EVENT_PAGE_FOUNDATION_USER: "Foundation User",
  CREATE_EVENT_PAGE_RISE_USER: "Rise User",
  CREATE_EVENT_PAGE_UNVERIFIED: "Unverified",
  CREATE_EVENT_PAGE_LITTLE_SISTERS: "Little Sisters",
  CREATE_EVENT_PAGE_1_3_MONTHS: "1-3 months",
  CREATE_EVENT_PAGE_3_12_MONTHS: "3-12 months",
  CREATE_EVENT_PAGE_3_6_MONTH: "3-6 month",
  CREATE_EVENT_PAGE_6_12_MONTH: "6-12 month",
  CREATE_EVENT_PAGE_12_MONTHS_PLUS: "12 months+",
  CREATE_EVENT_PAGE_INTERMEDIATE: "Intermediate",
  CREATE_EVENT_PAGE_ADVANCED: "Advanced",
  CREATE_EVENT_PAGE_WHAT_IS_THIS: "What is this?",
  CREATE_EVENT_PAGE_CHOOSE_INTERMEDIATE:
    " {separator} Choose this if your event is best for ladies with Intermediate English (B1 or B2), or may be feeling less confident about speaking.",
  CREATE_EVENT_PAGE_CHOOSE_ADVANCED:
    " {separator} Choose this if your event is best for ladies with Advanced English (C1 or above) who want to practise speaking with other high-level speakers.",
  CREATE_EVENT_PAGE_CHOOSE_ALL:
    " {separator} Choose this if you are happy for all ladies to attend - whether intermediate or advanced!",
  CREATE_EVENT_PAGE_WHICH_TYPE_OF_MEMBER_WILL_ATTEND:
    "Which type of members will be able to attend the event?",
  CREATE_EVENT_PAGE_CHOOSE_EVENT_CATEGORY: "What type of event is this?",
  CREATE_EVENT_PAGE_IS_IT_PROFESSIONAL: "Is this event related to professional skills?",
  CREATE_EVENT_PAGE_CONVERSATION_TYPE: "Conversation",
  CREATE_EVENT_PAGE_STUDY_AND_LEARN_TYPE: "Study & Learn",
  CREATE_EVENT_PAGE_PROFESSIONAL_TYPE: "Professional",
  CREATE_EVENT_PAGE_PROFESSIONAL_DESCRIPTION:
    "Choose 'yes' if your event is designed to help members practise or" +
    " learn skills that will help them at work.",
  CREATE_EVENT_PAGE_WHICH_LEVEL: "Which level is this event suitable for?",
  CREATE_EVENT_PAGE_CHOOSE_THE_COVER: "Choose the Cover",
  CREATE_EVENT_PAGE_CHOOSE_THE_COVER_FROM_OUR_OPTIONS: "Choose the event cover from our options.",
  CREATE_EVENT_PAGE_CHOOSE_FROM_OUR_SUGGESTIONS: "Choose from our suggestions",
  CREATE_EVENT_PAGE_MY_CUSTOM_COVER: "My custom cover",
  CREATE_EVENT_PAGE_YOU_CAN_ADD_LINKS_TO_MATERIALS:
    "Add the link to a website, video or blog post here so ladies can review it before your event.",
  CREATE_EVENT_PAGE_ADD_NEW_LINK: "Add New Link",
  CREATE_EVENT_PAGE_LINK: "Link",
  CREATE_EVENT_PAGE_ENTER_LINK_DESCRIPTION: "Enter link description",
  CREATE_EVENT_PAGE_PASTE_URL: "Paste URL",
  CREATE_EVENT_PAGE_LINK_DESCRIPTION: "Link description",
  CREATE_EVENT_PAGE_DO_YOU_WANT_TO_DELETE_THIS_ATTACHMENT: "Do you want to delete this Attachment?",
  CREATE_EVENT_PAGE_AFTER_DELETING_THIS_PHOTO:
    "After deleting this photo, it will no longer be available",
  CREATE_EVENT_PAGE_CHOOSE_EVENT_HOST: "Choose Event Host",
  CREATE_EVENT_PAGE_HOW_MANY_LADIES: "How many ladies can join this event?",
  CREATE_EVENT_PAGE_IS_THIS_A_PRIVATE_EVENT: "Is it a private event?",
  CREATE_EVENT_PAGE_ENTER_EVENT_HOST_NAME: "Enter event host name here",
  CREATE_EVENT_PAGE_START_TYPING_A_FIRST_OR_LAST_NAME:
    "Start typing a first or last name to search.",
  CREATE_EVENT_PAGE_PARTICIPANTS_ERROR:
    "Uh oh! You have more participants than the maximum capacity you chose ({number}). Please remove participants or increase the maximum capacity.",
  CREATE_EVENT_PAGE_PARTICIPANTS_NOTE:
    "Note: Only members included in 'Which type of members will be able to attend' settings will appear in your search.",
  CREATE_EVENT_IS_THIS_A_PRIVATE_EVENT: "Is this a private event? (Only visible to invited guests)",
  CREATE_EVENT_PAGE_PRIVATE_EVENT: "Private Event",
  CREATE_EVENT_PAGE_LADIES_EXCLUDING_HOST: "(excluding host)",
  CREATE_EVENT_PAGE_INVITE_PEOPLE_TO_YOUR_EVENT: "Invite people to your event (if you wish)",
  CREATE_EVENT_PAGE_DO_YOU_WANT_TO_UPLOAD:
    "Optional: Do you want to upload something for ladies so they can prepare for your event?",
  CREATE_EVENT_PAGE_MAKE_SURE_YOU_UPLOAD:
    "Please upload supported file types only: .jpeg, .doc, .docx, .pdf, .png",
  CREATE_EVENT_PAGE_UPLOAD_FILE: "Upload File",
  CREATE_EVENT_PAGE_OCCURS_EVERY: "Occurs Every",
  CREATE_EVENT_PAGE_SPECIFY_TIME_DETAILS: "Specify time details",
  CREATE_EVENT_PAGE_END_DATE_AND_TIME: "End Date and Time",
  CREATE_EVENT_WARNING_IMPORTANT: "Important information for hosts",
  CREATE_EVENT_WARNING_HL_VIDEO:
    "All events in our platform are now using Hey Lady! Video (<u>not</u> Zoom) to allow all members to enjoy unlimited call time.",
  CREATE_EVENT_WARNING_PLEASE_NOTE:
    "Please note that this feature is currently in beta, which means it is still 'in progress' and being tested. There are some differences you should be aware of:",
  CREATE_EVENT_WARNING_SCREEN_SHARING:
    "- Screen sharing is not currently supported on mobile or tablet (desktop only).",
  CREATE_EVENT_WARNING_BREAKOUT_ROOMS1:
    "- Breakout rooms are available but they are still being tested. <url>Click here</url> to learn more about breakout rooms.",
  CREATE_EVENT_WARNING_GOOGLE_CHROME:
    "- For the best experience we recommend that you install Google Chrome browser on your device.",
  CREATE_EVENT_WARNING_CONTACT:
    "If you notice anything that does not work as you expect or need assistance, kindly contact Member Support at <url>hello@heylady.io</url>.",
  CREATE_EVENT_PAGE_END_DATE: "End Date",
  CREATE_EVENT_PAGE_REPEATS_ON: "Repeats on",
  CREATE_EVENT_PAGE_REPEAT_ENDS: "Repeat ends",
  CREATE_EVENT_PAGE_IS_THIS_RECURRING_EVENT: "Is this a recurring event?",
  CREATE_EVENT_PAGE_PLEACE_SPECIFY_INFORMATION:
    "Please specify information about you event occurrence",
  CREATE_EVENT_PAGE_NO_THIS_IS_ONE_TIME: "No, this is one time event",
  CREATE_EVENT_PAGE_YES_THIS_EVENT_IS_RECURRING: "Yes, this event is recurring",
  CREATE_EVENT_PAGE_RECURRING_OPTIONS: "Recurring Options",
  CREATE_EVENT_PAGE_SPECIFY_RECURRING_OPTIONS_FOR_YOUR_EVENT:
    "Specify recurring options for your event",
  CREATE_EVENT_PAGE_REPEAT_OPTION: "Repeat Option",
  CREATE_EVENT_PAGE_CHOOSE_TIME_AND_DURATION: "Choose time and duration for your event",
  CREATE_EVENT_PAGE_START_DATE_ERROR: "Start date must be in the future",
  CREATE_EVENT_PAGE_TIME_RANGE_ERROR: "End time must be later than Start time",
  CREATE_EVENT_PAGE_CHOOSE_EVENT_TYPE: "Choose Event Type",
  CREATE_EVENT_PAGE_EVENT_DETAILS: "Event Details",
  CREATE_EVENT_PAGE_IN_PERSON_EVENT_DETAILS: "In Person Event Details",
  CREATE_EVENT_PAGE_EVENT_TOPIC: "What's the topic?",
  CREATE_EVENT_PAGE_EVENT_DETAILS_DESCRIPTION:
    "Keep your title short but descriptive. It might be a question or the key idea you want to explore together.",
  CREATE_EVENT_PAGE_EVENT_TAGS: "[Optional] Choose up to 3 tags that relate to your event",
  CREATE_EVENT_PAGE_ENGLISH_SKILLS_TAGS: "English skills",
  CREATE_EVENT_PAGE_RELEVANT_SELECTIONS: "Select up to three (3) that are relevant to your event",
  CREATE_EVENT_PAGE_PERSONAL_INTERESTS_TAGS: "Personal interests",
  CREATE_EVENT_PAGE_WHY_EVENT_TAGS:
    "'Tags' are key words or phrases that make it easier for us to show your event to the right people.",
  CREATE_EVENT_PAGE_TIME_DETAILS: "Time Details",
  CREATE_EVENT_PAGE_PARTICIPANTS: "Participants",
  CREATE_EVENT_PAGE_EVENT_COVER: "Event Cover",
  CREATE_EVENT_PAGE_CONFIRM_EVENT_DETAILS: "Confirm Event Details",
  CREATE_EVENT_PAGE_KIND_OF_EVENT: "What kind of event is this?",
  CREATE_EVENT_PAGE_IS_THIS_CASUAL_OR_IN_DEPTH:
    "Is this a casual conversation or perhaps something more in depth?",
  CREATE_EVENT_PAGE_CHOOSE_A_HEY_LADY_TOPIC: "Choose a Hey Lady Topic",
  CREATE_EVENT_PAGE_SET_MY_OWN_TOPIC: "Set my own topic",
  CREATE_EVENT_PAGE_EASY_GOING_CHAT: "This will be an easy going and open chat about the topic.",
  CREATE_EVENT_PAGE_IMPROVE_ENGLISH: "We will be helping each other to improve our English skills.",
  CREATE_EVENT_PAGE_WILL_BE_TAKING_TOPIC: "Will be taking this topic to a whole new level!",
  CREATE_EVENT_PAGE_PRESELECTED_EVENT_TOPIC: "Event Topic",
  CREATE_EVENT_PAGE_SELECT_EVENT_TOPIC: "Select an event topic",
  CREATE_EVENT_PAGE_SELECT_CURRENT_TOPIC: "{name} (Current Topic)",
  CREATE_EVENT_PAGE_SELECT_TOPIC_LIBRARY: "Topic Library",
  CREATE_EVENT_PAGE_THEMES_AVAILABLE_FOR_THIS_KIND:
    "The topics available for this kind of conversation are:",
  CREATE_EVENT_PAGE_SELECT_CONVERSATION_TOPIC: "Select a conversation topic",
  CREATE_EVENT_PAGE_AVAILABLE_FOR_TOPIC: "Available for this topic are:",
  CREATE_EVENT_PAGE_TOPIC_OVERVIEW: "Topic Overview",
  CREATE_EVENT_PAGE_GIVE_YOUR_EVENT_A_TITLE: "Give your event a title",
  CREATE_EVENT_PAGE_KEEP_YOUR_TITLE_SHORT_BUT_DESCRIPTIVE:
    "Keep your title short but descriptive. It might be a question or the key idea you want to explore together.",
  CREATE_EVENT_PAGE_ENTER_YOUR_TITLE: "Enter your title",
  CREATE_EVENT_PAGE_ENTER_YOUR_IDEA: "Enter your question/idea",
  CREATE_EVENT_PAGE_ENTER_YOUR_DESCRIPTION: "Enter your description",
  CREATE_EVENT_PAGE_ADD_THREE_IDEAS_TO_DISCUSS: "Add up to three (3) ideas to discuss",
  CREATE_EVENT_PAGE_ADD_THREE_IDEAS_TO_DISCUSS_OPTIONAL:
    "Add up to three (3) ideas to discuss (optional)",
  CREATE_EVENT_PAGE_HELP_OTHER_TO_SEE_WHAT_KINDS_TALKING:
    "Help others to see what kinds of things you might be talking about so they have a chance to prepare if they want to.",
  CREATE_EVENT_PAGE_CREATE_YOUR_OWN: "Create your own",
  CREATE_EVENT_PAGE_INTRO_VIDEO: "Intro Video",
  CREATE_EVENT_PAGE_INVITE_GUEST: "Invite Guest to your event",
  CREATE_EVENT_PAGE_ENTER_GUEST_NAME: "Enter your guest name",
  CREATE_EVENT_PAGE_MAKE_SURE_YOU_UPLOAD_SUPPORTING_FILES:
    "Please, make sure you upload supporting files extensions: .mp4, .mov, .mpeg-4",
  CREATE_EVENT_PAGE_UPLOAD_VIDEO: "Upload Video",
  CREATE_EVENT_PAGE_PLEASE_USE_HIGH_QUALITY_IMAGES:
    "Please use high quality left-oriented images because the left part of your cover will be displayed as your event preview.",
  CREATE_EVENT_PAGE_COVER_PHOTO: "Cover photo",
  CREATE_EVENT_PAGE_EVENT_TILE_AND_DESCRIPTION: "Event Title & Description",
  CREATE_EVENT_PAGE_IDEA_FOR_DISCUSSION: "Idea for Discussion",
  CREATE_EVENT_PAGE_EVENT_TITLE: "Event Title",
  CREATE_EVENT_PAGE_EVENT_DESCRIPTION: "Event Description",
  CREATE_EVENT_PAGE_PICK_A_TOPIC: "Pick a topic",
  CREATE_EVENT_PAGE_DUPLICATE_EVENT: "Duplicate Event - Please be sure you check all details",
  CARD_DETAILS_PAGE_FOUNDATIONS_PACKAGE: "Foundations Package",
  CARD_DETAILS_PAGE_FOUNDATIONS_MEMBER: "Foundations Member",
  CARD_DETAILS_PAGE_FIRST_MONTHS: "3 month introductory offer",
  CARD_DETAILS_PAGE_MONTHLY_SUBSCRIPTION: "Monthly subscription",
  CARD_DETAILS_PAGE_GET_INTRODUCED_TO_SPEAKING_PARTNERS: "Get introduced to speaking partners",
  CARD_DETAILS_PAGE_GUIDED_CONVERSATIONS_WITH_COACHES: "Guided conversations with coaches",
  CARD_DETAILS_PAGE_UNLIMITED_SPEAKING_PRACTICE: "Unlimited speaking practice time",
  CARD_DETAILS_PAGE_ACCESS_LEARNING: "Access to structured learning materials and workbooks.",
  CARD_DETAILS_PAGE_PLUS: "PLUS",
  CARD_DETAILS_PAGE_SPECIAL_LAUNCH_OFFER: "Special Launch offer!",
  CARD_DETAILS_PAGE_TICKET_TO_SPECIAL_EVENT: "1 x Ticket to Special Event with Emma",
  CARD_DETAILS_PAGE_ONE_MONTH_FREE_MEMBERSHIP: "1 month FREE membership*",
  CARD_DETAILS_PAGE_APPLIES_AFTER_COMPLETION: "*Applies after completion of Foundations Package.",
  CARD_DETAILS_PAGE_OFFER_MONEY_BACK:
    "We offer a 14 day money back guarantee. If you join and decide within 14 days that Hey Lady! is not for you we will refund 100% of your payment.",
  CARD_DETAILS_PAGE_CONDITIONS_OF_MEMBERSHIP: "Conditions of Membership",
  CARD_DETAILS_PAGE_TO_JOIN: "To join Hey Lady! you will need to meet the following conditions:",
  CARD_DETAILS_PAGE_INTERMEDIATE_LEVEL: "Intermediate level (B1) English or above",
  CARD_DETAILS_PAGE_OVER_18_YEARS_OLD: "You must be over 18 years old",
  CARD_DETAILS_PAGE_YOU_MUST_BE_A_WOMAN:
    "You must be a woman or identify as a woman to participate",
  CARD_DETAILS_PAGE_MONTH: "{number} month",
  CARD_DETAILS_PAGE_MONTHS: "{number} months",
  CARD_DETAILS_PAGE_USD_MONTH: "USD/ mth",
  CARD_DETAILS_PAGE_USD_MONTHS: "USD/ {number} months",
  CARD_DETAILS_PAGE_PERSONAL_PAYMENT_DETAILS: "Personal & payment details",
  CARD_DETAILS_PAGE_PROMOCODE: "Promocode:",
  CARD_DETAILS_PAGE_ENTER_PROMOCODE_HERE: "Enter promocode here",
  CARD_DETAILS_PAGE_AMOUNT_PER_MONTH: "{amount} USD/ {months} months",
  CARD_DETAILS_PAGE_YOUR_PASSWORD: "Your Password",
  CARD_DETAILS_PAGE_CONFIRMATION_EMAIL: "Please confirm your email",
  CARD_DETAILS_PAGE_CONFIRMATION_PASSWORD: "Please confirm your password",
  CARD_DETAILS_PAGE_CONDITIONS_AND_MEMBERSHIP: "I confirm that I meet the Conditions of Membership",
  CARD_DETAILS_THANK_YOU_MESSAGE: "Thank you for your purchase. We'll be in contact soon!",
  CARD_DETAILS_GO_TO_MAIN_SITE: "Go to main site",
  CHAT_MEMBERS: "Group: {total} members, {online} online",
  CHAT_MEMBERS_WO_GROUP: "{total} members, {online} online",
  CHAT_SEARCH_RESULTS: "Search results",
  CHAT_INVITED_PEOPLE: "Invited",
  CHAT_LOADING: "Searching...",
  CHAT_CREATE_CHAT_MSG: "Invite someone else for a group chat or continue and 'chat'",
  CHAT_CREATE_CHAT: "Chat",
  CHAT_CREATE_GROUP_CHAT: "Create a group chat",
  CHAT_FIND_PEOPLE: "Find people",
  CHAT_DELETE_CHAT: "Delete chat",
  CHAT_DELETE_GROUP_CHAT: "Delete group chat",
  CHAT_ADD_MEMBER: "Add member",
  CHAT_ADD_MEMBERS: "Add members",
  CHAT_ADD_NEW_MEMBERS: "Add new group members",
  CHAT_VIEW_OR_REMOVE_MEMBERS: "View/remove members",
  CHAT_REMOVE_MEMBERS: "Remove members",
  CHAT_REMOVE_MEMBER: "Remove",
  CHAT_REMOVE_MEMBER_HEADING:
    "Are you sure you want to remove <strong>{memberName}</strong> from <em>{channelName}</em>?",
  CHAT_MEMBERS_IN_GROUP: "Members in the channel:",
  CHAT_SELECTED: "Selected",
  CHAT_SELECTED_FOR_DELETION: "Selected to be removed:",
  CHAT_LEAVE_GROUP: "Leave group chat",
  CHAT_CHANGE_THE_NAME: "Change the name of this group",
  CHAT_RENAME_GROUP: "Rename group chat",
  CHAT_LEAVE_CHAT: "Leave chat",
  CHAT_LEAVE_CHANNEL_SHORT: "Leave",
  CHAT_LEAVE_CHANNEL_HEADER: "Are you sure you want to leave <em>{channelName}</em>?",
  CHAT_DELETE_CHANNEL_HEADER:
    "Are you sure you want to delete <em>{channelName}</em> for everyone?",
  CHAT_RENAME_CHANNEL: "Rename channel",
  CHAT_COMMUNITY_CHANNEL_DESC: "Share achievements and good news here!",
  CHAT_MANAGE_GROUP: "Manage group chat",
  CHAT_INFO: "Chat info",
  CHAT_MANAGEMENT_GROUP_MEMBERS: "Group members",
  CHAT_MANAGEMENT_CHAT_GROUP_NAME: "Chat group name",
  CHAT_MANAGEMENT_NEW_GROUP_NAME: "New group name",
  CHAT_START_VIDEO_CALL: "Start a video call",
  CHAT_START_CALL_DISABLED_TIER: "The other member doesn't have permission to attend a video call",
  CHAT_START: "Start",
  CHAT_JOIN: "Join",
  CHAT_ONGOING_VIDEO_CALL: "Ongoing video call",
  CHAT_CREATE_VIDEO_CALL: "Start a video call?",
  CHAT_CREATE_VIDEO_CALL_DESCRIPTION: "This will start a video call for your chat channel.",
  CHAT_START_CALL: "Start Call",
  CHAT_ENDED_VIDEO_CALL: "Sorry, this video call has already ended.",
  CHAT_TEMPLATE_WONDERFUL_TO_MEET_YOU: "Wonderful to meet you!",
  CHAT_TEMPLATE_SPEAKING_PARTNERS: "Would you like to be speaking partners?",
  CHAT_TEMPLATE_CATCH_UP: "Are you free to catch up soon?",
  CHAT_TEMPLATE_ENJOYED_CONVERSATION: "I enjoyed our conversation!",
  CHAT_START_CONVERSATION: "Start your conversation",
  CHAT_START_CONVERSATION_DESCRIPTION:
    "You can either choose a pre-defined message to send or type your own",
  PAYMENT_SUCCESSFUL_WELCOME_ON_BOARD: "Welcome on Board!",
  PAYMENT_SUCCESSFUL_WELCOME: "Welcome!",
  PAYMENT_SUCCESSFUL_HAPPY_THAR_YOU_ARE_HERE:
    "We're happy that you're here! After <url>completing your profile</url> and attending the <url2>New Member Meetup</url2>, you will unlock a lot of community features such as:",
  PAYMENT_SUCCESSFUL_COMPLETING_YOUR_PROFILE: "completing your profile",
  PAYMENT_SUCCESSFUL_NEW_MEMBER_MEETUP: '"New Member Meetup"',
  PAYMENT_SUCCESSFUL_TOPICS_BASED_GROUP_EVENTS: "Topic based group events",
  PAYMENT_SUCCESSFUL_CHATS_WITH_COMMUNITY: "Chats with community",
  PAYMENT_SUCCESSFUL_CONVERSATIONS_ONE_TO_ONE: "Conversations 1 to 1",
  PAYMENT_SUCCESSFUL_LEARNING_MATERIALS: "Learning materials",
  PAYMENT_SUCCESSFUL_CLASSES_WITH_TEACHERS: "Classes with teachers",
  PAYMENT_SUCCESSFUL_AFTER_THE_FIRST_THREE_MONTHS:
    "After the first 3 months your tariff plan will automatically changed to <url>$29/month</url>, and more features will be unlocked for you.",
  PAYMENT_SUCCESSFUL_USD_MONTH: "{usd}/month",
  PAYMENT_SUCCESSFUL_GO_TO_HOME_PAGE: "Go to Home Page",
  PAYMENT_SUCCESSFUL_REQUEST_REFUND:
    "If you don't feel like a part of our community within the first 30 days, you can request a refund.",
  PURCHASE_MEMBERSHIP_PRODUCT_DESCRIPTION: "Product Description",
  PURCHASE_MEMBERSHIP_DESCRIPTION_TITLE: "Hey Lady! Foundations",
  PURCHASE_MEMBERSHIP_DESCRIPTION:
    " Access to Hey Lady! platform plus invitations to join guided conversations hosted by our qualified English teachers. Your member fees will be charged to your card each month. You can update your plan at any time in your Account Settings.",
  PURCHASE_MEMBERSHIP_PRICE: "Price",
  PURCHASE_MEMBERSHIP_TERMS_AND_CONDITIONS: "I agree to all <url>Terms & Conditions</url>",
  PURCHASE_MEMBERSHIP_NOW_A_MEMBER: "You’re officially a member!",
  PURCHASE_MEMBERSHIP_SUCCESSFUL: "Payment successful!",
  PURCHASE_MEMBERSHIP_THANK_YOU: "Thank you for supporting Hey Lady!",
  PURCHASE_MEMBERSHIP_COPY: "A copy of your receipt has been sent to {email}",
  PURCHASE_MEMBERSHIP_YOU_CAN_UPDATE_YOUR_PAYMENT:
    "You can update your payment settings at any time.",
  PAYMENT_DETAILS_PAGE: "Payment details",
  PAYMENT_DETAILS_PAGE_BILLING_AND_PAYMENTS: "Billing and payments",
  PAYMENT_DETAILS_PAGE_YOU_CAN_UPDATE:
    "You can update your card details any time via our secure portal.",
  PAYMENT_DETAILS_PAGE_REMOVE_CARD: "Remove Card",
  PAYMENT_DETAILS_PAGE_ADD_CARD: "Add Card",
  PAYMENT_DETAILS_PAGE_ADD_NEW_CARD:
    "Don't forget to add a new card in order not to lose your membership benefits",
  PAYMENT_DETAILS_PAGE_YOUR_CREDIT_CARD_HAS_BEEN_EXPIRED:
    "Your credit card has been expired. Please try to add another card.",
  PAYMENT_DETAILS_PAGE_UPDATE: "Update",
  PAYMENT_DETAILS_PAGE_CURRENT_MEMBERSHIP_PLAN: "Current membership plan",
  PAYMENT_DETAILS_PAGE_YOU_CAN_SWITCH_YOUR_MEMBERSHIP:
    "You can switch your membership plan in <url>Membership Settings</url>",
  PAYMENT_DETAILS_PAGE_FOUNDATIONS_MONTHLY: "Foundations Monthly",
  PAYMENT_DETAILS_PAGE_FOUNDATIONS_PACKAGE: "Foundations Package",
  PAYMENT_DETAILS_PAGE_RISE_ANNUAL: "Rise Yearly",
  PAYMENT_DETAILS_PAGE_RISE_MONTHLY: "Rise Monthly",
  PAYMENT_DETAILS_PAGE_ANNUAL_PLAN: "Yearly Plan",
  PAYMENT_DETAILS_PAGE_THREE_MONTHS_PLAN: "3 Month Plan",
  PAYMENT_DETAILS_PAGE_SUPPORTER_PLAN: "Friends Plan",
  PAYMENT_DETAILS_PAGE_THREE_MONTHS_ONLY:
    "Enjoy three(3) months access to everything we offer in the Hey Lady! platform and community.",
  PAYMENT_DETAILS_PAGE_QUARTERLY_PLAN: "Quarterly Plan",
  PAYMENT_DETAILS_PAGE_MONTHLY_PLAN: "Monthly Plan",
  PAYMENT_DETAILS_PAGE_FREE_PLAN: "Trial Plan",
  PAYMENT_DETAILS_PAGE_SUPPORTER: "Friend of Hey Lady!",
  PAYMENT_DETAILS_PAGE_PRICE: "Price",
  PAYMENT_DETAILS_PAGE_NEXT_PAYMENT: "Next Payment:",
  PAYMENT_DETAILS_PAGE_CANCELS_ON: "Cancels on:",
  PAYMENT_DETAILS_PAGE_PAUSE: "Pause",
  PAYMENT_DETAILS_PAGE_RENEW_PLAN: "Reactivate membership",
  PAYMENT_DETAILS_NEED_TO_MAKE_CHANGES: "Need to make changes",
  PAYMENT_DETAILS_PAUSE_TWO_WEEKS: "Pause 2 weeks",
  PAYMENT_DETAILS_DELETE_MY_ACCOUNT: "Delete Account",
  PAYMENT_DETAILS_DELETING_YOUR_ACCOUNT: "Deleting your account is final and cannot be undone.",
  PAYMENT_DETAILS_DELETE_ONCE_YOU_SUBMIT:
    "Once you submit this request to delete your account, you will be logged out and no longer able to access the platform. Your subscription will be cancelled and all personal details associated with your account will be removed, except those legally required for reporting purposes.",
  PAYMENT_DETAILS_PAGE_CANCEL_MEMBERSHIP: "Cancel membership",
  PAYMENT_DETAILS_WERE_GONNA_MISS_YOU: "We’re gonna miss you!",
  PAYMENT_DETAILS_REASONS_FOR_LEAVING: "Can you tell us why you’re leaving?",
  PAYMENT_DETAILS_CONFIRM:
    "Click the button below to confirm cancellation. You will still have access to the community and platform until the end of your trial.",
  PAYMENT_DETAILS_TRIAL_ENDS: "Your trial ends on {date}.",
  PAYMENT_DETAILS_MEMBERSHIP_ENDS: "Your membership ends on {date}.",
  PAYMENT_DETAILS_FEEDBACK:
    "We would appreciate your feedback so we can learn from your experience.",
  PAYMENT_DETAILS_I_AM_LEAVING: "I’m leaving the community because...",
  PAYMENT_DETAILS_REACTIVATE: "Reactivate",
  PAYMENT_DETAILS_PAUSE_TWO_WEEKS_DESCRIPTION:
    "You can pause your membership for 2 weeks, up to two times per year. We will not charge your card while you are away, and your next billing date will be adjusted once you return.",
  PAYMENT_DETAILS_CANCEL_SUBSCRIPTION_DESCRIPTION:
    "This will cancel your membership but keep your profile and history of events. You will still be able to access the platform until the end of your current billing period. You can return to reactivate your subscription at any time.",
  PAYMENT_DETAILS_DELETE_MY_ACCOUNT_DESCRIPTION:
    "This will cancel your membership immediately and delete your account including your profile and history. Deleting your account is instant and cannot be undone.",
  PAYMENT_DETAILS_REACTIVATE_DESCRIPTION:
    "If you change your mind about cancelling your membership and decide you would like to continue, clicking the Reactivate Membership button will reverse your decision. Your membership will continue at the end of the current trial or billing period.",
  PAYMENT_DETAILS_PAGE_PAUSE_YOUR_MEMBERSHIP: "Pause your membership?",
  PAYMENT_DETAILS_PAGE_ONE_PAUSE_THIS_YEAR: "one pause left this year",
  PAYMENT_DETAILS_PAGE_YOU_CAN_PAUSE:
    "You can pause your Hey Lady! membership for up to two (2) weeks, twice per year (total of 4 weeks per year).",
  PAYMENT_DETAILS_PAGE_THE_PAUSE_PERIOD:
    "The pause period begins from the day that you click the Pause button below. Once you pause, you will be logged out and we will automatically start your membership after 2 weeks.",
  PAYMENT_DETAILS_MEMBERSHIP_PAUSED: "Your membership is paused",
  PAYMENT_DETAILS_YOU_CAN_RETURN:
    "Your subscription will resume automatically on <bold>{date}</bold>. You can return to activate your subscription earlier if you need to.",
  PAYMENT_DETAILS_PAGE_REQUEST_REFUND: "Request Refund",
  PAYMENT_DETAILS_PAGE_IF_YOU_DONT_FEEL_PART_OF_COMMUNITY:
    "*If you don't feel Hey Lady! is right for you within the first 14 days, you can request a refund. {days} days left",
  PAYMENT_DETAILS_PAGE_WANT_TO_CANCEL: "Are you sure you want to cancel your membership?",
  PAYMENT_DETAILS_PAGE_AFTER_CANCELLING:
    "After cancelling your membership, you will still be able to access the platform until the end of your billing cycle.",
  PAYMENT_DETAILS_PAGE_KEEP_MEMBERSHIP: "Keep membership",
  PAYMENT_DETAILS_PAGE_ARE_YOU_SURE:
    "Are you sure you want to request a refund and cancel your membership?",
  PAYMENT_DETAILS_PAGE_LOSE_MEMBERSHIP:
    "Once your membership is cancelled and we issue a refund you will immediately lose access to our platform.",
  PAYMENT_DETAILS_PAGE_PURCHASE_HISTORY: "Purchase history",
  PAYMENT_DETAILS_PAGE_HERE_YOU_CAN_SEE: "Here you can see all your purchases",
  PAYMENT_DETAILS_PAGE_DATE: "Date",
  PAYMENT_DETAILS_PAGE_INVOICE_NO: "Invoice No.",
  PAYMENT_DETAILS_PAGE_CARD: "Card",
  PAYMENT_DETAILS_PAGE_DOWNLOAD: "Download",
  PAYMENT_DETAILS_PAGE_STATUS: "Status",
  PAYMENT_DETAILS_PAGE_REQUEST_SENT: "We’ve received your request!",
  PAYMENT_DETAILS_PAGE_REQUEST_RECEIVED:
    "We will send confirmation of your request to cancel your membership via email to {email}",
  PAYMENT_DETAILS_PAGE_NOTE:
    "Note: It will take a few minutes for the changes to be processed in our system.If you have any issues, contact support at <url>hello@heylady.io</url>.",
  PAYMENT_DETAILS_BEFORE_YOU_DELETE: "Before you delete, can we help you?",
  PAYMENT_DETAILS_BEFORE_YOU_CANCEL: "Before you cancel, can we help you?",
  PAYMENT_DETAILS_CHOOSE_ANY_OPTION: "(Choose any option below.)",
  PAYMENT_DETAILS_PAGE_NEED_MORE_TIME: "Do you need more time?",
  PAYMENT_DETAILS_PAGE_NEED_MORE_TIME_DESCRIPTION: "We can extend your trial by 7 days.",
  PAYMENT_DETAILS_PAGE_HELP_FINDING_PEOPLE: "Do you need help finding people to practise with?",
  PAYMENT_DETAILS_PAGE_HELP_FINDING_PEOPLE_DESCRIPTION:
    "We’d be happy to make personal introductions.",
  PAYMENT_DETAILS_PAGE_HELP_FINDING_EVENTS:
    "Need help finding events for your level or availability?",
  PAYMENT_DETAILS_PAGE_HELP_FINDING_EVENTS_DESCRIPTION:
    "We can help you to find or create events to join.",
  PAYMENT_DETAILS_PAGE_ASSISTANCE: "Would you like assistance in how to use the platform? ",
  PAYMENT_DETAILS_PAGE_ASSISTANCE_DESCRIPTION: "We can meet via video and show you step-by-step.",
  PAYMENT_DETAILS_PAGE_SOMETHING_ELSE: "Is there something else we could do to help you?",
  PAYMENT_DETAILS_PAGE_SOMETHING_ELSE_DESCRIPTION: "Let us know what you need.",
  PAYMENT_DETAILS_PAGE_CONSIDER: "I would stay if..",
  PAYMENT_DETAILS_PAGE_SEND_REQUEST: "Send request",
  PAYMENT_DETAILS_PAGE_NO_I_WANT_TO_DELETE: "No, I want to delete",
  PAYMENT_DETAILS_PAGE_NO_I_WANT_TO_CANCEL: "No, I want to cancel",
  PAYMENT_DETAILS_PAGE_THANK_YOU: "Thank you",
  PAYMENT_DETAILS_PAGE_WE_HAVE_SENT_YOUR_REQUEST:
    "We have sent your request to our Community Manager who will contact you as soon as she can to help.",
  PAYMENT_DETAILS_PAGE_WE_LL_BE_IN_TOUCH: "We’ll be in touch ASAP!",
  PAYMENT_DETAILS_PAGE_PLAN: "Plan",
  PAYMENT_DETAILS_PAGE_PLAN_IS_DIFFERENT:
    "The price of this plan is now {planAmount} USD. We have decided that we don't want to charge existing users additional costs.",
  REDEEM_PAGE_REDEEM_GIFT: "Claim your Gift Card!",
  REDEEM_PAGE_COMPLETE_DETAILS: "Please complete the details below to claim your gift.",
  REDEEM_PAGE_GIFT_CODE: "Enter code",
  REDEEM_PAGE_EMAIL: "Email (you will use this to log in)",
  REDEEM_PAGE_CREATE_ACCOUNT: "Create my Hey Lady! account",
  REDEEM_PAGE_TERMS_AND_CONDITIONS: "By registering I agree to all <url>Terms & Conditions</url>",
  REDEEM_PAGE_CONFIRM_AGE_AND_GENDER: "I confirm that I am a woman aged 18 years and above",
  REDEEM_PAGE_WELCOME: "Welcome!",
  REDEEM_PAGE_WELCOME_BACK: "Welcome back!",
  REDEEM_PAGE_JOINING_OUR_COMMUNITY: "We're so excited that you're joining our community!",
  REDEEM_PAGE_CONTINUE_YOUR_JOURNEY:
    "We're so excited to see you continue your journey in our community!",
  REDEEM_PAGE_EMAIL_WAS_SENT: "An email has been sent to {email}.",
  REDEEM_PAGE_MEMBERSHIP_UPDATED: "Your membership has now been updated.",
  REDEEM_PAGE_LOG_IN_NOW: "Log in now",
  REDEEM_PAGE_MISSING_CODE: "Wrong gift code!",
  REDEEM_PAGE_ALREADY_REDEEMED: "This code has been already claimed!",
  REDEEM_PAGE_EXISTING_USER: "User already exists!",
  REDEEM_PAGE_BACKEND_FAIL: "Backend failed!",
  MEMBERZINE_WRITE_YOUR_OWN_STORY_TO_SHARE: "Write your own story to share",
  MEMBERZINE_WRITE_ARTICLE: "Write Article",
  MEMBERZINE_FROM_PEOPLE_YOU_FOLLOW: "From people you follow",
  MEMBERZINE_NEW_ARTICLES: "New articles",
  MEMBERZINE_BACK_TO_MAIN_PAGE: "Back to main page",
  MEMBERZINE_YOUR_PUBLISHED_ARTICLES: "Your published articles",
  MEMBERZINE_YOUR_DRAFT_ARTICLES: "Your draft articles",
  MEMBERZINE_ALL_ARTICLES: "All Articles",
  MEMBERZINE_MY_ARTICLES: "My articles",
  MEMBERZINE_MY_DRAFTS: "My drafts",
  MEMBERZINE_CATEGORY_ANY: "Any category",
  MEMBERZINE_CATEGORY_FEATURED: "Featured",
  MEMBERZINE_CATEGORY_POPULAR: "Most popular",
  MEMBERZINE_CATEGORY_RECOMMENDED: "Recommended",
  MEMBERZINE_CATEGORY_TOPICS: "Hey Lady! Topics",
  MEMBERZINE_CATEGORY_INTERESTS: "Interests",
  MEMBERZINE_CATEGORY_JOURNEY: "English Journeys",
  MEMBERZINE_CATEGORY_LIKED: "Liked by me",
  MEMBERZINE_AUTHOR_ANY: "Any author",
  MEMBERZINE_AUTHOR_MEMBERS: "By members",
  MEMBERZINE_AUTHOR_FOLLOW: "By people I follow",
  MEMBERZINE_AUTHOR_HEYLADY: "By Hey Lady! Team",
  MEMBERZINE_LIKE_IT: "Like it!",
  MEMBERZINE_EDIT_ARTICLE: "Edit Article",
  MEMBERZINE_EDIT_DRAFT: "Edit Draft",
  MEMBERZINE_LIKE_THE_ARTICLE: "Like the article? Cheer the author!",
  MEMBERZINE_ARTICLE_DELETED: "Article deleted",
  MEMBERZINE_REPORT_ARTICLE: "Report article",
  MEMBERZINE_CREATE_PAGE_WRITE_YOUR_ARTICLE: "Write Your Article",
  MEMBERZINE_CREATE_PAGE_CHOOSE_THE_COVER_IMAGE: "Choose the cover image",
  MEMBERZINE_CREATE_PAGE_UPLOAD_YOUR_COVER_HERE:
    "Upload your cover here. Please, make sure you upload supported files extensions: *.jpg, *.png",
  MEMBERZINE_CREATE_PAGE_GIVE_YOUR_ARTICLE_A_TITLE: "Give your article a title",
  MEMBERZINE_CREATE_PAGE_KEEP_YOUR_TITLE_SHORT:
    "Keep your title short but descriptive. It could be a question or the key idea you want to explore",
  MEMBERZINE_CREATE_PAGE_SHARE_YOUR_STORY: "Share your story",
  MEMBERZINE_CREATE_PAGE_KEEP_IN_MIND_WE_POST:
    "Please, keep in mind we post articles only with 300 words and above.",
  MEMBERZINE_CREATE_PAGE_SCHEDULE_ARTICLE: "Schedule Article",
  MEMBERZINE_CREATE_PAGE_TITLE_ARTICLE_TITLE: "Article Title",
  MEMBERZINE_CREATE_PAGE_TITLE_CHARACTERS_LEFT: "{chars} left",
  MEMBERZINE_CREATE_PAGE_USE_HIGH_QUALITY_IMAGES:
    "Please use high quality images that represent your article theme",
  MEMBERZINE_CREATE_PAGE_UPLOAD_IMAGE: "Upload Image",
  MEMBERZINE_CREATE_PAGE_ADD_ARTICLE_COVER: "Add Article Cover",
  MEMBERZINE_CREATE_PAGE_MOVE_AND_ZOOM:
    "Move and zoom (+/-) your image in the frame to create the perfect cover!",
  MEMBERZINE_CREATE_PAGE_SAVE_COVER: "Save Cover",
  MEMBERZINE_CREATE_PAGE_PLEASE_UPLOAD: "Please upload one file at a time.",
  MEMBERZINE_CREATE_PAGE_DRAG_AND_DROP: 'Drag and drop or click "Upload" to choose a file.',
  MEMBERZINE_CREATE_PAGE_ACCEPTED_FILES_TYPES: "Accepted file types are: .pdf, .jpg, .doc, .xls",
  MEMBERZINE_CREATE_PAGE_CHOOSE_A_CATEGORY:
    "Choose a category so we can help people to find your article more easily.",
  MEMBERZINE_CREATE_PAGE_WOULD_YOU_LIKE_TO_ATTACH_ANY_FILES_TO_YOUR_ARTICLE:
    "Would you like to attach any files to your article?",
  MEMBERZINE_CREATE_PAGE_PLEASE_SELECT_THREE_SUBJECTS:
    "Please select up to three (3) subjects relevant to your article",
  MEMBERZINE_CREATE_PAGE_PLEASE_SELECT_RELEVANT_THEME: "Please select the relevant theme",
  MEMBERZINE_YES_PLEASE: "Yes, please!",
  MEMBERZINE_NO_THANKS: "No, thanks!",
  MEMBERZINE_CREATE_PAGE_UPLOAD: "Upload",
  MEMBERZINE_CREATE_PAGE_DO_YOU_WANT_TO_SAVE: "Do you want to save your work?",
  MEMBERZINE_CREATE_PAGE_UNSAVED_CHANGES: "Unsaved Changes?",
  MEMBERZINE_CREATE_PAGE_SAVE_YOUR_ARTICLE:
    "Save your article as a draft if you plan to come back to finish it later.",
  MEMBERZINE_CREATE_PAGE_DISCARD_CHANGES:
    "Are you sure you want to discard the changes that you made to this article?",
  MEMBERZINE_CREATE_PAGE_EXIT_AND_DELETE: "Delete and exit",
  MEMBERZINE_CREATE_PAGE_DISCARD: "Discard",
  MEMBERZINE_CREATE_PAGE_SAVE_AS_DRAFT: "Save as draft",
  MEMBERZINE_CREATE_PAGE_WHAT_IS_YOUR_ARTICLE_ABOUT: "What is your article about?",
  MEMBERZINE_CREATE_PAGE_SELECT_UP_TO_5_TAGS:
    "Select up to 5 tags to make your article easier to find.",
  MEMBERZINE_CREATE_PAGE_HEY_LADY_TOPIC: "Hey Lady! Topic",
  MEMBERZINE_CREATE_PAGE_MY_PERSONAL_INTERESTS: "My personal interests",
  MEMBERZINE_CREATE_PAGE_MY_ENGLISH_JOURNEY: "My English journey",
  MEMBERZINE_CREATE_PAGE_SAVED_DRAFT: "Your draft has been saved!",
  MEMBERZINE_CREATE_PAGE_SAVED_ARTICLE: "Your article has been saved!",
  MEMBERZINE_CREATE_PAGE_SAFELY_SAVED:
    "Your hard work is safely saved. You can continue editing or come back later to keep working on it.",
  MEMBERZINE_CREATE_PAGE_EXIT_EDITOR: "Exit the editor",
  MEMBERZINE_CREATE_PAGE_CONTINUE_EDITING: "Continue editing",
  MEMBERZINE_CREATE_PAGE_PUBLISHED_ARTICLE: "Article published!",
  MEMBERZINE_CREATE_PAGE_WELL_DONE:
    "Well done! Your article has been published for the community to enjoy!",
  MEMBERZINE_CREATE_PAGE_MAKE_SURE:
    " Make sure you check back to respond to reader comments. You can also return and edit your article if you need to.",
  MEMBERZINE_SCHEDULE_PAGE_SCHEDULE_YOUR_ARTICLE: "Schedule your article",
  MEMBERZINE_SCHEDULE_PAGE_SPECIFY_TIME_DETAILS: "Specify time details",
  MEMBERZINE_SCHEDULE_PAGE_CHOOSE_TIME_WHEN_YOUR_ARTICLE_CAN_BE_PUBLISHED:
    "Choose time when your article can be published",
  MEMBERZINE_JANUARY: "January edition",
  MEMBERZINE_FEBRUARY: "February edition",
  MEMBERZINE_MARCH: "March edition",
  MEMBERZINE_APRIL: "April edition",
  MEMBERZINE_MAY: "May edition",
  MEMBERZINE_JUNE: "June edition",
  MEMBERZINE_JULY: "July edition",
  MEMBERZINE_AUGUST: "August edition",
  MEMBERZINE_SEPTEMBER: "September edition",
  MEMBERZINE_OCTOBER: "October edition",
  MEMBERZINE_NOVEMBER: "November edition",
  MEMBERZINE_DECEMBER: "December edition",
  MEMBERZINE_CREATE_ADD_HALF_WIDTH_IMAGE: "Add half width image",
  MEMBERZINE_CREATE_ADD_FULL_WIDTH_IMAGE: "Add full width image",
  MEMBERZINE_CREATE_ADD_VIDEO: "Add video",
  MEMBERZINE_CREATE_TEXT_SEPARATOR: "Text separator",
  MEMBERZINE_CREATE_PAGE_PASTE_LINK: "Paste or type a video link",
  HEAVY_LOAD_PAGE_OOPS: "Oops! Hey Lady! is experiencing a few technical problems.",
  HEAVY_LOAD_PAGE_WE_RE_WORKING_ON_IT: "We're working on it and will be back very soon!",
  HEAVY_LOAD_PAGE_TRY_AGAIN: "Try again",
  RESUBSCRIBE_BACK: "You're back!",
  RESUBSCRIBE_DESCRIPTION:
    "To reactivate your Hey Lady! Membership, please choose a plan and confirm your payment method.",
  RESUBSCRIBE_CONFIRM: "Got it!",
  REQUEST_PERMISSION_TITLE: "Allow Notifications?",
  REQUEST_PERMISSION_DESCRIPTION:
    "Do you want to allow this website to send notifications? You will receive notifications when other users send you messages or tag you in messages in the chat.",
  REQUEST_PERMISSION_ALLOW: "Allow",
  REQUEST_PERMISSION_GRANTED:
    "Thanks for allowing notifications! You can always manage your notification settings in your browser's preferences.",
  ERROR_HEADING: "Oops! Something went wrong",
  ERROR_DESCRIPTION:
    "We're sorry, but it looks like something unexpected happened. Please try reloading the page by clicking the button below, and if the issue persists, please <contact>contact our support team for assistance</contact>.",
  ERROR_RELOAD: "Reload page",
  ERROR_GO_HOME: "Home page",
  ERROR_PATIENCE: "Thank you for your patience and understanding.",
  ERROR_UPDATE_HEADING: "New update available",
  ERROR_UPDATE_DESCRIPTION:
    "We have made some improvements to make your experience even better.{br}All you need to do is refresh your page.",
  ERROR_PAGE_NEED_HELP: "Need help? Contact {mail}",
  ERROR_PAGE_HEADING: "Oh dear! Something is wrong",
  ERROR_PAGE_GO_HOME: "Back to Home Page",
  ERROR_PAGE_SEE_PLANS: "See plans",
  NOTIFICATION_COMPLETED_PROFILE: "Your Profile is <o>{percentage}%</o> complete!",
  NOTIFICATION_COMPLETED_PROFILE_DESCRIPTION:
    "Complete your profile to reach 100% and earn yourself the “Perfect" + " Profile” badge today!",
  NOTIFICATION_UPDATE_PROFILE_NOW: "Update Profile Now",
  NOTIFICATION_MAYBE_LATER: "Maybe Later",
  NOTIFICATION_NEED_TO_COMPLETE_PROFILE: "This is the information you’ll need to complete:",
  NOTIFICATION_IS_THIS_STILL_CORRECT: "{name}, is this still correct?",
  NOTIFICATION_THIS_WILL_HELP_US:
    "This will help us to find the best speaking partners for you who can practice" +
    " English at the same time.",
  NOTIFICATION_IS_THIS_YOUR_AVAILABILITY: "Is this your availability?",
  NOTIFICATION_ARE_YOU_OPEN: "Are you open to new speaking partners?",
  NOTIFICATION_SAVE_DETAILS: "Save Details",
  NOTIFICATION_ACCEPTED_INVITATION: "{name} accepted your invite!",
  NOTIFICATION_JUST_JOINED:
    "Just a quick note to let you know that your friend <b>{name}</b> just joined {logo}" +
    " using your invitation!",
  NOTIFICATION_MAKE_HER_FEEL_WELCOME: "Let’s make her feel welcome! 🤗",
  NOTIFICATION_SEND_MESSAGE: "Send message",
  NOTIFICATION_WE_HAVE_SENT:
    "We have sent an invitation email to your friend with a special link to join Hey Lady! and enjoy one month for free!\n",
  NOTIFICATION_INVITE_ANOTHER_FRIEND: "Invite another friend",
  NOTIFICATION_SIGNED_UP: "{name} just signed up!",
  NOTIFICATION_GREAT_NEWS:
    "Great news! 💖 Your friend has decided to join {logo} as a paying member!",
  VERIFICATION_PAGE_GET_VERIFIED: "Get verified to unlock access to all features",
  VERIFICATION_PAGE_KEEP_COMMUNITY_SAFE:
    "To keep our community safe, <orange>we do not allow anyone to create or" +
    " join video calls or chat with our members until they have been verified</orange> by our team.",
  VERIFICATION_PAGE_GETTING_VERIFIED:
    "Getting verified is easy. Just follow the steps on the next page to record a" +
    " short video. We will check your video and unlock all features within 24 hours.",
  VERIFICATION_PAGE_BACK_TO_HOME: "Back to Home",
  RECORD_VIDEO_PAGE_RECORD_VIDEO: "Record a short video",
  RECORD_VIDEO_PAGE_WATCH_EMMA: "1. Watch Emma’s video",
  RECORD_VIDEO_PAGE_ANSWER_3_QUESTIONS: "2. Record your answer to 3 questions",
  RECORD_VIDEO_PAGE_NAME: "a. What is your name?",
  RECORD_VIDEO_PAGE_WHERE_LIVE: "b. Where do you live?",
  RECORD_VIDEO_PAGE_WHY_JOIN: "c. Why did you join {HL}?",
  RECORD_VIDEO_PAGE_SEND: "3. Click “Yes” to send your answers",
  RECORD_VIDEO_PAGE_VERIFICATION:
    "Your video is for verification purposes only. We will never share your" +
    " information, images or videos without your permission.",
  RECORD_VIDEO_PAGE_DONE: "I'm done",
  ONBOARDING_QUIZ_TAKE_THE_QUIZ: "Take the quiz!",
  ONBOARDING_QUIZ_INTRO_TITLE: "Show us your understanding of the {HL} Way",
  ONBOARDING_QUIZ_INTRO_DESCRIPTION: "Answer all 10 questions to earn your “{HL} Way” badge!",
  ONBOARDING_QUIZ_HEY_LADY_QUIZ: "{HL} Quiz",
  ONBOARDING_QUIZ_QUESTION_NUM: "Question {current}/{total}",
  ONBOARDING_QUIZ_QUESTION_1: "Which of these best describes {HL} accurately?",
  ONBOARDING_QUIZ_QUESTION_1_OPTION_1: "A new type of language school for women",
  ONBOARDING_QUIZ_QUESTION_1_OPTION_2: "An English speaking community for women",
  ONBOARDING_QUIZ_QUESTION_1_OPTION_3: "A place to practice with native speakers",
  ONBOARDING_QUIZ_QUESTION_1_OPTION_4: "An English course created for women",
  ONBOARDING_QUIZ_QUESTION_1_HINT:
    "{HL} is not a language school or a course, but a safe, supportive space to meet and practise speaking English.",
  ONBOARDING_QUIZ_QUESTION_2: "What are the {HL} community values?",
  ONBOARDING_QUIZ_QUESTION_2_OPTION_1: "Courage, Confidence, Celebration & Happiness",
  ONBOARDING_QUIZ_QUESTION_2_OPTION_2: "Command, Coerce, Commit & Conquer",
  ONBOARDING_QUIZ_QUESTION_2_OPTION_3: "Connection, Collaboration, Contribution, Respect & Joy",
  ONBOARDING_QUIZ_QUESTION_2_OPTION_4: "Peace, Love, Creativity & Harmony",
  ONBOARDING_QUIZ_QUESTION_2_HINT:
    "We meet and work together respectfully to help each other succeed and have fun!",
  ONBOARDING_QUIZ_QUESTION_3: "What is the simple formula to build fluency and confidence?",
  ONBOARDING_QUIZ_QUESTION_3_OPTION_1: "Learn a new word from the English dictionary every day",
  ONBOARDING_QUIZ_QUESTION_3_OPTION_2: "Pay for as many courses about fluency as possible",
  ONBOARDING_QUIZ_QUESTION_3_OPTION_3:
    "Regular practice in real conversations with different people about different topics",
  ONBOARDING_QUIZ_QUESTION_3_OPTION_4: "Watch YouTube videos and speak out loud in every lesson",
  ONBOARDING_QUIZ_QUESTION_3_HINT:
    "Fluency and confidence develop naturally through consistent practice in a variety of situations.",
  ONBOARDING_QUIZ_QUESTION_4: "Why is {HL} only for women?",
  ONBOARDING_QUIZ_QUESTION_4_OPTION_1:
    "Because we can focus on the unique needs and interests of women",
  ONBOARDING_QUIZ_QUESTION_4_OPTION_2:
    "Because it creates a space that is inclusive of all cultures",
  ONBOARDING_QUIZ_QUESTION_4_OPTION_3: "Because the world needs more female voices to be heard",
  ONBOARDING_QUIZ_QUESTION_4_OPTION_4: "All of the above",
  ONBOARDING_QUIZ_QUESTION_4_HINT: "There are many great reasons {HL} is only for women.",
  ONBOARDING_QUIZ_QUESTION_5: "What is an “event” in {HL}?",
  ONBOARDING_QUIZ_QUESTION_5_OPTION_1: "Something that you pay for if you want to attend",
  ONBOARDING_QUIZ_QUESTION_5_OPTION_2: "A conversation or activity happening at a scheduled time",
  ONBOARDING_QUIZ_QUESTION_5_OPTION_3: "A class with a teacher where attendance is mandatory",
  ONBOARDING_QUIZ_QUESTION_5_OPTION_4: "A kind of contest where people compete against each other",
  ONBOARDING_QUIZ_QUESTION_5_HINT:
    "Our events are free and flexible and it’s always up to you if you would like to attend.",
  ONBOARDING_QUIZ_QUESTION_6: "What timezone are events displayed in the platform?",
  ONBOARDING_QUIZ_QUESTION_6_OPTION_1: "UTC (Coordinated Universal Time)",
  ONBOARDING_QUIZ_QUESTION_6_OPTION_2: "AEST (Australian Eastern Standard Time)",
  ONBOARDING_QUIZ_QUESTION_6_OPTION_3: "Your timezone",
  ONBOARDING_QUIZ_QUESTION_6_OPTION_4: "I don’t know, I never really paid attention to time zones",
  ONBOARDING_QUIZ_QUESTION_6_HINT:
    "We make it easy for you to know what time our events are happening.",
  ONBOARDING_QUIZ_QUESTION_7: "What’s the best way to find a great speaking partner?",
  ONBOARDING_QUIZ_QUESTION_7_OPTION_1:
    "Regularly attending different events and talking to different people",
  ONBOARDING_QUIZ_QUESTION_7_OPTION_2:
    "Writing a message to everyone in the chat and asking for one",
  ONBOARDING_QUIZ_QUESTION_7_OPTION_3: "Asking one of the teachers to find one for you",
  ONBOARDING_QUIZ_QUESTION_7_OPTION_4: "Waiting patiently until someone contacts you",
  ONBOARDING_QUIZ_QUESTION_7_HINT:
    "The more people you meet, the sooner you’ll find “your people”!",
  ONBOARDING_QUIZ_QUESTION_8: "Who is able to create an event in the {HL} platform?",
  ONBOARDING_QUIZ_QUESTION_8_OPTION_1: "Only the {HL} Coaches and Community managers",
  ONBOARDING_QUIZ_QUESTION_8_OPTION_2: "Anyone, but they must be verified to unlock this feature",
  ONBOARDING_QUIZ_QUESTION_8_OPTION_3: "Anyone but only if they have perfect English",
  ONBOARDING_QUIZ_QUESTION_8_OPTION_4: "Only people who have completed a college degree",
  ONBOARDING_QUIZ_QUESTION_8_HINT:
    "Any verified member has the same opportunities as everyone else.",
  ONBOARDING_QUIZ_QUESTION_9:
    "How long does it take before members can speak English more fluently and confidently?",
  ONBOARDING_QUIZ_QUESTION_9_OPTION_1: "Usually in just 2-3 hours with minimal effort",
  ONBOARDING_QUIZ_QUESTION_9_OPTION_2: "About 2-3 years, depending on the person",
  ONBOARDING_QUIZ_QUESTION_9_OPTION_3: "Within 2-3 months of regular weekly practice",
  ONBOARDING_QUIZ_QUESTION_9_OPTION_4: "Approximately 2 weeks, sometimes less",
  ONBOARDING_QUIZ_QUESTION_9_HINT:
    "Fluency and confidence don’t happen overnight, but it doesn’t take as long as you think to experience a change!",
  ONBOARDING_QUIZ_QUESTION_10: "What can you expect to become by following the {HL} Way?",
  ONBOARDING_QUIZ_QUESTION_10_OPTION_1: "Outgoing and confident when you talk to people",
  ONBOARDING_QUIZ_QUESTION_10_OPTION_2: "Open-minded, curious and compassionate",
  ONBOARDING_QUIZ_QUESTION_10_OPTION_3: "A competent and capable communicator",
  ONBOARDING_QUIZ_QUESTION_10_OPTION_4: "All of the above",
  ONBOARDING_QUIZ_QUESTION_10_HINT:
    "We produce women who are ready for the real world of English in every way!",
  ONBOARDING_QUIZ_CORRECT: "Correct!",
  ONBOARDING_QUIZ_TRY_AGAIN: "Oops... Try again!!",
  ONBOARDING_QUIZ_ERROR_MESSAGE: "Choose a correct answer to proceed",
  ONBOARDING_QUIZ_GET_HINT: "Not Sure? Get a Hint!",
  ONBOARDING_QUIZ_HINT_SUBTITLE: "Here is your hint!",
  ASSESSMENT_PAGE_SORRY: "Sorry!",
  ASSESSMENT_PAGE_NO_CREDIT: "You don’t have any remaining credits for a Speaking Assessment.",
  ASSESSMENT_PAGE_CONTACT: "Please contact <link>hello@heylady.io</link>",
  ASSESSMENT_PAGE_COMPLETED: "Assessment Complete!",
  ASSESSMENT_PAGE_COMPLETED_DESC:
    "A copy of your report has been sent to your email address. {br} Didn't receive it?",
};
